import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Add this JavaScript to your page
    const changeHotModal = document.getElementById('hotModal');

    changeHotModal.addEventListener('show.bs.modal', function (event) {
      const button = event.relatedTarget;
      const frame = document.querySelector("#hotModalTurboFrame");  // Get the turbo-frame element inside modal

      if (button.getAttribute('data-bs-hotmodal') === "plan-modal") {
        const planName = button.getAttribute('data-bs-plan');
        const url = `plans/change?plan=${planName}`;
        frame.setAttribute("src", url);
      }

      if (button.getAttribute('data-bs-hotmodal') === "cancel-plan-modal") {
        const url = 'plans/cancel_plan';
        frame.setAttribute("src", url);
      }
    });

    // Initialize popovers on connect
    this.initializePopovers();

    // Listen for turbo frame loads to reinitialize popovers
    document.addEventListener('turbo:frame-load', (event) => {
      if (event.target.id === 'hotModalTurboFrame') {
        // Wait a moment for the DOM to be fully updated
        setTimeout(() => {
          this.initializePopovers();
        }, 50);
      }
    });
  }

  initializePopovers() {
    // Clean up existing popovers first
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach(element => {
      const existingPopover = bootstrap.Popover.getInstance(element);
      if (existingPopover) {
        existingPopover.dispose();
      }
    });

    // Create new popovers
    popoverTriggerList.forEach(element => {
      new bootstrap.Popover(element);
    });
  }
}
