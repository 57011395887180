import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = ["form", "submitButton", "spinner", "emailRadio", "smsRadio", "bothRadio", "messageType", "messageStatus", "messageStart", "messageReenroll", "emailField", "phoneField", "phone", "languageInput", "languageButton", "languageName", "flagImage"]

  connect() {
    this.setupModalParams()
    this.initPhoneInput()
    this.validateForm()
    
    // Add event listeners for form fields to validate as user types
    this.formTarget.querySelectorAll('input, select, textarea').forEach(element => {
      element.addEventListener('change', this.validateForm.bind(this))
      element.addEventListener('input', this.validateForm.bind(this))
    })
    
    // Listen for modal opening events to set preselected values
    const modal = document.querySelector('.notification-modal')
    if (modal) {
      // Will run every time the modal is shown
      modal.addEventListener('show.bs.modal', this.setupModalParams.bind(this))
    }
    
    // Add event listeners for delivery method changes to highlight required fields
    if (this.hasEmailRadioTarget) this.emailRadioTarget.addEventListener('change', this.updateRequiredFields.bind(this))
    if (this.hasSmsRadioTarget) this.smsRadioTarget.addEventListener('change', this.updateRequiredFields.bind(this))
    if (this.hasBothRadioTarget) this.bothRadioTarget.addEventListener('change', this.updateRequiredFields.bind(this))
    
    // Add visual indication for the selected message type
    document.querySelectorAll('input[name="message_type"]').forEach(radio => {
      radio.addEventListener('change', () => {
        this.highlightSelectedMessageType(radio.value)
      })
    })
    
    // Set initial required fields
    this.updateRequiredFields()
  }
  
  // Handle language selection from dropdown
  selectLanguage(event) {
    event.preventDefault()
    const selectedLang = event.currentTarget.getAttribute('data-lang')
    const selectedLangText = event.currentTarget.textContent.trim()
    const selectedFlag = event.currentTarget.querySelector('img')
    
    // Update hidden input with selected language
    if (this.hasLanguageInputTarget) {
      this.languageInputTarget.value = selectedLang
    }
    
    // Update button text and flag
    if (this.hasLanguageNameTarget) {
      this.languageNameTarget.textContent = selectedLangText
    }
    
    if (this.hasFlagImageTarget && selectedFlag) {
      this.flagImageTarget.src = selectedFlag.src
      this.flagImageTarget.alt = selectedFlag.alt
    }
    
    // Close dropdown (Bootstrap will handle this automatically)
    console.log(`Language selected: ${selectedLang}`)
  }
  
  initPhoneInput() {
    const phoneInput = this.formTarget.querySelector('input[name="phone"]')
    if (phoneInput) {
      // Get user country based on system function or default to US
      let defaultCountry = 'us'
      try {
        // Try to detect the country from IP (if method exists)
        if (typeof DetectUserCountry !== 'undefined' && DetectUserCountry.getCountry) {
          defaultCountry = DetectUserCountry.getCountry().toLowerCase() || 'us'
        }
      } catch (e) {
        console.warn('Could not detect user country, using default', e)
      }
      
      // Create error container
      const errorContainer = document.createElement('div')
      errorContainer.className = 'phone-error-message text-danger mt-1 small'
      errorContainer.style.display = 'none'
      phoneInput.parentNode.appendChild(errorContainer)
      
      // Initialize intlTelInput
      this.iti = intlTelInput(phoneInput, {
        initialCountry: defaultCountry,
        preferredCountries: ['us', 'gb', 'ca', 'au'],
        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
        autoPlaceholder: 'aggressive',
        separateDialCode: true,
        nationalMode: false
      })
      
      // Update validation when the phone number changes
      phoneInput.addEventListener('countrychange', this.validateForm.bind(this))
      
      // Add custom validation events
      phoneInput.addEventListener('blur', () => {
        if (phoneInput.value.trim()) {
          if (this.iti.isValidNumber()) {
            phoneInput.parentNode.querySelector('.phone-error-message').style.display = 'none'
            phoneInput.classList.remove('is-invalid')
          } else {
            const errorCode = this.iti.getValidationError()
            let errorMsg = "Invalid number"
            
            if (errorCode === intlTelInput.ValidationError.INVALID_COUNTRY_CODE) {
              errorMsg = "Invalid country code"
            } else if (errorCode === intlTelInput.ValidationError.TOO_SHORT) {
              errorMsg = "Number is too short"
            } else if (errorCode === intlTelInput.ValidationError.TOO_LONG) {
              errorMsg = "Number is too long"
            } else if (errorCode === intlTelInput.ValidationError.IS_POSSIBLE) {
              errorMsg = "Number is possible but not valid"
            }
            
            const errorContainer = phoneInput.parentNode.querySelector('.phone-error-message')
            errorContainer.textContent = errorMsg
            errorContainer.style.display = 'block'
          }
        }
        
        this.validateForm()
      })
    }
  }
  
  highlightSelectedMessageType(type) {
    // Remove active class from all message cards
    document.querySelectorAll('.message-card').forEach(card => {
      card.style.transform = ''
    })
    
    // Add active class to the selected message card
    const selectedCard = document.querySelector(`label[for="message_${type}"]`)
    if (selectedCard) {
      selectedCard.style.transform = 'translateY(-2px)'
    }
  }
  
  setupModalParams() {
    // Get the clicked element that opened the modal
    const triggerElement = document.querySelector('[data-bs-toggle="modal"][data-bs-target=".notification-modal"]:focus') || 
                           document.querySelector('[data-bs-toggle="modal"][data-bs-target=".notification-modal"].active')
    
    if (triggerElement) {
      const messageType = triggerElement.getAttribute('data-message-type')
      const deliveryMethod = triggerElement.getAttribute('data-delivery-method')
      
      // Set the delivery method radio button
      if (deliveryMethod === 'email') {
        this.emailRadioTarget.checked = true
      } else if (deliveryMethod === 'sms') {
        this.smsRadioTarget.checked = true
      }
      
      // Set the appropriate message type radio based on the trigger
      if (messageType === 'status' && this.hasMessageStatusTarget) {
        this.messageStatusTarget.checked = true
      } else if (messageType === 'start' && this.hasMessageStartTarget) {
        this.messageStartTarget.checked = true
      } else if (messageType === 'reenroll' && this.hasMessageReenrollTarget) {
        this.messageReenrollTarget.checked = true
      }
      
      // Update required fields based on delivery method
      this.updateRequiredFields()
      
      console.log(`Modal opened with message type: ${messageType}, delivery method: ${deliveryMethod}`)
    } else {
      console.warn("No trigger element found for notification modal")
      // Default to status message type if no trigger found
      if (this.hasMessageStatusTarget) {
        this.messageStatusTarget.checked = true
      }
    }
  }

  updateRequiredFields() {
    const isEmailRequired = this.emailRadioTarget.checked || this.bothRadioTarget.checked
    const isPhoneRequired = this.smsRadioTarget.checked || this.bothRadioTarget.checked
    
    // Find the email and phone input fields
    const emailInput = this.formTarget.querySelector('input[name="email"]')
    const phoneInput = this.formTarget.querySelector('input[name="phone"]')
    
    if (emailInput) {
      emailInput.required = isEmailRequired
      // Update visual indicators
      const emailFormGroup = emailInput.closest('.form-group, .mb-3')
      if (emailFormGroup) {
        const emailLabel = emailFormGroup.querySelector('label')
        if (emailLabel) {
          if (isEmailRequired) {
            if (!emailLabel.innerHTML.includes('*')) {
              emailLabel.innerHTML += ' <span class="text-danger">*</span>'
            }
          } else {
            emailLabel.innerHTML = emailLabel.innerHTML.replace(' <span class="text-danger">*</span>', '')
          }
        }
      }
    }
    
    if (phoneInput) {
      phoneInput.required = isPhoneRequired
      // Update visual indicators
      const phoneFormGroup = phoneInput.closest('.form-group, .mb-3')
      if (phoneFormGroup) {
        const phoneLabel = phoneFormGroup.querySelector('label')
        if (phoneLabel) {
          if (isPhoneRequired) {
            if (!phoneLabel.innerHTML.includes('*')) {
              phoneLabel.innerHTML += ' <span class="text-danger">*</span>'
            }
          } else {
            phoneLabel.innerHTML = phoneLabel.innerHTML.replace(' <span class="text-danger">*</span>', '')
          }
        }
      }
    }
    
    this.validateForm()
  }

  validateForm() {
    const email = this.formTarget.querySelector('input[name="email"]').value
    const phone = this.formTarget.querySelector('input[name="phone"]').value
    const isEmailDelivery = this.emailRadioTarget.checked || this.bothRadioTarget.checked
    const isSmsDelivery = this.smsRadioTarget.checked || this.bothRadioTarget.checked
    
    let isValid = true
    let emailIsValid = true
    let phoneIsValid = true
    
    // Validate email if email delivery is selected
    if (isEmailDelivery) {
      emailIsValid = this.isValidEmail(email)
      isValid = isValid && emailIsValid
      
      // Show validation UI feedback
      const emailInput = this.formTarget.querySelector('input[name="email"]')
      if (emailInput) {
        if (email && !emailIsValid) {
          emailInput.classList.add('is-invalid')
          const feedbackElement = this.getOrCreateFeedbackElement(emailInput, 'Please enter a valid email address')
          feedbackElement.classList.add('invalid-feedback')
          feedbackElement.classList.remove('valid-feedback')
        } else if (email && emailIsValid) {
          emailInput.classList.remove('is-invalid')
          emailInput.classList.add('is-valid')
          const feedbackElement = this.getOrCreateFeedbackElement(emailInput, 'Looks good!')
          feedbackElement.classList.add('valid-feedback')
          feedbackElement.classList.remove('invalid-feedback')
        } else {
          emailInput.classList.remove('is-invalid')
          emailInput.classList.remove('is-valid')
          this.removeFeedbackElement(emailInput)
        }
      }
    }
    
    // Validate phone if SMS delivery is selected
    if (isSmsDelivery) {
      phoneIsValid = this.isValidPhone(phone)
      isValid = isValid && phoneIsValid
      
      // Let intlTelInput handle validation UI for phone
      const phoneInput = this.formTarget.querySelector('input[name="phone"]')
      if (phoneInput && this.iti) {
        // Just set submit button state based on validation
        // intlTelInput will handle its own UI
        if (phone && !phoneIsValid) {
          // Remove any Bootstrap validation classes to avoid conflicts
          phoneInput.classList.remove('is-valid')
          // Remove any existing feedback elements
          this.removeFeedbackElement(phoneInput)
        } else {
          // Remove any existing feedback elements
          this.removeFeedbackElement(phoneInput)
        }
      }
    }
    
    // Enable/disable submit button based on validation
    this.submitButtonTarget.disabled = !isValid
  }
  
  getOrCreateFeedbackElement(inputElement, message) {
    const parent = inputElement.parentNode
    let feedbackElement = parent.querySelector('.invalid-feedback, .valid-feedback')
    
    if (!feedbackElement) {
      feedbackElement = document.createElement('div')
      parent.appendChild(feedbackElement)
    }
    
    feedbackElement.textContent = message
    return feedbackElement
  }
  
  removeFeedbackElement(inputElement) {
    const parent = inputElement.parentNode
    const feedbackElement = parent.querySelector('.invalid-feedback, .valid-feedback')
    
    if (feedbackElement) {
      feedbackElement.remove()
    }
  }
  
  isValidEmail(email) {
    return email && email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  }
  
  isValidPhone(phone) {
    // Use intlTelInput's validation if available
    if (this.iti) {
      return this.iti.isValidNumber()
    }
    
    // Fallback validation if intlTelInput is not available
    return phone && phone.match(/^\+[0-9]{5,15}$/)
  }
  
  submit(event) {
    // Update the phone field with the full international number before submitting
    if (this.iti) {
      const phoneInput = this.formTarget.querySelector('input[name="phone"]')
      if (phoneInput) {
        // Get the full international number using intl-tel-input
        phoneInput.value = this.iti.getNumber()
      }
    }
    
    // Ensure language is included in form submission
    if (this.hasLanguageInputTarget) {
      // If language is not set, always default to English
      if (!this.languageInputTarget.value) {
        this.languageInputTarget.value = 'en'
      }
      console.log(`Submitting form with language: ${this.languageInputTarget.value}`)
    }
    
    // Show spinner and disable button immediately
    this.spinnerTarget.classList.remove('d-none')
    this.submitButtonTarget.disabled = true
    
    // Change button text to "Sending..." for visual feedback
    const buttonTextElement = this.submitButtonTarget.querySelector('span')
    if (buttonTextElement) {
      buttonTextElement.textContent = 'Sending...'
    }
    
    // Form will submit normally with Turbo
  }
  
  // Add quick animation styles to the document
  initialize() {
    // Create and inject minimal CSS for quick animations
    const style = document.createElement('style')
    style.textContent = `
      /* Super fast 200ms animations */
      .btn, .form-control, .form-select, .badge, .alert {
        transition: all 200ms ease-out !important;
      }
      
      /* Quick feedback animations */
      .is-valid, .is-invalid {
        transition: border-color 200ms ease-out !important;
      }
      
      /* Quick button state changes */
      .btn-outline-primary:hover, .btn-primary:hover {
        transition: all 200ms ease-out !important;
      }
      
      /* Make form control animations snappy */
      .form-control:focus, .form-select:focus {
        transition: box-shadow 200ms ease-out, border-color 200ms ease-out !important;
      }
      
      /* Message type option cards styling */
      .message-card {
        cursor: pointer;
        transition: all 200ms ease-out !important;
        border-color: #dee2e6 !important;
        height: 100% !important; /* Ensure all cards are the same height */
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
      }
      
      /* Create equal heights for the message content */
      .message-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      
      .message-card:hover {
        border-color: var(--bs-primary) !important;
        transform: translateY(-2px);
      }
      
      /* Style for selected message card */
      .btn-check:checked + .message-card {
        border-color: var(--bs-primary) !important;
        background-color: rgba(var(--bs-primary-rgb), 0.05) !important;
      }
      
      /* Make sure the row of options maintains consistent height */
      .message-type-options .row {
        display: flex;
        flex-wrap: wrap;
      }
      
      .message-type-options .col-md-4 {
        display: flex;
        margin-bottom: 0 !important;
      }
      
      /* Icon styling */
      .message-card .icon {
        font-size: 1.5rem;
      }
      
      /* Better styling for the delivery method buttons */
      .btn-check:checked + .btn-outline-primary {
        box-shadow: 0 2px 5px rgba(var(--bs-primary-rgb), 0.15) !important;
      }

      /* Style fixes for intl-tel-input */
      .iti {
        width: 100%;
      }
      .iti__flag-container {
        z-index: 5 !important; 
      }
      
      /* Style the intlTelInput validation states */
      .iti.iti--invalid .iti__selected-flag {
        background-color: rgba(var(--bs-danger-rgb), 0.1) !important;
      }
      .iti.iti--valid .iti__selected-flag {
        background-color: rgba(var(--bs-success-rgb), 0.1) !important;
      }
    `
    document.head.appendChild(style)
  }
  
  disconnect() {
    // Clean up the phone input when controller disconnects
    if (this.iti) {
      this.iti.destroy()
    }
  }
}