import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["languageContainer", "languageInput", "languageButton", "languageName", "flagImage", "phoneContainer"]

  connect() {
    // Initialize language to English by default
    if (this.hasLanguageInputTarget && !this.languageInputTarget.value) {
      this.languageInputTarget.value = 'en'
    }
    
    // Set initial visibility based on checkbox state
    const smsCheckbox = document.getElementById('send_sms')
    if (smsCheckbox && smsCheckbox.checked) {
      if (this.hasLanguageContainerTarget) {
        this.languageContainerTarget.classList.remove('d-none')
      }
      if (this.hasPhoneContainerTarget) {
        this.phoneContainerTarget.classList.remove('d-none')
      }
    }
  }

  toggleLanguageSelector(event) {
    if (event.target.checked) {
      if (this.hasLanguageContainerTarget) {
        this.languageContainerTarget.classList.remove('d-none')
      }
      if (this.hasPhoneContainerTarget) {
        this.phoneContainerTarget.classList.remove('d-none')
      }
    } else {
      if (this.hasLanguageContainerTarget) {
        this.languageContainerTarget.classList.add('d-none')
      }
      if (this.hasPhoneContainerTarget) {
        this.phoneContainerTarget.classList.add('d-none')
      }
    }
  }

  selectLanguage(event) {
    event.preventDefault()
    const selectedLang = event.currentTarget.getAttribute('data-lang')
    const selectedLangText = event.currentTarget.textContent.trim()
    const selectedFlag = event.currentTarget.querySelector('img')
    
    // Update hidden input with selected language
    if (this.hasLanguageInputTarget) {
      this.languageInputTarget.value = selectedLang
    }
    
    // Update button text and flag
    if (this.hasLanguageNameTarget) {
      this.languageNameTarget.textContent = selectedLangText
    }
    
    if (this.hasFlagImageTarget && selectedFlag) {
      this.flagImageTarget.src = selectedFlag.src
      this.flagImageTarget.alt = selectedFlag.alt
    }
    
    console.log(`Language selected: ${selectedLang}`)
  }
}