import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "scriptSelector", 
    "scriptTitle", 
    "scriptContent", 
    "talkingPoints", 
    "platformTips",
    "toastContainer",
    "toastMessage",
    "assetModal",
    "assetModalTitle",
    "assetModalBody",
    "assetModalDownload",
    "videoModal",
    "videoModalTitle",
    "videoModalBody",
    "videoModalDownload"
  ]
  
  static values = {
    tab: String,
    assetType: String,
    videoId: String,
    template: String
  }

  connect() {
    // Store scripts, talking points and platform tips for different platforms
    this.scripts = {
      youtube: {
        title: "YouTube Script (30-60 sec)",
        content: `"Hey everyone! Today I want to talk about a game-changer for businesses dealing with identity verification.

Bynn is the all-in-one platform that makes KYC, AML, and identity verification incredibly simple. Their AI-powered technology means faster verifications with fewer false positives.

What sets them apart? They handle everything from document verification to biometrics and AML screening in one seamless flow. Plus, they're fully compliant across multiple jurisdictions.

If you're tired of complex verification processes, check out Bynn using my link in the description. You'll get $50 in free credits, and so will I when you sign up!"`,
        points: [
          "Bynn is an all-in-one identity verification platform that combines KYC, AML, and fraud prevention",
          "AI-powered technology ensures faster verifications with fewer false positives",
          "Seamless integration options (API, SDK, or no-code solution)",
          "Global compliance coverage across multiple jurisdictions",
          "Viewers get $50 in free credits using your link"
        ],
        tips: `<p class="mb-2 small"><i class="fa-regular fa-lightbulb me-1"></i><strong>YouTube Tips:</strong></p>
        <ul class="small">
          <li>Keep the intro short - hook viewers in the first 10 seconds</li>
          <li>Show Bynn's interface when explaining features</li>
          <li>Include your affiliate link in both description and pinned comment</li>
          <li>Add chapters to your video for easy navigation</li>
        </ul>`
      },
      tiktok: {
        title: "TikTok Script (15-30 sec)",
        content: `"Do you run a business that needs to verify customer identities? Let me show you how to save time and prevent fraud!

Bynn uses AI to verify IDs, detect fraud, and screen users in seconds - all while staying compliant.

I've been using it for my business and it's cut our verification time in half!

Click the link in my bio to try Bynn. You'll get $50 in free credits, and so will I!"`,
        points: [
          "Bynn verifies customer identities in seconds",
          "AI-powered fraud detection with fewer false positives",
          "Easy to integrate with any website or app",
          "Fully compliant with global regulations",
          "$50 free credits for sign-ups through your link"
        ],
        tips: `<p class="mb-2 small"><i class="fa-regular fa-lightbulb me-1"></i><strong>TikTok Tips:</strong></p>
        <ul class="small">
          <li>Start with a hook question or problem statement</li>
          <li>Keep it under 30 seconds for better engagement</li>
          <li>Use on-screen text to highlight key points</li>
          <li>Mention "link in bio" since TikTok limits direct linking</li>
          <li>Use trending sounds to increase visibility</li>
        </ul>`
      },
      instagram: {
        title: "Instagram Reel/Story Script",
        content: `"Hey followers! Quick tip for businesses dealing with identity verification and fraud prevention!

If you're tired of complex verification processes, check out Bynn. Their AI-powered platform handles everything from document verification to biometrics and fraud screening.

I've seen businesses cut verification time by 70% while reducing fraud. Swipe up or tap the link in my bio to try Bynn.

You'll get $50 in free credits, and I'll earn a commission too - win-win! #IdentityVerification #FraudPrevention"`,
        points: [
          "Bynn's AI platform verifies identities instantly",
          "Reduces verification time by up to 70%",
          "Works for businesses of all sizes",
          "Compliant with regulations in 190+ countries",
          "$50 free credits for your followers"
        ],
        tips: `<p class="mb-2 small"><i class="fa-regular fa-lightbulb me-1"></i><strong>Instagram Tips:</strong></p>
        <ul class="small">
          <li>Use Instagram Stories for swipe-up links (if you have 10k+ followers)</li>
          <li>Add relevant hashtags like #IdentityVerification #FraudPrevention</li>
          <li>Create a carousel post showing before/after using Bynn</li>
          <li>Add your affiliate link to your bio</li>
          <li>Use Instagram's "Link" sticker in stories</li>
        </ul>`
      },
      linkedin: {
        title: "LinkedIn Post Script",
        content: `🔐 Identity verification doesn't have to be a bottleneck for your business.

I recently discovered Bynn, an AI-powered identity verification platform that's helping businesses:

✅ Verify customer identities in seconds
✅ Reduce false positives by 80%
✅ Stay compliant with regulations in 190+ countries
✅ Integrate verification into any website or app

What impressed me most was how Bynn combines document verification, biometrics, and AML screening in one seamless flow.

If your business is looking to streamline customer onboarding while preventing fraud, I recommend checking out Bynn. Through my affiliate link below, you'll receive $50 in free credits to get started.

[Affiliate Link]

#IdentityVerification #FraudPrevention #RegTech #FinTech`,
        points: [
          "Bynn streamlines identity verification for businesses",
          "AI-powered platform reduces verification time and fraud",
          "Comprehensive solution: document verification, biometrics, AML screening",
          "Easy integration with any website or app",
          "Compliant with global regulations"
        ],
        tips: `<p class="mb-2 small"><i class="fa-regular fa-lightbulb me-1"></i><strong>LinkedIn Tips:</strong></p>
        <ul class="small">
          <li>Use professional language and data points</li>
          <li>Include relevant hashtags like #IdentityVerification #RegTech</li>
          <li>Tag @Bynn (the company) in your post</li>
          <li>Add your affiliate link directly in the post</li>
          <li>Use bullet points and emojis for better readability</li>
        </ul>`
      }
    }
    
    // Asset preview content
    this.assetPreviews = {
      brand: {
        title: "Brand Assets Preview",
        content: `<div class="asset-gallery">
          <div class="row">
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x400/e9f5ff/0077b5?text=Logo+Dark" class="img-fluid rounded" alt="Logo Dark">
              <p class="small mt-2">Logo - Dark Version</p>
            </div>
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x400/0077b5/ffffff?text=Logo+Light" class="img-fluid rounded" alt="Logo Light">
              <p class="small mt-2">Logo - Light Version</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-3">
              <div class="p-3 rounded" style="background-color: #0077b5;">
                <p class="text-white mb-0 text-center">Primary: #0077b5</p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="p-3 rounded" style="background-color: #e9f5ff;">
                <p class="text-dark mb-0 text-center">Secondary: #e9f5ff</p>
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <div class="p-3 rounded" style="background-color: #00a400;">
                <p class="text-white mb-0 text-center">Accent: #00a400</p>
              </div>
            </div>
          </div>
          <div class="alert alert-info mt-3">
            <p class="small mb-0">The ZIP package includes logo files in PNG, SVG, and EPS formats, along with the brand style guide.</p>
          </div>
        </div>`
      },
      screenshots: {
        title: "Product Screenshots Preview",
        content: `<div class="asset-gallery">
          <div class="row">
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x400/f5fff5/198754?text=Dashboard" class="img-fluid rounded" alt="Dashboard">
              <p class="small mt-2">Main Dashboard</p>
            </div>
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x400/f5fff5/198754?text=Verification+Flow" class="img-fluid rounded" alt="Verification Flow">
              <p class="small mt-2">Verification Process</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x400/f5fff5/198754?text=Analytics" class="img-fluid rounded" alt="Analytics">
              <p class="small mt-2">Analytics Dashboard</p>
            </div>
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x400/f5fff5/198754?text=Mobile+View" class="img-fluid rounded" alt="Mobile View">
              <p class="small mt-2">Mobile Experience</p>
            </div>
          </div>
          <div class="alert alert-info mt-3">
            <p class="small mb-0">The ZIP package includes high-resolution screenshots of all major platform features, along with animated GIFs of the verification process.</p>
          </div>
        </div>`
      },
      social: {
        title: "Social Media Templates Preview",
        content: `<div class="asset-gallery">
          <div class="row">
            <div class="col-md-4 mb-3">
              <img src="https://placehold.co/600x600/fff0f5/dc3545?text=Instagram" class="img-fluid rounded" alt="Instagram Template">
              <p class="small mt-2">Instagram Post Template</p>
            </div>
            <div class="col-md-4 mb-3">
              <img src="https://placehold.co/600x315/fff0f5/dc3545?text=LinkedIn" class="img-fluid rounded" alt="LinkedIn Template">
              <p class="small mt-2">LinkedIn Post Template</p>
            </div>
            <div class="col-md-4 mb-3">
              <img src="https://placehold.co/600x315/fff0f5/dc3545?text=Twitter" class="img-fluid rounded" alt="Twitter Template">
              <p class="small mt-2">Twitter Post Template</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x1067/fff0f5/dc3545?text=Story" class="img-fluid rounded" alt="Story Template">
              <p class="small mt-2">Story Template (IG/FB)</p>
            </div>
            <div class="col-md-6 mb-3">
              <img src="https://placehold.co/600x1067/fff0f5/dc3545?text=TikTok" class="img-fluid rounded" alt="TikTok Template">
              <p class="small mt-2">TikTok Template</p>
            </div>
          </div>
          <div class="alert alert-info mt-3">
            <p class="small mb-0">The ZIP package includes Canva templates for all major social platforms with editable text overlays and placeholders for screenshots.</p>
          </div>
        </div>`
      }
    }
    
    // Video preview content
    this.videoPreview = {
      demo: {
        title: "Product Demo Template",
        content: `<div class="ratio ratio-16x9">
          <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="Product Demo Template" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>`,
        downloadUrl: "#demo-template"
      },
      tiktok: {
        title: "TikTok/Reels Template",
        content: `<div class="d-flex justify-content-center">
          <div style="max-width: 380px;" class="ratio ratio-9x16">
            <iframe src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="TikTok Template" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>`,
        downloadUrl: "#tiktok-template"
      }
    }
    
    // Initialize the first script
    this.changeScript()
    
    // Track page view for analytics
    this.trackView()
  }
  
  // Script changing when dropdown changes
  changeScript() {
    const platform = this.scriptSelectorTarget.value
    const scriptData = this.scripts[platform]
    
    this.scriptTitleTarget.textContent = scriptData.title
    this.scriptContentTarget.innerHTML = this.formatScript(scriptData.content)
    this.talkingPointsTarget.innerHTML = this.formatPoints(scriptData.points)
    this.platformTipsTarget.innerHTML = scriptData.tips
    
    // Track script change for analytics
    this.trackScriptChange(platform)
  }
  
  // Format the script with proper paragraphs
  formatScript(content) {
    return content.split('\n\n').map(paragraph => `<p>${paragraph}</p>`).join('')
  }
  
  // Format talking points as list items
  formatPoints(points) {
    return points.map(point => `<li class="mb-2">${point}</li>`).join('')
  }
  
  // Copy script to clipboard
  copyScript() {
    const scriptText = this.scriptContentTarget.innerText
    navigator.clipboard.writeText(scriptText)
    this.showToast("Script copied to clipboard!")
    this.trackCopy("script")
  }
  
  // Copy talking points to clipboard
  copyPoints() {
    const pointsText = this.talkingPointsTarget.innerText
    navigator.clipboard.writeText(pointsText)
    this.showToast("Talking points copied to clipboard!")
    this.trackCopy("points")
  }
  
  // Show toast notification
  showToast(message) {
    this.toastMessageTarget.textContent = message
    const toastEl = this.toastContainerTarget.querySelector('.toast')
    const toast = new bootstrap.Toast(toastEl)
    toast.show()
  }
  
  // Show asset preview modal
  showAssetPreview(event) {
    const assetType = event.currentTarget.dataset.collaborationsAssetType
    const preview = this.assetPreviews[assetType]
    
    this.assetModalTitleTarget.textContent = preview.title
    this.assetModalBodyTarget.innerHTML = preview.content
    this.assetModalDownloadTarget.setAttribute('data-collaborations-asset-type', assetType)
    
    const modal = new bootstrap.Modal(this.assetModalTarget)
    modal.show()
    
    this.trackAssetPreview(assetType)
  }
  
  // Download asset
  downloadAsset(event) {
    const assetType = event.currentTarget.dataset.collaborationsAssetType
    this.initiateDownload(assetType)
  }
  
  // Download from modal
  downloadFromModal() {
    if (this.hasAssetTypeValue) {
      this.initiateDownload(this.assetTypeValue)
    } else if (this.hasVideoIdValue) {
      this.initiateTemplateDownload(this.videoIdValue)
    }
  }
  
  // Simulate download (in a real app, this would download the actual files)
  initiateDownload(assetType) {
    this.showToast(`${this.capitalizeFirst(assetType)} assets download started!`)
    this.trackDownload(assetType)
    // In a real implementation, this would redirect to actual download URL
    // window.location.href = `/downloads/${assetType}.zip`
  }
  
  // Play video preview
  playVideoPreview(event) {
    const videoId = event.currentTarget.dataset.collaborationsVideoId
    const preview = this.videoPreview[videoId]
    
    this.videoModalTitleTarget.textContent = preview.title
    this.videoModalBodyTarget.innerHTML = preview.content
    this.videoModalDownloadTarget.setAttribute('data-collaborations-video-id', videoId)
    
    this.videoIdValue = videoId
    
    const modal = new bootstrap.Modal(this.videoModalTarget)
    modal.show()
    
    this.trackVideoPreview(videoId)
  }
  
  // Download template
  downloadTemplate(event) {
    const template = event.currentTarget.dataset.collaborationsTemplate
    this.initiateTemplateDownload(template)
  }
  
  // Simulate template download
  initiateTemplateDownload(template) {
    this.showToast(`${this.capitalizeFirst(template)} template download started!`)
    this.trackDownload(`template-${template}`)
    // In a real implementation, this would redirect to actual download URL
    // window.location.href = `/downloads/templates/${template}.zip`
  }
  
  // Track tab view for analytics
  trackTabView(event) {
    const tab = event.currentTarget.dataset.collaborationsTabValue
    this.trackView(tab)
  }
  
  // Analytics tracking functions (these would connect to your analytics service)
  trackView(tab = "scripts") {
    console.log(`View: collaborations page, tab: ${tab}`)
    // In a real app: analytics.track('view_collaborations', { tab })
  }
  
  trackScriptChange(platform) {
    console.log(`Script changed: ${platform}`)
    // In a real app: analytics.track('script_change', { platform })
  }
  
  trackCopy(contentType) {
    console.log(`Copied: ${contentType}`)
    // In a real app: analytics.track('copy_content', { contentType })
  }
  
  trackAssetPreview(assetType) {
    console.log(`Asset preview: ${assetType}`)
    // In a real app: analytics.track('asset_preview', { assetType })
  }
  
  trackVideoPreview(videoId) {
    console.log(`Video preview: ${videoId}`)
    // In a real app: analytics.track('video_preview', { videoId })
  }
  
  trackDownload(itemType) {
    console.log(`Download: ${itemType}`)
    // In a real app: analytics.track('download', { itemType })
  }
  
  // Helper to capitalize first letter
  capitalizeFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}