import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.setupStyles()
    this.initializeMoodSelectors()
    this.initializeFeedbackTypeSelectors()
    this.initializeEmojiRating()
    this.setupFormSubmission()
  }

  setupStyles() {
    // Add CSS for animations and effects
    const styleElement = document.createElement('style')
    styleElement.textContent = `
      .feedback-emoji-floating {
        animation: float 3s ease-in-out infinite;
      }
      
      @keyframes float {
        0% { transform: translateY(0) rotate(0deg); }
        50% { transform: translateY(-10px) rotate(5deg); }
        100% { transform: translateY(0) rotate(0deg); }
      }
      
      .feedback-emoji-container {
        width: 80px;
        height: 80px;
        background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.5));
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
      }
      
      .feedback-emoji-ring {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 2px solid #4158D0;
        animation: pulse 2s ease-in-out infinite;
      }
      
      .feedback-emoji-ring i {
        font-size: 2.5rem;
      }
      
      @keyframes pulse {
        0% { transform: scale(0.8); opacity: 0.8; }
        50% { transform: scale(1.1); opacity: 0.5; }
        100% { transform: scale(0.8); opacity: 0.8; }
      }
      
      .mood-box {
        cursor: pointer;
        transition: all 0.2s ease;
        border: 2px solid transparent;
        flex-grow: 1;
        margin: 0 5px;
      }
      
      .mood-box:hover {
        transform: translateY(-5px);
      }
      
      .mood-box.selected {
        background-color: rgba(var(--bs-primary-rgb), 0.1);
        border-color: var(--bs-primary);
        transform: translateY(-5px);
      }
      
      .feedback-type-box {
        cursor: pointer;
        transition: all 0.2s ease;
        background-color: rgba(255, 255, 255, 0.8);
      }
      
      .feedback-type-box:hover {
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }
      
      .feedback-type-box.selected {
        background-color: rgba(var(--bs-primary-rgb), 0.1);
        border-color: var(--bs-primary) !important;
        transform: translateY(-3px);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      }
      
      .emoji-rating-item {
        cursor: pointer;
        transition: all 0.3s ease;
      }
      
      .emoji-rating-item:hover {
        transform: scale(1.2) !important;
      }
      
      .emoji-rating-item.selected {
        transform: scale(1.2) !important;
      }
      
      .animate-slide {
        animation: slideIn 0.5s ease forwards;
        opacity: 0;
        transform: translateY(20px);
      }
      
      @keyframes slideIn {
        to {
          opacity: 1;
          transform: translateY(0);
        }
      }
      
      .rating-emoji {
        transition: all 0.2s ease;
        cursor: pointer;
      }
      
      .rating-value {
        opacity: 0;
        transition: all 0.2s ease;
      }
      
      .emoji-rating-item:hover .rating-value,
      .emoji-rating-item.selected .rating-value {
        opacity: 1;
      }
      
      .confetti {
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: #f00;
        opacity: 0.8;
        animation: confetti-fall 4s ease-in-out forwards;
      }
      
      @keyframes confetti-fall {
        0% { transform: translateY(-50px) rotate(0deg); opacity: 1; }
        100% { transform: translateY(calc(100vh - 50px)) rotate(360deg); opacity: 0; }
      }
    `
    document.head.appendChild(styleElement)
  }

  initializeMoodSelectors() {
    // Handle mood selection
    const moodBoxes = document.querySelectorAll('.mood-box')
    moodBoxes.forEach(box => {
      box.addEventListener('click', () => {
        moodBoxes.forEach(b => b.classList.remove('selected'))
        box.classList.add('selected')
        const mood = box.getAttribute('data-mood')
        document.querySelector(`#mood${mood}`).checked = true
      })
    })
  }

  initializeFeedbackTypeSelectors() {
    // Handle feedback type selection
    const typeBoxes = document.querySelectorAll('.feedback-type-box')
    typeBoxes.forEach(box => {
      box.addEventListener('click', () => {
        typeBoxes.forEach(b => b.classList.remove('selected'))
        box.classList.add('selected')
        const type = box.getAttribute('data-type')
        document.querySelector(`#feedbackType${type}`).checked = true
      })
    })
  }

  initializeEmojiRating() {
    // Handle emoji rating system
    const emojiRatings = document.querySelectorAll('.rating-emoji')
    
    emojiRatings.forEach(emoji => {
      emoji.addEventListener('click', () => {
        const rating = emoji.getAttribute('data-rating')
        
        // Reset all emojis
        emojiRatings.forEach(e => {
          e.parentElement.parentElement.classList.remove('selected')
        })
        
        // Set the selected emoji
        emoji.parentElement.parentElement.classList.add('selected')
        
        // Set radio button value
        document.querySelector(`#rating${rating}`).checked = true
      })
    })
  }

  createConfetti(container) {
    const colors = ['#f94144', '#f3722c', '#f8961e', '#f9c74f', '#90be6d', '#43aa8b', '#577590', '#4158D0', '#C850C0']
    const shapes = ['circle', 'square', 'triangle']
    
    for (let i = 0; i < 100; i++) {
      const confetti = document.createElement('div')
      confetti.className = 'confetti'
      
      // Random properties
      const color = colors[Math.floor(Math.random() * colors.length)]
      const shape = shapes[Math.floor(Math.random() * shapes.length)]
      const size = Math.random() * 10 + 5
      const left = Math.random() * 100
      const delay = Math.random() * 3
      
      confetti.style.backgroundColor = color
      confetti.style.width = `${size}px`
      confetti.style.height = `${size}px`
      
      if (shape === 'circle') {
        confetti.style.borderRadius = '50%'
      } else if (shape === 'triangle') {
        confetti.style.width = '0'
        confetti.style.height = '0'
        confetti.style.backgroundColor = 'transparent'
        confetti.style.borderLeft = `${size}px solid transparent`
        confetti.style.borderRight = `${size}px solid transparent`
        confetti.style.borderBottom = `${size}px solid ${color}`
      }
      
      confetti.style.left = `${left}%`
      confetti.style.animationDelay = `${delay}s`
      
      container.appendChild(confetti)
    }
  }

  setupFormSubmission() {
    // We'll now listen for Turbo Stream updates
    document.addEventListener('turbo:before-stream-render', (event) => {
      if (event.target.action === 'replace' && event.target.target === 'feedback_form_container') {
        // Get modal content for confetti
        const modalContent = document.querySelector('#feedbackModal .modal-content')
        if (modalContent) {
          // Create confetti container
          const confettiContainer = document.createElement('div')
          confettiContainer.style.position = 'absolute'
          confettiContainer.style.top = '0'
          confettiContainer.style.left = '0'
          confettiContainer.style.width = '100%'
          confettiContainer.style.height = '100%'
          confettiContainer.style.overflow = 'hidden'
          confettiContainer.style.pointerEvents = 'none'
          confettiContainer.id = 'feedback-confetti-container'
          modalContent.appendChild(confettiContainer)
          
          // Create confetti
          this.createConfetti(confettiContainer)
          
          // Remove confetti after animation completes
          setTimeout(() => {
            const container = document.getElementById('feedback-confetti-container')
            if (container) {
              container.remove()
            }
          }, 7000)
        }
      }
    })
  }
}