import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Initialize data modals if needed
  }

  // Helper to validate JSON input in the data field
  validateJson(event) {
    const textarea = event.target
    try {
      if (textarea.value.trim() !== '') {
        JSON.parse(textarea.value)
        textarea.classList.remove('is-invalid')
        textarea.classList.add('is-valid')
      }
    } catch (e) {
      textarea.classList.remove('is-valid')
      textarea.classList.add('is-invalid')
      
      // Add error message if it doesn't exist
      if (!textarea.nextElementSibling || !textarea.nextElementSibling.classList.contains('invalid-feedback')) {
        const errorDiv = document.createElement('div')
        errorDiv.className = 'invalid-feedback'
        errorDiv.textContent = 'Invalid JSON format'
        textarea.parentNode.insertBefore(errorDiv, textarea.nextSibling)
      }
    }
  }

  // Debounced search for entries
  debounce(event) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }
    
    this.debounceTimer = setTimeout(() => {
      event.target.form.requestSubmit()
    }, 300)
  }

  // Format JSON data in textarea
  formatJson(event) {
    const textarea = event.currentTarget.previousElementSibling
    try {
      const value = textarea.value.trim()
      if (value !== '') {
        const parsed = JSON.parse(value)
        textarea.value = JSON.stringify(parsed, null, 2)
      }
    } catch (e) {
      // Do nothing if invalid JSON
    }
  }

  // Toggle between active/inactive status
  toggleActive(event) {
    const button = event.currentTarget
    button.disabled = true
    
    // Add loading spinner
    const originalHTML = button.innerHTML
    button.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...'
    
    // Re-enable after response
    setTimeout(() => {
      button.disabled = false
      button.innerHTML = originalHTML
    }, 1000)
  }
}