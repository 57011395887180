// Cross-compatible prefetch implementation for all Turbo versions
// This avoids using Turbo.FetchRequest which is not available in all versions

import { Turbo } from "@hotwired/turbo-rails"

// Registry to track prefetched URLs and elements
const prefetchRegistry = new WeakMap();
const prefetchedUrls = new Set();

/**
 * Core prefetch function using standard fetch API
 * @param {string} url The URL to prefetch
 * @param {Element} element The element that triggered the prefetch (for debugging)
 */
function prefetchURL(url, element) {
  if (!url || url.includes('#') || url.includes('javascript:')) return;
  
  try {
    const prefetchable = new URL(url, window.location.href);
    
    // Only prefetch same-origin URLs
    if (prefetchable.origin === window.location.origin) {
      const elementType = element ? element.tagName : 'unknown';
      const elementId = element ? (element.id || 'no-id') : 'unknown';
      
      // Skip if already prefetched
      if (prefetchedUrls.has(url)) {
        console.log(`⏭️ Already prefetched ${url}`);
        return Promise.resolve(true);
      }
      
      console.log(`🚀 Prefetching ${url} (from ${elementType}#${elementId})`);
      prefetchedUrls.add(url);
      
      // Use standard fetch API (works in all browsers and with all Turbo versions)
      return fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Accept': 'text/html, application/xhtml+xml',
          'X-Requested-With': 'XMLHttpRequest',
          'X-Turbo-Prefetch': 'true'
        }
      })
      .then(response => {
        if (response.ok) {
          console.log(`✅ Successfully prefetched ${url}`);
          return true;
        }
        return false;
      })
      .catch(error => {
        console.error(`❌ Prefetch error for ${url}:`, error);
        prefetchedUrls.delete(url); // Remove from cache so it can be tried again
        return false;
      });
    }
  } catch (e) {
    console.error('Invalid URL for prefetch:', url, e);
  }
  
  return Promise.resolve(false);
}

/**
 * Adds prefetch behavior to an element
 * @param {Element} element The element to add prefetch behavior to
 */
function addPrefetchBehavior(element) {
  // Skip if already processed
  if (prefetchRegistry.has(element)) return;
  
  // Mark as processed
  prefetchRegistry.set(element, true);
  
  // Determine correct attribute to use for URL
  const urlAttribute = element.tagName === 'A' ? 'href' : 'data-href';
  const url = element.getAttribute(urlAttribute);
  
  if (!url || url.startsWith('#') || url.startsWith('javascript:')) return;
  
  // Add mouseenter event listener unless the element already handles it (like with Stimulus)
  if (!element.hasAttribute('data-controller') || 
      !element.getAttribute('data-controller').includes('clickable')) {
    
    element.addEventListener('mouseenter', function() {
      const url = this.getAttribute(urlAttribute);
      if (url && !prefetchedUrls.has(url)) {
        prefetchURL(url, this);
      }
    });
  }
}

/**
 * Set up prefetch for all applicable elements in the document
 */
function setupPrefetch() {
  // Find regular <a> links
  document.querySelectorAll('a[href]:not([href^="#"]):not([href^="javascript:"])').forEach(link => {
    if (link.getAttribute('data-turbo') !== 'false' && link.getAttribute('data-turbo-prefetch') !== 'false') {
      addPrefetchBehavior(link);
    }
  });
  
  // Find custom elements with data-turbo-prefetch="true" and data-href
  document.querySelectorAll('[data-turbo-prefetch="true"][data-href]').forEach(element => {
    // Only add behavior if not already handled by a Stimulus controller
    if (!element.hasAttribute('data-controller') || 
        !element.getAttribute('data-controller').includes('clickable')) {
      addPrefetchBehavior(element);
    }
  });
}

// Initialize when the DOM is ready, and after Turbo navigation
document.addEventListener('DOMContentLoaded', setupPrefetch);
document.addEventListener('turbo:load', setupPrefetch);
document.addEventListener('turbo:render', setupPrefetch);

// Expose for import in other files
export { prefetchURL, setupPrefetch };