// Modern Sidebar JavaScript
// Handles submenu visibility and active states

document.addEventListener('turbo:load', function() {
  initSidebar();
  // Ensure sidebar is always visible
  ensureSidebarVisible();
});

document.addEventListener('DOMContentLoaded', function() {
  initSidebar();
  // Ensure sidebar is always visible
  ensureSidebarVisible();
});

// Function to ensure sidebar is always visible
function ensureSidebarVisible() {
  // Add show class to make sure it's visible
  const sidebar = document.querySelector('.modern-sidebar');
  if (sidebar) {
    sidebar.classList.add('show');
  }
}

function initSidebar() {
  // Handle submenu visibility via hover
  const menuItems = document.querySelectorAll('.sidebar-item.has-children');
  
  menuItems.forEach(item => {
    // Remove any previously attached listeners
    item.removeEventListener('mouseenter', handleMenuItemEnter);
    item.removeEventListener('mouseleave', handleMenuItemLeave);
    
    // Add new listeners
    item.addEventListener('mouseenter', handleMenuItemEnter);
    item.addEventListener('mouseleave', handleMenuItemLeave);
    
    // For mobile/touch support
    const mainLink = item.querySelector('.main-link');
    if (mainLink) {
      mainLink.removeEventListener('click', handleMainLinkClick);
      mainLink.addEventListener('click', handleMainLinkClick);
    }
  });
  
  // Set active class based on current URL
  setActiveLinks();
}

function handleMenuItemEnter(e) {
  const item = e.currentTarget;
  const allItems = document.querySelectorAll('.sidebar-item.has-children');
  
  // Close all other open submenus
  allItems.forEach(otherItem => {
    if (otherItem !== item) {
      otherItem.classList.remove('open');
    }
  });
  
  // Calculate and set the submenu position
  positionSubmenu(item);
  
  // Open this submenu
  item.classList.add('open');
}

function positionSubmenu(item) {
  const submenu = item.querySelector('.sidebar-submenu');
  if (!submenu) return;
  
  // Get the item's position relative to viewport
  const itemRect = item.getBoundingClientRect();
  
  // Get the height of the menu item (to align the arrow)
  const arrowPosition = Math.max(16, itemRect.height / 2);
  
  // Set the top position for the submenu to align with the parent item
  submenu.style.setProperty('--submenu-top-position', `${itemRect.top}px`);
  
  // Position the arrow to match the menu item's middle
  const arrowElement = submenu.querySelector('::before');
  if (arrowElement) {
    arrowElement.style.top = `${arrowPosition}px`;
  }
  
  // Add direct mouse events to the submenu to keep it open when hovered
  submenu.addEventListener('mouseenter', function() {
    item.classList.add('open');
  });
  
  submenu.addEventListener('mouseleave', function() {
    // Add a small delay before closing when mouse leaves the submenu
    setTimeout(() => {
      if (!item.matches(':hover') && !submenu.matches(':hover')) {
        item.classList.remove('open');
      }
    }, 100);
  });
  
  // Position check - make sure submenu doesn't go off screen at the bottom
  setTimeout(() => {
    const submenuRect = submenu.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    
    if (submenuRect.bottom > viewportHeight) {
      // If submenu extends beyond viewport, reposition it
      const newTop = itemRect.top - (submenuRect.bottom - viewportHeight) - 20; // 20px buffer
      submenu.style.setProperty('--submenu-top-position', `${Math.max(10, newTop)}px`);
    }
  }, 10); // Small delay to let the submenu render
}

function handleMenuItemLeave(e) {
  const item = e.currentTarget;
  const submenu = item.querySelector('.sidebar-submenu');
  
  // Add a small delay to prevent flickering on rapid movements
  setTimeout(() => {
    // Check if either the menu item or its submenu is being hovered
    if (!item.matches(':hover') && submenu && !submenu.matches(':hover')) {
      item.classList.remove('open');
    }
  }, 150); // Increased delay for better user experience
}

function handleMainLinkClick(e) {
  const link = e.currentTarget;
  if (link.getAttribute('href') === '#') {
    e.preventDefault();
    
    const parent = link.closest('.sidebar-item.has-children');
    if (parent) {
      parent.classList.toggle('open');
    }
  }
}

function setActiveLinks() {
  const currentPath = window.location.pathname;
  const sidebarLinks = document.querySelectorAll('.sidebar-link, .subitem-link');
  
  sidebarLinks.forEach(link => {
    const href = link.getAttribute('href');
    
    // Clear any existing active state
    link.classList.remove('active');
    
    if (href && currentPath === href) {
      // Add active class to the current link
      link.classList.add('active');
      
      // If it's a submenu item, mark the parent item as active too
      if (link.classList.contains('subitem-link')) {
        const parent = link.closest('.has-children');
        if (parent) {
          const parentLink = parent.querySelector('.main-link');
          if (parentLink) {
            parentLink.classList.add('active');
          }
        }
      }
    }
  });
}