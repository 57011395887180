// Theme mode detection and management
(function () {
  'use strict'

  // Local storage for theme preference
  const getStoredTheme = () => localStorage.getItem('theme')
  const setStoredTheme = theme => localStorage.setItem('theme', theme)

  // Check if system prefers dark mode
  const systemPrefersDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches
  
  // Set theme attribute on root element - the only DOM manipulation for theme
  const setThemeAttribute = theme => {
    // console.log('Setting theme attribute:', theme);
    document.documentElement.setAttribute('data-bs-theme', theme);
    return theme;
  }

  // Determine and apply theme based on user preference
  const applyTheme = theme => {
    // For 'auto' or null, use system preference
    if (theme === 'auto' || theme === null) {
      return setThemeAttribute(systemPrefersDark() ? 'dark' : 'light');
    }
    // For manual theme selection (light/dark)
    return setThemeAttribute(theme);
  }
  
  // Initialize or update theme based on stored preference
  function checkAndApplyTheme() {
    const storedTheme = getStoredTheme();
    const appliedTheme = applyTheme(storedTheme);
    updateActiveButton(storedTheme || 'auto');
    return appliedTheme;
  }
  
  // Update UI button state to reflect current theme selection
  function updateActiveButton(theme) {
    // Get stored theme or default to 'auto'
    const storedTheme = theme || getStoredTheme() || 'auto';
    
    // Reset all buttons
    const buttons = document.querySelectorAll('[data-theme-role]');
    buttons.forEach(btn => {
      btn.classList.remove('active');
      btn.classList.remove('theme-highlight');
    });
    
    // Highlight active theme button
    const activeButton = document.querySelector(`[data-theme-role="${storedTheme}"]`);
    if (activeButton) {
      activeButton.classList.add('active');
    }
    
    // For auto mode, also highlight the current actual theme (light/dark)
    if (storedTheme === 'auto') {
      const currentTheme = document.documentElement.getAttribute('data-bs-theme');
      const currentModeBtn = document.querySelector(`[data-theme-role="${currentTheme}"]`);
      if (currentModeBtn) {
        currentModeBtn.classList.add('theme-highlight');
      }
    }
  }
  
  // Set theme on initial load
  checkAndApplyTheme();

  // Setup system preference change detection
  const colorSchemeQuery = window.matchMedia('(prefers-color-scheme: dark)');
  
  // Handle system color scheme changes
  const handleColorSchemeChange = () => {
    const storedTheme = getStoredTheme();
    if (storedTheme === null || storedTheme === 'auto') {
      applyTheme('auto');
      updateActiveButton('auto');
    }
  };
  
  // Add listener for system preference changes
  try {
    // Modern browsers
    colorSchemeQuery.addEventListener('change', handleColorSchemeChange);
  } catch (e1) {
    try {
      // Safari & older browsers
      colorSchemeQuery.addListener(handleColorSchemeChange);
    } catch (e2) {
      console.error('Could not add color scheme change listener', e2);
    }
  }
  
  // Backup polling for browsers with unreliable media query events
  let lastKnownColorScheme = systemPrefersDark();
  
  function pollSystemColorScheme() {
    const currentColorScheme = systemPrefersDark();
    
    // Only act if the system preference has changed
    if (currentColorScheme !== lastKnownColorScheme) {
      lastKnownColorScheme = currentColorScheme;
      
      // Only update if in auto mode
      const storedTheme = getStoredTheme();
      if (storedTheme === 'auto' || storedTheme === null) {
        applyTheme('auto');
        updateActiveButton('auto');
      }
    }
  }
  
  // Poll every 5 seconds as fallback (increased from 2 seconds)
  setInterval(pollSystemColorScheme, 5000);

  // Expose theme switcher function for UI buttons
  window.toggleTheme = function (theme) {
    setStoredTheme(theme);
    applyTheme(theme);
    updateActiveButton(theme);
  }
  
  // Initialize theme on DOM load
  document.addEventListener('DOMContentLoaded', checkAndApplyTheme)
  
  // Re-initialize on Turbo navigation (for Rails with Turbo)
  document.addEventListener('turbo:load', checkAndApplyTheme)
})()