import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Track instances for cleanup
  dataTableInstances = []

  initialize() {
    // Add listeners for all relevant Turbo events
    document.addEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
    document.addEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
  }
  
  connect() {
    // Basic initialization with a single animation frame
    requestAnimationFrame(() => {
      this.initializeDataTable()
      this.initializePopovers()
    })
  }

  disconnect() {
    // Remove event listeners
    document.removeEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
    document.removeEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    
    // Clean up when controller disconnects
    this.cleanupDataTables()
  }
  
  cleanupBeforeNavigation = () => {
    this.cleanupDataTables()
  }
  
  cleanupDataTables() {
    // Clean up any instances we've created
    this.dataTableInstances.forEach(dt => {
      try {
        if (dt && typeof dt.destroy === 'function') {
          dt.destroy(true) // true = remove DOM changes
        }
      } catch (err) {
        console.warn('Error cleaning up DataTable:', err)
      }
    })
    
    // Reset the instances array
    this.dataTableInstances = []
    
    // Also attempt to clean any tables in the DOM
    try {
      const table = document.getElementById('datatable')
      if (table && $.fn.DataTable.isDataTable(table)) {
        $(table).DataTable().destroy(true)
      }
    } catch (err) {
      // Ignore errors
    }
  }
  
  initializeDataTable() {
    // Ensure we clean up first
    this.cleanupDataTables()
    
    const table = document.getElementById('datatable')
    if (!table) return
    
    try {
      // Initialize with options that help with Turbo
      const config = {
        autoWidth: false,
        columns: [
          {width: "70%"},
          {width: "10%"},
          {width: "10%"},
          {width: "10%"}
        ],
        language: {
          url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
          emptyTable: "No usage data found"
        },
        retrieve: true,
        destroy: true,
        responsive: true
      }
      
      // Use jQuery-style initialization
      const dtInstance = $(table).DataTable(config)
      if (dtInstance) {
        this.dataTableInstances.push(dtInstance)
      }
    } catch (error) {
      console.error('Error initializing DataTable:', error)
    }
  }
  
  initializePopovers() {
    // Initialize popovers
    try {
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      popoverTriggerList.forEach(popoverTriggerEl => {
        new bootstrap.Popover(popoverTriggerEl)
      })
    } catch (error) {
      console.warn('Error initializing popovers:', error)
    }
  }
}
