import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "box"]

  connect() {
    // Check if required targets exist before proceeding
    if (!this.hasImageTarget) {
      console.log("Show dossier controller: image target not found, skipping initialization")
      return
    }

    try {
      // Initial setup on connection
      if (this.imageTarget.complete) {
        this.adjustBoundingBox()
      } else {
        this.imageTarget.addEventListener('load', () => this.adjustBoundingBox())
      }

      // Watch for container resizing
      this.resizeObserver = new ResizeObserver(() => this.adjustBoundingBox())
      this.resizeObserver.observe(this.imageTarget)
    } catch (error) {
      console.error("Error initializing show-dossier controller:", error)
    }
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  }

  adjustBoundingBox() {
    // Check if box target exists before proceeding
    if (!this.hasBoxTarget) {
      return
    }

    const box = this.boxTarget

    // Check if box has the required data attributes
    if (!box.dataset.originalTop || !box.dataset.originalLeft || 
        !box.dataset.originalWidth || !box.dataset.originalHeight) {
      return
    }

    // Convert original values to percentages relative to the original image dimensions
    const originalTop = parseFloat(box.dataset.originalTop)
    const originalLeft = parseFloat(box.dataset.originalLeft)
    const originalWidth = parseFloat(box.dataset.originalWidth)
    const originalHeight = parseFloat(box.dataset.originalHeight)

    // Calculate percentages based on the original image dimensions
    const topPercentage = (originalTop / this.imageTarget.naturalHeight) * 100
    const leftPercentage = (originalLeft / this.imageTarget.naturalWidth) * 100
    const widthPercentage = (originalWidth / this.imageTarget.naturalWidth) * 100
    const heightPercentage = (originalHeight / this.imageTarget.naturalHeight) * 100

    // Apply percentage-based positioning
    box.style.top = `${topPercentage}%`
    box.style.left = `${leftPercentage}%`
    box.style.width = `${widthPercentage}%`
    box.style.height = `${heightPercentage}%`
  }
}