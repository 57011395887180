import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.timeout = null
  }

  debounce(event) {
    clearTimeout(this.timeout)
    
    this.timeout = setTimeout(() => {
      event.target.form.requestSubmit()
    }, 400)
  }
}