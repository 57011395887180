import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Notification close controller connected")
  }
  
  closeModal() {
    // Just remove the fucking modal and backdrop directly
    try {
      // 1. Hide the modal itself
      this.element.classList.remove('show')
      this.element.style.display = 'none'
      
      // 2. Remove ALL modal backdrops
      document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove())
      
      // 3. Fix the body
      document.body.classList.remove('modal-open')
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('padding-right')
    } catch (e) {
      console.error('Error closing modal', e)
    }
  }
}