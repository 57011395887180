import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitButton", "currentPassword", "newPassword", "confirmPassword", "errorContainer"]
  static values = {
    submitPath: String,
    redirectPath: String
  }

  connect() {
    console.log("Password form controller connected")
  }

  submit(event) {
    event.preventDefault()
    this.submitButtonTarget.disabled = true
    
    const formData = new FormData(this.element)
    
    // Convert form data to proper JSON format
    const formDataObj = {}
    formData.forEach((value, key) => {
      formDataObj[key] = value
    })
    
    fetch(this.submitPathValue, {
      method: this.element.method,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formDataObj)
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        // Close modal
        bootstrap.Modal.getInstance(document.getElementById('changePasswordModal')).hide()
        
        // Show a message that we're redirecting to the login page
        const flashContainer = document.getElementById('flash')
        if (flashContainer) {
          const successHtml = `
            <div class="alert alert-success alert-dismissible fade show" role="alert">
              <strong>Success!</strong> Your password has been changed successfully. You will be redirected to the login page...
              <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
          `
          flashContainer.innerHTML = successHtml
        } else {
          alert('Password changed successfully! You will be redirected to the login page...')
        }
        
        // Wait a moment and then redirect to the login page
        setTimeout(() => {
          window.location.href = data.redirect_to || this.redirectPathValue || '/users/sign_in'
        }, 2000)
      } else {
        // Show error message
        // Reset previous errors
        document.querySelectorAll('.is-invalid').forEach(el => el.classList.remove('is-invalid'))
        document.querySelectorAll('.invalid-feedback').forEach(el => el.innerHTML = '')
        
        this.errorContainerTarget.classList.add('d-none')
        this.errorContainerTarget.innerHTML = ''
        
        // Process and display errors
        if (data.errors) {
          // Show specific field errors
          if (data.errors.current_password) {
            this.currentPasswordTarget.classList.add('is-invalid')
            this.currentPasswordTarget.nextElementSibling.nextElementSibling.innerHTML = data.errors.current_password.join('<br>')
          }
          
          if (data.errors.password) {
            this.newPasswordTarget.classList.add('is-invalid')
            this.newPasswordTarget.nextElementSibling.nextElementSibling.innerHTML = data.errors.password.join('<br>')
          }
          
          if (data.errors.password_confirmation) {
            this.confirmPasswordTarget.classList.add('is-invalid')
            this.confirmPasswordTarget.nextElementSibling.nextElementSibling.innerHTML = data.errors.password_confirmation.join('<br>')
          }
          
          // Show generic errors
          if (data.errors.base) {
            this.errorContainerTarget.classList.remove('d-none')
            this.errorContainerTarget.innerHTML = `<strong>Error:</strong> ${data.errors.base.join('<br>')}`
          }
        } else {
          // Generic error
          this.errorContainerTarget.classList.remove('d-none')
          this.errorContainerTarget.innerHTML = '<strong>Error:</strong> Failed to change password. Please check your inputs.'
        }
      }
    })
    .catch(error => {
      console.error('Error:', error)
      alert('An error occurred while changing your password. Please try again.')
    })
    .finally(() => {
      // Re-enable the submit button
      this.submitButtonTarget.disabled = false
    })
  }
}