import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["editableLabel", "labelDisplay", "labelEdit", "labelInput", "affiliateRow", "dashboardStats"]

  connect() {
    // We're now using the Clipboard API directly in the copyToClipboard method
    // No need to initialize ClipboardJS here anymore
    
    // Setup edit modal handlers
    document.querySelectorAll('[data-bs-target="#editLinkModal"]').forEach((el) => {
      el.addEventListener('click', () => {
        const form = document.querySelector('.edit-link-form')
        const linkToken = el.getAttribute('data-link-token')
        const linkLabel = el.getAttribute('data-link-label')
        const linkUrl = el.getAttribute('data-link-url')
        
        // Use the base URL to ensure proper URL helpers
        form.action = document.getElementById('edit-link-form').dataset.baseUrl.replace(':token', linkToken)
        
        // Set the form fields
        document.getElementById('edit_link_label').value = linkLabel || ''
        document.getElementById('edit_link_redirect_url').value = linkUrl || ''
      })
    })
  }

  // Method to handle copy button clicks directly
  copyToClipboard(event) {
    const btn = event.currentTarget
    const textToCopy = btn.getAttribute('data-clipboard-text')
    
    // Use the Clipboard API directly
    navigator.clipboard.writeText(textToCopy).then(() => {
      // Flash the button to indicate copying success
      const originalContent = btn.innerHTML
      btn.innerHTML = '<i class="fa-regular fa-check"></i>'
      
      // Use our showToast method
      this.showToast('Link copied to clipboard!', 'success')
      
      // Reset button after delay
      setTimeout(() => {
        btn.innerHTML = originalContent
      }, 2000)
    })
    .catch(err => {
      console.error('Failed to copy text: ', err)
    })
  }

  // Initialize inline label editing
  editableLabelTargetConnected(el) {
    const labelDisplay = el.querySelector('.label-display')
    const labelEdit = el.querySelector('.label-edit')
    const labelInput = el.querySelector('.label-input')
    
    // Save references to elements in the target for use in other methods
    el.displayElement = labelDisplay
    el.editElement = labelEdit
    el.inputElement = labelInput
  }

  // Show edit field when label is clicked
  showEdit(event) {
    const el = event.currentTarget.closest('.editable-label')
    el.displayElement.classList.add('d-none')
    el.editElement.classList.remove('d-none')
    el.inputElement.focus()
    el.inputElement.select()
  }

  // Handle keyboard events while editing
  handleKeydown(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.saveLabel(event)
    } else if (event.key === 'Escape') {
      event.preventDefault()
      this.cancelEdit(event)
    }
  }

  // Save on blur (click outside)
  handleBlur(event) {
    this.saveLabel(event)
  }

  // Save the edited label
  saveLabel(event) {
    const el = event.currentTarget.closest('.editable-label')
    const labelInput = el.inputElement
    const labelDisplay = el.displayElement
    const newValue = labelInput.value.trim()
    const originalValue = labelInput.dataset.originalValue
    
    // Only save if value has changed and is not empty
    if (newValue !== originalValue && newValue !== '') {
      // Update the display immediately for better UX
      const displayText = labelDisplay.childNodes[0]
      if (displayText && displayText.nodeType === Node.TEXT_NODE) {
        displayText.nodeValue = newValue
      }
      
      // Send AJAX request to update
      const updateUrl = el.dataset.updateUrl
      const formData = new FormData()
      formData.append('_method', 'PATCH')
      formData.append('affiliate_link[label]', newValue)
      
      fetch(updateUrl, {
        method: 'POST',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        // Update the original value to reflect the new saved state
        labelInput.dataset.originalValue = newValue
        
        // Show success toast
        this.showToast('Label updated successfully', 'success')
      })
      .catch(error => {
        // If there's an error, revert to the original
        const displayText = labelDisplay.childNodes[0]
        if (displayText && displayText.nodeType === Node.TEXT_NODE) {
          displayText.nodeValue = originalValue
        }
        this.showToast('Failed to update label', 'danger')
        console.error('Error updating label:', error)
      })
    }
    
    // Hide edit field and show display
    this.cancelEdit(event)
  }

  // Cancel editing and restore display
  cancelEdit(event) {
    const el = event.currentTarget.closest('.editable-label')
    const labelInput = el.inputElement
    const labelEdit = el.editElement
    const labelDisplay = el.displayElement
    
    // Reset input to original value
    labelInput.value = labelInput.dataset.originalValue
    
    // Hide edit field, show display
    labelEdit.classList.add('d-none')
    labelDisplay.classList.remove('d-none')
  }

  // Helper function to show a toast notification
  showToast(message, type = 'success') {
    const toastEl = document.createElement('div')
    toastEl.className = 'position-fixed top-0 end-0 p-3'
    toastEl.style.zIndex = '5000'
    toastEl.innerHTML = `
      <div class="toast show align-items-center text-white bg-${type} border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            <i class="fa-regular fa-${type === 'success' ? 'check-circle' : 'exclamation-circle'} me-2"></i>
            ${message}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `
    document.body.appendChild(toastEl)
    
    setTimeout(() => {
      document.body.removeChild(toastEl)
    }, 3000)
  }
}