// app/javascript/controllers/dashboard/scansettings_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "frequencyGroup",
        "frequencyInput",
        "sensitivitySlider",
        "sensitivityInput",
        "sensitivityValue",
        "sensitivityLabel"
    ]

    static values = {
        initialDays: Number,
        initialSensitivity: Number
    }

    connect() {
        this.initializeFrequency()
        this.initializeSensitivity()
        this.updateSensitivityLabel()
    }

    initializeFrequency() {
        const days = this.initialDaysValue
        const option = this.frequencyGroupTarget.querySelector(`[data-days="${days}"]`)
        if (option) {
            option.classList.add('aml-radio-option--selected')
            this.frequencyInputTarget.value = days
        }
    }

    initializeSensitivity() {
        const percentValue = Math.round(this.initialSensitivityValue * 100)
        this.sensitivitySliderTarget.value = percentValue
        
        // Use translation with parameter
        if (window.I18n && window.I18n.t) {
            this.sensitivityValueTarget.textContent = window.I18n.t('dashboard.setting.product.aml.matching_sensitivity.sensitivity_value', { value: percentValue })
        } else {
            this.sensitivityValueTarget.textContent = `${percentValue}% Sensitivity`
        }
        
        this.sensitivityInputTarget.value = this.initialSensitivityValue
    }

    selectFrequency(event) {
        const option = event.currentTarget
        const days = option.dataset.days

        // Update visual state
        this.frequencyGroupTarget.querySelectorAll('.aml-radio-option').forEach(el => {
            el.classList.remove('aml-radio-option--selected')
        })
        option.classList.add('aml-radio-option--selected')

        // Update hidden input
        this.frequencyInputTarget.value = days
    }

    updateSensitivity(event) {
        const percentValue = event.target.value
        const decimalValue = (percentValue / 100).toFixed(2)

        // Use translation with parameter
        if (window.I18n && window.I18n.t) {
            this.sensitivityValueTarget.textContent = window.I18n.t('dashboard.setting.product.aml.matching_sensitivity.sensitivity_value', { value: percentValue })
        } else {
            this.sensitivityValueTarget.textContent = `${percentValue}% Sensitivity`
        }
        
        this.sensitivityInputTarget.value = decimalValue
        this.updateSensitivityLabel()
    }

    updateSensitivityLabel() {
        const value = parseInt(this.sensitivitySliderTarget.value)
        let label = ''

        // Use translations for sensitivity labels
        if (window.I18n && window.I18n.t) {
            if (value < 60) {
                label = window.I18n.t('dashboard.setting.product.aml.matching_sensitivity.low_label')
            } else if (value < 80) {
                label = window.I18n.t('dashboard.setting.product.aml.matching_sensitivity.medium_label')
            } else if (value < 100) {
                label = window.I18n.t('dashboard.setting.product.aml.matching_sensitivity.high_label')
            } else {
                label = window.I18n.t('dashboard.setting.product.aml.matching_sensitivity.maximum_label')
            }
        } else {
            // Fallback to hardcoded labels if translations are not available
            if (value < 60) {
                label = 'Low - Very wide matching, high false positive rate'
            } else if (value < 80) {
                label = 'Medium - More potential matches, higher false positive rate'
            } else if (value < 100) {
                label = 'High - Balance between matches and accuracy'
            } else {
                label = 'Maximum - Highest accuracy, name, nationality and birth date must match'
            }
        }

        this.sensitivityLabelTarget.textContent = label
    }
}