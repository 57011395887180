import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Add event listeners to close buttons
    const closeButtons = this.element.querySelectorAll('.btn-close, button[data-bs-dismiss="modal"]');
    closeButtons.forEach(button => {
      button.addEventListener('click', this.close.bind(this));
    });

    // Add event listener for escape key
    document.addEventListener('keydown', this.handleKeyDown.bind(this));
    
    // Add backdrop
    this.addBackdrop();
  }
  
  disconnect() {
    // Remove event listener when controller disconnects
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
    this.removeBackdrop();
  }
  
  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.close();
    }
  }
  
  close() {
    // Redirect to the appropriate page using the data attribute
    const returnUrl = this.element.dataset.returnUrl;
    if (returnUrl) {
      window.location.href = returnUrl;
    } else {
      window.history.back();
    }
  }
  
  addBackdrop() {
    if (!document.querySelector('.modal-backdrop')) {
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
      document.body.classList.add('modal-open');
    }
  }
  
  removeBackdrop() {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.remove();
      document.body.classList.remove('modal-open');
    }
  }
}