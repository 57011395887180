import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
    static targets = [
        "detailsForm",
        "verificationForm",
        "firstName",
        "lastName",
        "phoneNumber",
        "phoneDisplay",
        "verificationCode"
    ]

    connect() {
        this.modal = new bootstrap.Modal(this.element)
        this.initPhoneInput()
        
        // Initialize the verification subtitle with a proper translation
        if (this.hasPhoneDisplayTarget) {
            this.phoneDisplayTarget.textContent = I18n.t('dashboard.phone_verification.modal.verification_subtitle', { phone: '...' })
        }
    }
    
    initPhoneInput() {
        if (this.hasPhoneNumberTarget) {
            // Get default country from data attribute, fallback to "us"
            const defaultCountry = this.data.get("defaultCountry") || "us";
            
            this.iti = intlTelInput(this.phoneNumberTarget, {
                initialCountry: defaultCountry,
                strictMode: true,
                nationalMode: true,
                loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
            });
        }
    }

    // Set up SMS auto-capture when code is shown
    setupSMSAutoCapture() {
        // This function works for newer browsers that support the WebOTP API
        if ('OTPCredential' in window) {
            // Get the form element
            const verificationForm = this.verificationFormTarget;

            if (verificationForm) {
                // Abort controller for aborting the OTP process when needed
                this.abortController = new AbortController();
                const { signal } = this.abortController;

                // Start listening for SMS with OTP
                navigator.credentials.get({
                    otp: { transport: ['sms'] },
                    signal
                }).then(otp => {
                    // Auto-fill the code input
                    this.verificationCodeTarget.value = otp.code;
                    
                    // Optionally auto-submit the form
                    // verificationForm.submit();
                }).catch(err => {
                    // Ignore cancellations, log other errors
                    if (err.name !== 'AbortError') {
                        console.log('OTP Error:', err);
                    }
                });
            }
        }
    }

    sendCode(event) {
        event.preventDefault()

        // Validate the phone number using the intl-tel-input library's isValidNumber method
        if (this.iti && !this.iti.isValidNumber()) {
            // Show error to user
            this.phoneNumberTarget.classList.add('is-invalid')
            
            // Add error message if it doesn't exist
            let errorMessage = this.phoneNumberTarget.parentNode.querySelector('.invalid-feedback')
            if (!errorMessage) {
                errorMessage = document.createElement('div')
                errorMessage.classList.add('invalid-feedback')
                this.phoneNumberTarget.parentNode.appendChild(errorMessage)
            }
            errorMessage.textContent = I18n.t('dashboard.phone_verification.js.invalid_phone')
            return
        }
        
        // Remove error styling if it was previously shown
        this.phoneNumberTarget.classList.remove('is-invalid')

        // Get the full international number using intl-tel-input
        const phoneNumber = this.iti ? this.iti.getNumber() : this.phoneNumberTarget.value
        
        // Get the URL from data attribute
        const url = this.data.get('url')
        if (!url) {
            console.error(I18n.t('dashboard.phone_verification.js.no_url_error'))
            return
        }
        
        // Disable the submit button and show loading state
        const submitButton = event.target.querySelector('button[type="submit"]')
        const originalButtonText = submitButton.innerHTML
        submitButton.disabled = true
        submitButton.innerHTML = '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> ' + I18n.t('dashboard.phone_verification.js.sending')
        
        // Prepare the form data
        const formData = new FormData()
        formData.append('first_name', this.firstNameTarget.value)
        formData.append('last_name', this.lastNameTarget.value)
        formData.append('phone_number', phoneNumber)
        formData.append('verification_action', 'send_code')
        
        // Make the API call to send verification code
        fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {
            // Reset button state
            submitButton.disabled = false
            submitButton.innerHTML = originalButtonText
            
            if (data.success) {
                // Show success message if provided
                if (data.message && typeof bynnToast !== 'undefined') {
                    bynnToast.success(data.message)
                }
                
                // Update phone display
                this.phoneDisplayTarget.textContent = I18n.t('dashboard.phone_verification.modal.verification_subtitle', { phone: phoneNumber })
                
                // Switch to verification form
                this.detailsFormTarget.classList.add('d-none')
                this.verificationFormTarget.classList.remove('d-none')
                
                // Setup SMS auto-capture
                this.setupSMSAutoCapture()
                
                // Focus the verification code input
                setTimeout(() => {
                    this.verificationCodeTarget.focus()
                }, 300)
            } else {
                // Show error message in the form
                this.phoneNumberTarget.classList.add('is-invalid')
                
                let errorMessage = this.phoneNumberTarget.parentNode.querySelector('.invalid-feedback')
                if (!errorMessage) {
                    errorMessage = document.createElement('div')
                    errorMessage.classList.add('invalid-feedback')
                    this.phoneNumberTarget.parentNode.appendChild(errorMessage)
                }
                errorMessage.textContent = data.message || I18n.t('dashboard.phone_verification.js.failed_to_send')
                
                // Also show toast if available
                if (data.message && typeof bynnToast !== 'undefined') {
                    bynnToast.error(data.message)
                }
            }
        })
        .catch(error => {
            // Reset button state
            submitButton.disabled = false
            submitButton.innerHTML = originalButtonText
            
            console.error('Error sending verification code:', error)
            
            // Show generic error message
            this.phoneNumberTarget.classList.add('is-invalid')
            
            let errorMessage = this.phoneNumberTarget.parentNode.querySelector('.invalid-feedback')
            if (!errorMessage) {
                errorMessage = document.createElement('div')
                errorMessage.classList.add('invalid-feedback')
                this.phoneNumberTarget.parentNode.appendChild(errorMessage)
            }
            errorMessage.textContent = I18n.t('dashboard.phone_verification.js.failed_to_send')
        })
    }

    verifyCode(event) {
        event.preventDefault()

        // Get verification code from input
        const verificationCode = this.verificationCodeTarget.value
        
        // Get the phone number (stored earlier)
        const phoneNumber = this.iti ? this.iti.getNumber() : this.phoneNumberTarget.value
        
        // Get the URL from data attribute
        const url = this.data.get('url')
        if (!url) {
            console.error(I18n.t('dashboard.phone_verification.js.no_url_error'))
            return
        }
        
        // Disable the submit button and show loading state
        const submitButton = event.target.querySelector('button[type="submit"]')
        const originalButtonText = submitButton.innerHTML
        submitButton.disabled = true
        submitButton.innerHTML = '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> ' + I18n.t('dashboard.phone_verification.js.verifying')
        
        // Prepare the form data
        const formData = new FormData()
        formData.append('verification_code', verificationCode)
        formData.append('phone_number', phoneNumber)
        formData.append('first_name', this.firstNameTarget.value)
        formData.append('last_name', this.lastNameTarget.value)
        formData.append('verification_action', 'verify_code')
        
        // Make the API call to verify the code
        fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {
            // Reset button state
            submitButton.disabled = false
            submitButton.innerHTML = originalButtonText
            
            if (data.success) {
                // Show success message if provided (in a nonintrusive way)
                if (data.message && typeof bynnToast !== 'undefined') {
                    bynnToast.success(data.message)
                }
                
                // Reset and close modal on success
                this.reset()
                this.modal.hide()
                
                // Trigger a page reload if needed
                if (data.reload) {
                    window.location.reload()
                }
            } else {
                // Show error message in the form
                this.verificationCodeTarget.classList.add('is-invalid')
                
                let errorMessage = this.verificationCodeTarget.parentNode.querySelector('.invalid-feedback')
                if (!errorMessage) {
                    errorMessage = document.createElement('div')
                    errorMessage.classList.add('invalid-feedback')
                    this.verificationCodeTarget.parentNode.appendChild(errorMessage)
                }
                errorMessage.textContent = data.message || I18n.t('dashboard.phone_verification.js.invalid_code')
                
                // Also show toast if available
                if (data.message && typeof bynnToast !== 'undefined') {
                    bynnToast.error(data.message)
                }
            }
        })
        .catch(error => {
            // Reset button state
            submitButton.disabled = false
            submitButton.innerHTML = originalButtonText
            
            console.error('Error verifying code:', error)
            
            // Show generic error message
            this.verificationCodeTarget.classList.add('is-invalid')
            
            let errorMessage = this.verificationCodeTarget.parentNode.querySelector('.invalid-feedback')
            if (!errorMessage) {
                errorMessage = document.createElement('div')
                errorMessage.classList.add('invalid-feedback')
                this.verificationCodeTarget.parentNode.appendChild(errorMessage)
            }
            errorMessage.textContent = I18n.t('dashboard.phone_verification.js.failed_to_verify')
        })
    }

    resendCode(event) {
        event.preventDefault()
        
        // Get the full international number using intl-tel-input
        const phoneNumber = this.iti ? this.iti.getNumber() : this.phoneNumberTarget.value
        
        // Get the URL from data attribute
        const url = this.data.get('url')
        if (!url) {
            console.error(I18n.t('dashboard.phone_verification.js.no_url_error'))
            return
        }
        
        // Disable the resend button and show loading state
        const resendButton = event.currentTarget
        const originalButtonText = resendButton.innerHTML
        resendButton.disabled = true
        resendButton.innerHTML = '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> ' + I18n.t('dashboard.phone_verification.js.resending')
        
        // Prepare the form data
        const formData = new FormData()
        formData.append('phone_number', phoneNumber)
        formData.append('first_name', this.firstNameTarget.value)
        formData.append('last_name', this.lastNameTarget.value)
        formData.append('verification_action', 'resend_code')
        
        // Make the API call to resend verification code
        fetch(url, {
            method: 'POST',
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {
            // Reset button state
            resendButton.disabled = false
            resendButton.innerHTML = originalButtonText
            
            // Show appropriate messages
            if (data.success) {
                // Show success message
                if (data.message && typeof bynnToast !== 'undefined') {
                    bynnToast.success(data.message)
                } else {
                    // Create a temporary success message
                    const tempMessage = document.createElement('div');
                    tempMessage.className = 'text-success small mt-2 text-center';
                    tempMessage.textContent = data.message || I18n.t('dashboard.phone_verification.js.code_resent');
                    
                    // Append it near the resend button
                    resendButton.parentNode.appendChild(tempMessage);
                    
                    // Remove it after a delay
                    setTimeout(() => {
                        if (tempMessage.parentNode) {
                            tempMessage.parentNode.removeChild(tempMessage);
                        }
                    }, 3000);
                }
            } else if (data.message) {
                // Show error message
                if (typeof bynnToast !== 'undefined') {
                    bynnToast.error(data.message)
                } else {
                    // Create a temporary error message
                    const tempMessage = document.createElement('div');
                    tempMessage.className = 'text-danger small mt-2 text-center';
                    tempMessage.textContent = data.message;
                    
                    // Append it near the resend button
                    resendButton.parentNode.appendChild(tempMessage);
                    
                    // Remove it after a delay
                    setTimeout(() => {
                        if (tempMessage.parentNode) {
                            tempMessage.parentNode.removeChild(tempMessage);
                        }
                    }, 3000);
                }
            }
        })
        .catch(error => {
            // Reset button state
            resendButton.disabled = false
            resendButton.innerHTML = originalButtonText
            
            console.error('Error resending verification code:', error)
            
            // Show error message
            if (typeof bynnToast !== 'undefined') {
                bynnToast.error(I18n.t('dashboard.phone_verification.js.failed_to_resend'))
            } else {
                // Show inline error message
                const tempMessage = document.createElement('div');
                tempMessage.className = 'text-danger small mt-2 text-center';
                tempMessage.textContent = I18n.t('dashboard.phone_verification.js.failed_to_resend');
                
                // Append it near the resend button
                resendButton.parentNode.appendChild(tempMessage);
                
                // Remove it after a delay
                setTimeout(() => {
                    if (tempMessage.parentNode) {
                        tempMessage.parentNode.removeChild(tempMessage);
                    }
                }, 3000);
            }
        })
    }

    reset() {
        // Reset forms and switch back to details view
        this.detailsFormTarget.reset()
        this.verificationFormTarget.reset()
        this.detailsFormTarget.classList.remove('d-none')
        this.verificationFormTarget.classList.add('d-none')
    }
    
    disconnect() {
        // Clean up the phone input when controller disconnects
        if (this.iti) {
            this.iti.destroy();
        }
        
        // Clean up WebOTP if it's being used
        if (this.abortController) {
            this.abortController.abort();
        }
    }
}