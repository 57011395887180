// Special fingerprinting for OAuth flows
import { load } from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup
const fpPromise = load()

// Store fingerprint in browser storage and cookies before OAuth redirect
const storeOAuthFingerprint = async () => {
  try {
    // Get the visitor identifier 
    const fp = await fpPromise
    const result = await fp.get()
    const visitorId = result.visitorId
    
    // Store fingerprint and timezone in sessionStorage for OAuth flow
    sessionStorage.setItem('oauth_fingerprint', visitorId)
    
    // Also store timezone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    sessionStorage.setItem('oauth_timezone', timezone)
    
    // Store in cookies to persist through OAuth redirects (30 minute expiration)
    document.cookie = `oauth_fingerprint=${visitorId}; path=/; max-age=1800; SameSite=Lax`;
    document.cookie = `oauth_timezone=${timezone}; path=/; max-age=1800; SameSite=Lax`;

    return { fingerprint: visitorId, timezone }
  } catch (error) {

    return null
  }
}

// Listen for clicks on OAuth login buttons
document.addEventListener('DOMContentLoaded', () => {
  // Process OAuth sign in buttons
  const oauthForms = document.querySelectorAll('form[action*="omniauth"], .oauth-form')
  
  oauthForms.forEach(form => {
    // Generate fingerprint immediately for the form
    storeOAuthFingerprint().then(({ fingerprint, timezone }) => {
      // Find existing or create new fingerprint field
      let fingerprintField = form.querySelector('.oauth-fingerprint, [name="login_activity[fingerprint]"]')
      if (!fingerprintField) {
        fingerprintField = document.createElement('input')
        fingerprintField.type = 'hidden'
        fingerprintField.name = 'login_activity[fingerprint]'
        fingerprintField.className = 'oauth-fingerprint'
        form.appendChild(fingerprintField)
      }
      
      // Find existing or create new timezone field
      let timezoneField = form.querySelector('.oauth-timezone, [name="login_activity[timezone]"]')
      if (!timezoneField) {
        timezoneField = document.createElement('input')
        timezoneField.type = 'hidden'
        timezoneField.name = 'login_activity[timezone]'
        timezoneField.className = 'oauth-timezone'
        form.appendChild(timezoneField)
      }
      
      // Set the values
      fingerprintField.value = fingerprint
      timezoneField.value = timezone
      

    }).catch(error => {

    })
    
    // Also set on submit to ensure latest values
    form.addEventListener('submit', async (e) => {
      // Generate and store fingerprint before the redirect happens
      const { fingerprint, timezone } = await storeOAuthFingerprint()
      
      // Update the form fields with the latest values
      const fingerprintField = form.querySelector('.oauth-fingerprint, [name="login_activity[fingerprint]"]')
      const timezoneField = form.querySelector('.oauth-timezone, [name="login_activity[timezone]"]')
      
      if (fingerprintField) fingerprintField.value = fingerprint
      if (timezoneField) timezoneField.value = timezone
      

    })
  })
  
  // Helper function to get a cookie by name
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
}

// Also check if we came from an OAuth redirect and attach fingerprint to the page
  if (window.location.pathname.includes('/dashboard')) {
    // Try to get from sessionStorage first, then from cookies as fallback
    const fingerprint = sessionStorage.getItem('oauth_fingerprint') || getCookie('oauth_fingerprint')
    const timezone = sessionStorage.getItem('oauth_timezone') || getCookie('oauth_timezone')
    
    if (fingerprint) {
      // Add information to the page using hidden fields
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.id = 'oauth_fingerprint'
      hiddenField.value = fingerprint
      document.body.appendChild(hiddenField)
      
      // Send this information to the server using a beacon
      navigator.sendBeacon('/api/auth/fingerprint', JSON.stringify({
        fingerprint,
        timezone
      }))
      

      
      // Clear these values to avoid confusion
      sessionStorage.removeItem('oauth_fingerprint')
      sessionStorage.removeItem('oauth_timezone')
    }
  }
})

// Make storeOAuthFingerprint globally available for onclick handlers
window.storeOAuthFingerprint = storeOAuthFingerprint;

export default { storeOAuthFingerprint }