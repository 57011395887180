import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "list", "item", "content", "switch"]



  initialize() {
    // Set the base URL depending on environment
    this.baseUrl = window.location.host.includes('dashboard.') ? '' : '/dashboard'


  }

  filter() {
    const query = this.normalizeText(this.inputTarget.value.toLowerCase())
    const items = this.listTarget.querySelectorAll("[data-search-text]")

    items.forEach(item => {
      const text = this.normalizeText(item.dataset.searchText.toLowerCase())
      item.style.display = text.includes(query) ? "" : "none"
    })
  }

  normalizeText(text) {
    // Normalize Unicode characters and handle special cases
    return text
      .normalize('NFD').replace(/[\u0300-\u036f]/g, '') // Remove diacritics
      // Special character replacements
      .replace(/ü/g, 'u') // Replace ü with u
      .replace(/å/g, 'a')
      .replace(/ä/g, 'a')
      .replace(/ö/g, 'o')
      .replace(/ø/g, 'o') // Replace Nordic ø with o
      .replace(/æ/g, 'ae') // Replace æ with ae
      .replace(/ß/g, 'ss') // Replace German ß with ss
      
      // Official name changes
      .replace(/turkiye/g, 'turkey') // Make Turkiye match Turkey
      .replace(/türkiye/g, 'turkey') // Make Türkiye match Turkey
      .replace(/côte\s*d'ivoire/g, 'ivory coast') // Handle Côte d'Ivoire/Ivory Coast
      .replace(/eswatini/g, 'swaziland') // Handle Eswatini/Swaziland
      .replace(/czechia/g, 'czech republic') // Handle Czechia/Czech Republic
      .replace(/north\s*macedonia/g, 'macedonia') // Handle North Macedonia/Macedonia
      .replace(/myanmar/g, 'burma') // Handle Myanmar/Burma
      .replace(/timor-leste/g, 'east timor') // Handle Timor-Leste/East Timor
      .replace(/cabo\s*verde/g, 'cape verde') // Handle Cabo Verde/Cape Verde
      .replace(/belarus/g, 'byelorussia') // Belarus/Byelorussia
      .replace(/belarus/g, 'white russia') // Belarus/White Russia
      
      // Congo variations
      .replace(/democratic\s*republic\s*of\s*the\s*congo/g, 'drc') // Handle DR Congo
      .replace(/republic\s*of\s*the\s*congo/g, 'congo') // Handle Republic of Congo
      .replace(/congo-kinshasa/g, 'drc') // Another name for DR Congo
      .replace(/congo-brazzaville/g, 'congo') // Another name for Republic of Congo
      
      // Historical or alternate names
      .replace(/nederlands/g, 'netherlands') // Dutch name for Netherlands
      .replace(/holland/g, 'netherlands') // Common name for Netherlands
      .replace(/persia/g, 'iran') // Historical name for Iran
      .replace(/formosa/g, 'taiwan') // Historical name for Taiwan
      .replace(/siam/g, 'thailand') // Historical name for Thailand
      .replace(/ceylon/g, 'sri lanka') // Historical name for Sri Lanka
      .replace(/british\s*honduras/g, 'belize') // Historical name for Belize
      .replace(/rhodesia/g, 'zimbabwe') // Historical name for Zimbabwe
      .replace(/kampuchea/g, 'cambodia') // Former name for Cambodia
      .replace(/east\s*pakistan/g, 'bangladesh') // Historical name for Bangladesh
      .replace(/upper\s*volta/g, 'burkina faso') // Historical name for Burkina Faso
      .replace(/dahomey/g, 'benin') // Historical name for Benin
      .replace(/zaire/g, 'drc') // Historical name for DR Congo
      .replace(/bombay/g, 'mumbai') // City name change in India
      .replace(/calcutta/g, 'kolkata') // City name change in India
      .replace(/madras/g, 'chennai') // City name change in India
      
      // Local names and variations
      .replace(/zhongguo/g, 'china') // Pinyin for China
      .replace(/nippon/g, 'japan') // Japanese name for Japan
      .replace(/nihon/g, 'japan') // Alternative Japanese name
      .replace(/hanguk/g, 'south korea') // Korean name for South Korea
      .replace(/bharat/g, 'india') // Hindi name for India
      .replace(/deutschland/g, 'germany') // German name for Germany
      .replace(/espana/g, 'spain') // Spanish name for Spain (without ñ)
      .replace(/españa/g, 'spain') // Spanish name for Spain (with ñ)
      .replace(/sverige/g, 'sweden') // Swedish name for Sweden
      .replace(/suomi/g, 'finland') // Finnish name for Finland
      .replace(/norge/g, 'norway') // Norwegian name for Norway
      .replace(/danmark/g, 'denmark') // Danish name for Denmark
      .replace(/hrvatska/g, 'croatia') // Croatian name for Croatia
      .replace(/hellas/g, 'greece') // Greek name for Greece
      .replace(/magyarorszag/g, 'hungary') // Hungarian name for Hungary
      .replace(/magyarország/g, 'hungary') // Hungarian name with accent
      .replace(/polska/g, 'poland') // Polish name for Poland
      .replace(/al-maghrib/g, 'morocco') // Arabic name for Morocco
      .replace(/misr/g, 'egypt') // Arabic name for Egypt
      
      // Informal variations
      .replace(/usa/g, 'united states') // Abbreviation for United States
      .replace(/america/g, 'united states') // Common name for United States
      .replace(/uk/g, 'united kingdom') // Abbreviation for United Kingdom
      .replace(/great\s*britain/g, 'united kingdom') // Alternative name for UK
      .replace(/britain/g, 'united kingdom') // Short form for UK
      .replace(/uae/g, 'united arab emirates') // Abbreviation for UAE
      .replace(/saudi/g, 'saudi arabia') // Short form for Saudi Arabia
      .replace(/russia/g, 'russian federation') // Short form matches official name
      .replace(/burma/g, 'myanmar') // Both directions of the name change
  }

  select(event) {
    this.itemTargets.forEach(item => item.classList.remove("selected"))
    event.currentTarget.classList.add("selected")

    const code = event.currentTarget.dataset.countryCode
    this.fetchCountryDetails(code)
  }

  async fetchCountryDetails(code) {
    try {
      const response = await fetch(`${this.baseUrl}/setting/country/${code}`)
      if (!response.ok) throw new Error('Failed to fetch country details')
      const html = await response.text()
      this.contentTarget.innerHTML = html
    } catch (error) {
      console.error('Error fetching country details:', error)
      this.contentTarget.innerHTML = '<div class="alert alert-danger">Failed to load country details</div>'
    }
  }

  async toggle(event) {
    const { setting, countryCode } = event.target.dataset
    const enabled = event.target.checked

    try {
      const response = await fetch(`${this.baseUrl}/setting/country/${countryCode}/settings`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          setting,
          enabled
        })
      })

      if (!response.ok) throw new Error('Failed to update setting')

      // Update all related icons in both the list and detail views
      this.updateIcons(countryCode, setting, enabled)
    } catch (error) {
      console.error('Error updating setting:', error)
      // Revert the toggle
      event.target.checked = !enabled
      // Show error message
      const errorToast = `
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div class="toast-header bg-danger text-white">
            <strong class="me-auto">Error</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            Failed to update setting. Please try again.
          </div>
        </div>
      `
      document.body.insertAdjacentHTML('beforeend', errorToast)
      const toast = document.body.lastElementChild
      new bootstrap.Toast(toast).show()
    }
  }

  updateIcons(countryCode, setting, enabled) {
    // Update icons in both list and detail views
    const iconName = this.getIconName(setting)
    const icons = document.querySelectorAll(`[data-country-code="${countryCode}"] .fa-${iconName}`)

    icons.forEach(icon => {
      const iconContainer = icon.closest('.icon') // Get the parent .icon container
      if (iconContainer) {
        iconContainer.classList.toggle('active', enabled)
        iconContainer.classList.toggle('disabled', !enabled)
      }
    })
  }

  getIconName(setting) {
    const icons = {
      residency: 'house-building',
      documents: 'passport',
      visiting: 'location-dot',
      goverlink: 'building-columns'
    }
    return icons[setting]
  }


}