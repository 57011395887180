import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // Track DataTable instances for proper cleanup
  dataTableInstances = []

  initialize() {
    // Add listeners for all relevant Turbo events
    document.addEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
    document.addEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    
    // Custom filtering function for date range
    this.dateRangeFilter = function(settings, data, dataIndex) {
      const minDate = document.getElementById('date-min')?.value
      const maxDate = document.getElementById('date-max')?.value
      const dateStr = data[1] // Date is in column 1
      
      // If no date filters are set, show all rows
      if (!minDate && !maxDate) return true
      
      // Parse the formatted date string back to a Date object
      const dateParts = dateStr.split(/[, :]/)
      const date = new Date(dateParts.join(' '))
      
      // If min date is set and date is before min date, exclude the row
      if (minDate && new Date(minDate) > date) {
        return false
      }
      
      // If max date is set and date is after max date, exclude the row
      if (maxDate) {
        const maxDateObj = new Date(maxDate)
        maxDateObj.setHours(23, 59, 59, 999) // Set to end of day
        if (maxDateObj < date) {
          return false
        }
      }
      
      // Include the row if it passed all filters
      return true
    }
  }
  
  connect() {
    // Basic initialization with a single animation frame
    requestAnimationFrame(() => {
      this.cleanupDataTables()
      this.formatDateCells()
      this.applyStatusBadgeColors()
      this.initializeDataTable()
      this.setupEventListeners()
    })
  }
  
  disconnect() {
    // Remove event listeners
    document.removeEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
    document.removeEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    
    // Remove the date range filter function
    const filterIndex = $.fn.dataTable.ext.search.indexOf(this.dateRangeFilter)
    if (filterIndex !== -1) {
      $.fn.dataTable.ext.search.splice(filterIndex, 1)
    }
    
    this.cleanupDataTables()
  }
  
  cleanupBeforeNavigation = () => {
    this.cleanupDataTables()
  }
  
  cleanupDataTables() {
    // Clean up any instances we've created
    this.dataTableInstances.forEach(dt => {
      try {
        if (dt && typeof dt.destroy === 'function') {
          dt.destroy(true) // true = remove DOM changes
        }
      } catch (err) {
        console.warn('Error cleaning up DataTable:', err)
      }
    })
    
    // Reset the instances array
    this.dataTableInstances = []
    
    // Also attempt to clean any tables in the DOM
    try {
      const table = document.getElementById('datatable')
      if (table && $.fn.DataTable.isDataTable(table)) {
        $(table).DataTable().destroy(true)
      }
      
      // No need to remove filter container as it's now part of the view
    } catch (err) {
      // Ignore errors
    }
  }
  
  
  formatDateCells() {
    // Dates are now formatted server-side with the user's preferences
    // using the Rails helper format_datetime in the view
    // This method remains for compatibility but does nothing
  }
  
  applyStatusBadgeColors() {
    try {
      // Apply status badge colors based on status
      document.querySelectorAll('#datatable tbody tr').forEach(row => {
        const statusCell = row.querySelector('td:nth-child(3) .badge')
        const statusValue = row.querySelector('td:nth-child(3) span.d-none')?.textContent.trim()
        
        if (statusCell && statusValue) {
          // Remove any existing badge classes
          ['badge-soft-success', 'badge-soft-warning', 'badge-soft-danger', 'badge-soft-info', 'badge-soft-dark'].forEach(cls => {
            statusCell.classList.remove(cls)
          })
          
          switch (statusValue) {
            case 'succeeded':
              statusCell.classList.add('badge-soft-success')
              break
            case 'pending':
              statusCell.classList.add('badge-soft-warning')
              break
            case 'failed':
              statusCell.classList.add('badge-soft-danger')
              break
            case 'refunded':
              statusCell.classList.add('badge-soft-info')
              break
            case 'canceled':
              statusCell.classList.add('badge-soft-dark')
              break
            default:
              // Fallback for any other status values
              statusCell.classList.add('badge-soft-secondary')
          }
        }
      })
    } catch (error) {
      console.warn('Error applying status badges:', error)
    }
  }
  
  initializeDataTable() {
    try {
      const table = document.getElementById('datatable')
      if (!table) return
      
      // Initialize with options that help with Turbo
      const config = {
        autoWidth: true,
        responsive: true,
        order: [[1, 'desc']], // Order by date column (index 1) in descending order
        columnDefs: [
          { orderable: false, targets: 5 }, // Make Receipt column (index 5) non-orderable
          { className: "fw-medium", targets: 0 }, // Payment ID column
          { className: "text-nowrap", targets: 1 } // Date column
        ],
        language: {
          url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
          emptyTable: window.i18n?.noPaymentRecords || "No payment records found",
          zeroRecords: window.i18n?.noMatchingRecords || "No matching payment records found",
          info: window.i18n?.showingPayments || "Showing _START_ to _END_ of _TOTAL_ payments",
          search: `<i class='bx bx-search fs-5 me-2'></i>${window.i18n?.search || "Search"}:`
        },
        dom: "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        retrieve: true,
        destroy: true
      }
      
      // Use jQuery-style initialization
      const dtInstance = $(table).DataTable(config)
      if (dtInstance) {
        this.dataTableInstances.push(dtInstance)
        // Store reference for filters
        this.dataTable = dtInstance
      }
    } catch (error) {
      console.error('Error initializing DataTable:', error)
    }
  }
  
  setupEventListeners() {
    try {
      // Add date range filter
      $.fn.dataTable.ext.search.push(this.dateRangeFilter)
      
      // Custom filtering function for status
      const statusFilter = document.getElementById('status-filter')
      if (statusFilter && this.dataTable) {
        statusFilter.addEventListener('change', () => {
          const value = statusFilter.value.toLowerCase()
          this.dataTable.columns(2).search(value).draw()
        })
      }
      
      // Apply date filter when date inputs change
      const dateMin = document.getElementById('date-min')
      if (dateMin && this.dataTable) {
        dateMin.addEventListener('change', () => {
          this.dataTable.draw()
        })
      }
      
      const dateMax = document.getElementById('date-max')
      if (dateMax && this.dataTable) {
        dateMax.addEventListener('change', () => {
          this.dataTable.draw()
        })
      }
      
      // Clear all filters button
      const clearFilters = document.getElementById('clear-filters')
      if (clearFilters && this.dataTable) {
        clearFilters.addEventListener('click', () => {
          if (dateMin) dateMin.value = ''
          if (dateMax) dateMax.value = ''
          if (statusFilter) statusFilter.value = ''
          this.dataTable.columns(2).search('').draw()
        })
      }
      
      // Initialize popovers
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      popoverTriggerList.forEach(popoverTriggerEl => {
        new bootstrap.Popover(popoverTriggerEl)
      })
    } catch (error) {
      console.warn('Error setting up event listeners:', error)
    }
  }
}
