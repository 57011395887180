import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "tab",
        "pane",
        "namesCheckbox",
        "identifierCheckbox",
        "identifierFields",
        "identifierLabel",
        "identifierPlaceholder",
        "buttonTarget",
        "previewForm",
        "previewNames",
        "previewIdentifier",
        "previewIdentifierLabel",
        "hostedCheckbox",
        "hostedFields",
        "pageTitle",
        "pageTitleField",
        "previewTitle",
        "pageDescription",
        "pageDescriptionField",
        "previewDescription",
        "termsCheckbox",
        "termsUrl",
        "previewTerms",
        "desktopView",
        "mobileView",
        "preview"
    ]

    connect() {
        try {
            // Check if all required targets exist
            if (!this.hasTabTarget) {
                console.log("Integration controller: tab target not found, skipping initialization")
                return
            }
            
            // Initialize preview
            this.updateAllPreviewElements()
            
            // Set initial active tab
            if (this.tabTargets.length > 0) {
                this.activate({ preventDefault: () => {}, currentTarget: this.tabTargets[0] })
            }
        } catch (error) {
            console.error("Error initializing integration controller:", error)
        }
    }
    
    updateAllPreviewElements() {
        // Update all preview elements based on current form values
        // Names preview
        if (this.hasNamesCheckboxTarget && this.hasPreviewNamesTarget) {
            this.previewNamesTarget.style.display = this.namesCheckboxTarget.checked ? 'block' : 'none'
        }
        
        // Identifier preview
        if (this.hasIdentifierCheckboxTarget && this.hasPreviewIdentifierTarget) {
            this.previewIdentifierTarget.style.display = this.identifierCheckboxTarget.checked ? 'block' : 'none'
        }
        
        // Label preview
        if (this.hasIdentifierLabelTarget && this.hasPreviewIdentifierLabelTarget) {
            this.previewIdentifierLabelTarget.textContent = this.identifierLabelTarget.value
        }
        
        // Title preview
        if (this.hasPageTitleTarget && this.hasPreviewTitleTarget) {
            const showTitle = this.pageTitleTarget.checked
            this.previewTitleTarget.style.display = showTitle ? 'block' : 'none'
            
            if (this.hasPageTitleFieldTarget && showTitle) {
                const titleText = this.pageTitleFieldTarget.querySelector('input').value
                if (this.previewTitleTarget.querySelector('h2')) {
                    this.previewTitleTarget.querySelector('h2').textContent = titleText
                }
            }
        }
        
        // Description preview
        if (this.hasPageDescriptionTarget && this.hasPreviewDescriptionTarget) {
            const showDescription = this.pageDescriptionTarget.checked
            this.previewDescriptionTarget.style.display = showDescription ? 'block' : 'none'
            
            if (this.hasPageDescriptionFieldTarget && showDescription) {
                const descText = this.pageDescriptionFieldTarget.querySelector('textarea').value
                if (this.previewDescriptionTarget.querySelector('p')) {
                    this.previewDescriptionTarget.querySelector('p').textContent = descText
                }
            }
        }
        
        // Terms preview
        if (this.hasTermsCheckboxTarget && this.hasPreviewTermsTarget) {
            this.previewTermsTarget.style.display = this.termsCheckboxTarget.checked ? 'block' : 'none'
        }
    }

    // Tab activation
    activate(event) {
        event.preventDefault()

        // Remove active class from all tabs and panes
        this.tabTargets.forEach(tab => {
            tab.classList.remove('active')
        })
        this.paneTargets.forEach(pane => {
            pane.classList.remove('active')
        })

        // Add active class to clicked tab
        const tab = event.currentTarget
        tab.classList.add('active')

        // Show corresponding pane
        const tabId = tab.getAttribute('data-tab')
        const pane = this.paneTargets.find(p => p.id === tabId)
        if (pane) {
            pane.classList.add('active')
        }
    }

    // Rest of the controller methods remain the same...
    toggleNames(event) {
        const showNames = event.target.checked
        if (this.hasPreviewNamesTarget) {
            this.previewNamesTarget.style.display = showNames ? 'block' : 'none'
        }
    }

    toggleIdentifier(event) {
        const showIdentifier = event.target.checked
        if (this.hasIdentifierFieldsTarget) {
            this.identifierFieldsTarget.style.display = showIdentifier ? 'block' : 'none'
        }
        if (this.hasPreviewIdentifierTarget) {
            this.previewIdentifierTarget.style.display = showIdentifier ? 'block' : 'none'
        }
    }

    updateIdentifierLabel(event) {
        if (this.hasPreviewIdentifierLabelTarget) {
            this.previewIdentifierLabelTarget.textContent = event.target.value
        }
    }

    updateIdentifierPlaceholder(event) {
        if (this.hasPreviewIdentifierTarget) {
            this.previewIdentifierTarget.querySelector('input').placeholder = event.target.value
        }
    }

    togglePageTitle(event) {
        const showTitle = event.target.checked
        if (this.hasPageTitleFieldTarget) {
            this.pageTitleFieldTarget.style.display = showTitle ? 'block' : 'none'
        }
        if (this.hasPreviewTitleTarget) {
            this.previewTitleTarget.style.display = showTitle ? 'block' : 'none'
        }
    }

    updatePageTitle(event) {
        if (this.hasPreviewTitleTarget) {
            this.previewTitleTarget.querySelector('h2').textContent = event.target.value
        }
    }

    togglePageDescription(event) {
        const showDescription = event.target.checked
        if (this.hasPageDescriptionFieldTarget) {
            this.pageDescriptionFieldTarget.style.display = showDescription ? 'block' : 'none'
        }
        if (this.hasPreviewDescriptionTarget) {
            this.previewDescriptionTarget.style.display = showDescription ? 'block' : 'none'
        }
    }

    updatePageDescription(event) {
        if (this.hasPreviewDescriptionTarget) {
            this.previewDescriptionTarget.querySelector('p').textContent = event.target.value
        }
    }

    toggleTerms(event) {
        const showTerms = event.target.checked
        if (this.hasPreviewTermsTarget) {
            this.previewTermsTarget.style.display = showTerms ? 'block' : 'none'
        }
    }

    switchView(event) {
        const view = event.currentTarget.dataset.view

        this.desktopViewTarget.classList.toggle('active', view === 'desktop')
        this.mobileViewTarget.classList.toggle('active', view === 'mobile')

        if (this.hasPreviewTarget) {
            if (view === 'mobile') {
                this.previewTarget.classList.add('max-w-sm', 'mx-auto')
            } else {
                this.previewTarget.classList.remove('max-w-sm', 'mx-auto')
            }
        }
    }

    copyToClipboard(event) {
        const button = event.currentTarget
        const input = button.previousElementSibling

        navigator.clipboard.writeText(input.value)

        const icon = button.querySelector('i')
        const originalClass = icon.className
        icon.className = 'fas fa-check'

        setTimeout(() => {
            icon.className = originalClass
        }, 2000)
    }

    updateRateLimit(event) {
        const input = event.target
        const min = parseInt(input.min)
        const max = parseInt(input.max)
        let value = parseInt(input.value)

        if (value < min) value = min
        if (value > max) value = max

        input.value = value
    }
}