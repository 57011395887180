import "@popperjs/core"

import DataTable from "datatables.net"
window.DataTable = DataTable

import "datatables.net-bs5"
//import "datatables.net-autofill-bs5"
//import "datatables.net-buttons-bs5"
//import "datatables.net-keytable-bs5"
//import "datatables.net-responsive-bs5"
//import "datatables.net-select-bs5"

import "dropzone"
import "ika.jvectormap"
import "intl-tel-input"
import "node-waves"

import "vendor/jsvectormap/js/jsvectormap.min.js"

// Import modern sidebar component
import "./modern-sidebar"
