import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Initialize the controller
    console.log("Data localization controller connected");
    
    // Set up modal event handler to transfer the selected ID
    const modal = document.getElementById('storageLocationModal');
    if (modal) {
      modal.addEventListener('show.bs.modal', this.prepareModalData.bind(this));
    }
  }
  
  prepareModalData() {
    // Copy selected location ID to confirmation form
    const selectedLocationId = document.getElementById('selected_location_id').value;
    document.getElementById('confirm_location_id').value = selectedLocationId;
    
    // Find the selected location name for display
    const selectedElement = document.querySelector(`.aml-radio-option[data-location-id="${selectedLocationId}"]`);
    if (selectedElement) {
      // Get the country name without any badges
      const labelElement = selectedElement.querySelector('.aml-radio-option__label');
      const countryName = labelElement.childNodes[0].textContent.trim();
      const locationName = selectedElement.querySelector('.aml-radio-option__description').textContent.trim();
      
      // Update the alert text in the modal
      const alertElement = document.querySelector('#storageLocationModal .alert-warning');
      if (alertElement) {
        alertElement.innerHTML = `<i class="mdi mdi-alert-circle me-2"></i>You are about to change your data storage location to <strong>${countryName} (${locationName})</strong>`;
      }
    }
  }
  
  selectLocation(event) {
    // Remove selected class from all options
    const allOptions = document.querySelectorAll('.aml-radio-option');
    allOptions.forEach(option => {
      option.classList.remove('aml-radio-option--selected');
    });
    
    // Add selected class to clicked option
    const clickedOption = event.currentTarget;
    clickedOption.classList.add('aml-radio-option--selected');
    
    // Update the hidden field with selected location ID
    const locationId = clickedOption.dataset.locationId;
    document.getElementById('selected_location_id').value = locationId;
  }
}