import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["step", "progressBar", "stepIndicator", "errorMessage", "submitButton", "normalState", "loadingState", "confirmButton", "confirmNormalState", "confirmLoadingState", "countrySelector"]
  static values = {
    currentStep: { type: Number, default: 1 },
    totalSteps: { type: Number, default: 4 },
    stripePublicKey: String,
    dashboardBillingContactPath: String,
    defaultCountry: String,
    locale: String,
    requestInProgress: { type: Boolean, default: false }
  }
  
  // Country data received from API
  countryData = null

  connect() {
    console.log("Billing Wizard controller connected")
    console.log("Stripe public key:", this.stripePublicKeyValue)
    console.log("Organization token from path:", this.dashboardBillingContactPathValue?.split('/').pop())

    // Get the base URL from the data attribute
    this.baseUrl = this.element.dataset.baseUrl || ''
    console.log("Base URL:", this.baseUrl)

    // Initialize the request flag
    this.requestInProgressValue = false
    
    // Set up global form state for debugging and interop with old code
    window.PAYMENT_FORM_STATE = {
      currentStep: 1,
      stripeSetup: false,
      clientSecret: null,
      setupIntentId: null,
      paymentMethodId: null,
      cardComplete: false
    }
    
    // Initialize the wizard without waiting for Stripe
    this.initializeWizard()
    
    // Initialize Choices.js for the country selector if available
    this.initCountrySelector()

    const changeModal = document.getElementById('updateBillingModal');
    const _this = this; // Save controller reference to use in the event listener
    
    changeModal.addEventListener('show.bs.modal', function (event) {
      const button = event.relatedTarget;

      // Get the plan data
      const frame = document.querySelector("#plan_in_wizard");  // Get the turbo-frame element inside modal
      const planName = button.getAttribute('data-bs-plan');
      const baseUrl = button.getAttribute('data-bs-base-url');
      const url = `plans/change?plan=${planName}&in_frame=wizard`;
      frame.setAttribute("src", url);
      console.log("Base url:", baseUrl)

      // Store planName both globally and on the controller instance
      window.selectedPlanName = planName;
      _this.selectedPlanName = planName; // Add to controller instance
      console.log("Selected plan name:", planName);

    });


  }
  
  initCountrySelector() {
    if (!this.hasCountrySelectorTarget) return
    
    // Import Choices dynamically
    import('choices.js').then(({ default: Choices }) => {
      // Create a new Choices instance
      this.countrySelector = new Choices(this.countrySelectorTarget, {
        searchEnabled: true,
        searchPlaceholderValue: 'Search countries...',
        placeholderValue: 'Select a country',
        searchResultLimit: 10,
        removeItemButton: true,
        itemSelectText: '',
        classNames: {
          containerOuter: 'choices country-choices',
        },
        fuseOptions: {
          threshold: 0.3,
        }
      })
      
      // Get the initial selected country, if any
      const initialCountryId = this.countrySelectorTarget.value
      if (initialCountryId) {
        console.log('Initial country selected:', initialCountryId)
        // Store country ID in a hidden field
        let hiddenField = document.getElementById('country-id-hidden')
        if (!hiddenField) {
          hiddenField = document.createElement('input')
          hiddenField.type = 'hidden'
          hiddenField.id = 'country-id-hidden'
          hiddenField.name = 'country_id'
          this.element.appendChild(hiddenField)
        }
        hiddenField.value = initialCountryId
        
        // Fetch country information for the initial selection
        this.fetchCountryInformation(initialCountryId)
      }
      
      // Store the selected country ID in a hidden field for form submission
      this.countrySelectorTarget.addEventListener('change', (event) => {
        const countryId = event.target.value
        console.log('Country selected:', countryId)
        
        // Store country ID in a hidden field
        let hiddenField = document.getElementById('country-id-hidden')
        if (!hiddenField) {
          hiddenField = document.createElement('input')
          hiddenField.type = 'hidden'
          hiddenField.id = 'country-id-hidden'
          hiddenField.name = 'country_id'
          this.element.appendChild(hiddenField)
        }
        hiddenField.value = countryId
        
        // Fetch country information
        this.fetchCountryInformation(countryId)
      })
    }).catch(error => {
      console.error('Failed to load Choices.js:', error)
    })
  }
  
  fetchCountryInformation(countryId) {
    if (!countryId) return
    
    // Use the baseUrl instance property from data attribute
    const url = `${this.baseUrl}/billing/contact/get_country_information?country_id=${countryId}`
    console.log('Fetching country information from:', url)
    
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || ''
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      return response.json()
    })
    .then(data => {
      console.log('Country information received:', data)
      this.countryData = data
      this.handleCountryData(data)
    })
    .catch(error => {
      console.error('Error fetching country information:', error)
    })
  }
  
  handleCountryData(data) {
    if (!data) return
    
    console.log('Handling country data:', data)
    
    // Handle sanctions check
    if (data.sanctions === true) {
      this.showSanctionsWarning()
    } else {
      this.hideSanctionsWarning()
    }
    
    // Update the tax ID field label based on country data
    this.updateTaxIdLabel(data)
  }
  
  updateTaxIdLabel(data) {
    console.log('Updating tax ID field with data:', data)
    
    // Force label update regardless of field ID
    // This is a direct approach that doesn't rely on field IDs
    const taxFields = document.querySelectorAll('.form-floating input[placeholder="Tax ID / VAT Number"]')
    console.log(`Found ${taxFields.length} tax ID fields`)
    
    if (taxFields.length === 0) {
      console.error('Tax ID field not found by placeholder')
      return
    }
    
    // Use the first matching field
    const vatField = taxFields[0]
    console.log('Found tax field with ID:', vatField.id)
    
    // Get the field's parent and find the label within it
    const parentFloating = vatField.closest('.form-floating')
    if (!parentFloating) {
      console.error('Parent floating container not found for tax field')
      return
    }
    
    const vatLabel = parentFloating.querySelector('label')
    if (!vatLabel) {
      console.error('Label not found within floating container')
      return
    }
    
    console.log('Found label:', vatLabel.textContent)
    
    // Update the label text with the tax_id_company value
    // If tax_id_company is not provided, use "Company number" as fallback
    const labelText = data.tax_id_company || 'Company number'
    console.log('Setting label text to:', labelText)
    vatLabel.textContent = labelText
    
    // Update field ID and related elements based on EU VAT status
    if (data.eu_vat === true) {
      console.log('Country has EU VAT, updating field ID')
      const oldId = vatField.id
      
      // Update the field ID and name
      vatField.id = 'billing_paymethod_company_eu_vat_number'
      vatField.name = 'organization[billing_paymethod_company_eu_vat_number]'
      
      // Update the label's 'for' attribute to match the new ID
      vatLabel.setAttribute('for', 'billing_paymethod_company_eu_vat_number')
      
      console.log(`Updated field ID from ${oldId} to ${vatField.id}`)
    } else {
      console.log('Country does not have EU VAT, using standard tax ID field')
      const oldId = vatField.id
      
      // Reset to standard ID and name
      vatField.id = 'stripe-tax-id'
      vatField.name = 'organization[billing_paymethod_company_vat_number]'
      
      // Update the label's 'for' attribute
      vatLabel.setAttribute('for', 'stripe-tax-id')
      
      console.log(`Updated field ID from ${oldId} to ${vatField.id}`)
    }
  }
  
  showSanctionsWarning() {
    // Find or create the sanctions warning element
    let sanctionsWarning = document.getElementById('sanctions-warning')
    if (!sanctionsWarning) {
      sanctionsWarning = document.createElement('div')
      sanctionsWarning.id = 'sanctions-warning'
      sanctionsWarning.className = 'alert alert-danger mt-3 mb-3 rounded-pill'
      sanctionsWarning.innerHTML = `
        <div class="d-flex align-items-center">
          <i class="fa-regular fa-exclamation-triangle text-danger me-2"></i>
          <div>This country is under sanctions. We cannot proceed with your registration.</div>
        </div>
      `
      
      // Insert after the country selector
      const countryWrapper = this.countrySelectorTarget.closest('.country-select-wrapper')
      if (countryWrapper) {
        countryWrapper.insertAdjacentElement('afterend', sanctionsWarning)
      } else {
        // Fallback - insert before first step button
        const nextButton = this.element.querySelector('.next-step[data-step="1"]')
        if (nextButton) {
          nextButton.parentElement.insertBefore(sanctionsWarning, nextButton)
        }
      }
    } else {
      sanctionsWarning.classList.remove('d-none')
    }
    
    // Disable the next button
    const nextButton = this.element.querySelector('.next-step[data-step="1"]')
    if (nextButton) {
      nextButton.disabled = true
      nextButton.classList.add('disabled')
    }
  }
  
  hideSanctionsWarning() {
    // Hide the sanctions warning if it exists
    const sanctionsWarning = document.getElementById('sanctions-warning')
    if (sanctionsWarning) {
      sanctionsWarning.classList.add('d-none')
    }
    
    // Enable the next button
    const nextButton = this.element.querySelector('.next-step[data-step="1"]')
    if (nextButton) {
      nextButton.disabled = false
      nextButton.classList.remove('disabled')
    }
  }
  
  // This method is called when the modal is shown
  initializeStripe(event) {
    console.log("DIRECT SCRIPT: Payment page loaded")
    console.log("*********** MODAL SHOW EVENT TRIGGERED ***********")
    console.log("Stripe global object available:", typeof Stripe !== 'undefined')
    console.log("Modal element found:", !!document.querySelector('#updateBillingModal'))
    console.log("Payment element exists:", !!document.querySelector('#payment-element'))
    
    // Reset the flag for new session
    this.requestInProgressValue = false
    console.log("Modal opened, resetting request in progress flag")
    
    // Reset the form state when opening
    window.PAYMENT_FORM_STATE = {
      currentStep: 1,
      stripeSetup: false,
      clientSecret: null,
      setupIntentId: null,
      paymentMethodId: null,
      cardComplete: false
    }
    
    // Make sure first step is visible when modal opens
    this.goToStep(1)
    
    // Check for initial country selection
    if (this.hasCountrySelectorTarget && this.countrySelectorTarget.value) {
      console.log('Modal opened with country already selected:', this.countrySelectorTarget.value)
      // Fetch country information for the preselected country
      this.fetchCountryInformation(this.countrySelectorTarget.value)
    }
    
    // Initialize directly
    this.initializeStripeDirectly()
  }
  
  // Key DOM elements access methods
  getDebugOutput() {
    return document.getElementById('debug-output')
  }
  
  getPaymentElement() {
    return document.getElementById('payment-element')
  }
  
  getCardElement() {
    return document.getElementById('card-element')
  }
  
  // Log function from the inline script
  log(message, isError = false) {
    console.log(message)
    const debugOutput = this.getDebugOutput()
    if (debugOutput) {
      const entry = document.createElement('div')
      entry.className = isError ? 'text-danger' : 'text-info'
      entry.textContent = message
      debugOutput.appendChild(entry)
    }
  }
  
  // Direct implementation from the inline script
  initializeStripeDirectly() {
    this.log("Initializing Stripe directly with key: " + this.stripePublicKeyValue)
    
    // First, verify Stripe is available
    if (typeof Stripe === 'undefined') {
      this.log("ERROR: Stripe not available", true)
      // Try loading it directly
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/'
      script.onload = () => {
        this.log("Stripe loaded dynamically, retrying...")
        setTimeout(() => this.initializeStripeDirectly(), 500)
      }
      document.head.appendChild(script)
      return
    }
    
    this.log("Stripe is available, creating instance")
    
    try {
      // Create Stripe instance
      const stripe = Stripe(this.stripePublicKeyValue, {
        apiVersion: '2020-08-27'
      })
      
      this.log("Stripe instance created, fetching client secret")
      
      // Create a "Getting client secret" message
      const paymentElement = this.getPaymentElement()
      if (paymentElement) {
        paymentElement.innerHTML = `
          <div class="text-center p-3">
            <div class="spinner-border text-primary" role="status"></div>
            <p class="mt-2">Getting payment token...</p>
          </div>
        `
      }
      
      // Get org token from path
      const orgToken = this.dashboardBillingContactPathValue.split('/').pop()
      
      // Prevent duplicate requests
      if (this.requestInProgressValue) {
        this.log("Request for client secret already in progress, skipping duplicate request")
        return
      }
      
      this.requestInProgressValue = true
      this.log("Setting request flag to prevent duplicates")
      
      // Get client secret from backend with organization token
      fetch(this.dashboardBillingContactPathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          do_what: 'get_payment_method_secret'
        })
      })
      .then(response => {
        this.log(`Server responded with status ${response.status}`)
        return response.text()
      })
      .then(text => {
        this.log(`Response text: ${text.substring(0, 50)}...`)
        try {
          return JSON.parse(text)
        } catch (e) {
          this.log(`ERROR: JSON parse error: ${e.message}`, true)
          throw e
        }
      })
      .then(data => {
        if (!data.client_secret) {
          this.log("ERROR: No client secret in response", true)
          throw new Error("Missing client secret")
        }
        
        this.log(`Client secret received: ${data.client_secret.substring(0, 10)}...`)
        
        // Show a new message
        const paymentElement = this.getPaymentElement()
        if (paymentElement) {
          paymentElement.innerHTML = `
            <div class="text-center p-3">
              <div class="spinner-border text-primary" role="status"></div>
              <p class="mt-2">Creating payment form...</p>
            </div>
          `
        }
        
        // Check if dark mode is active
        const isDarkMode = document.documentElement.getAttribute('data-bs-theme') === 'dark';
        
        // Create Stripe Elements
        const elements = stripe.elements({
          clientSecret: data.client_secret,
          locale: this.localeValue || 'auto', // Use the locale from the controller or fallback to auto-detection
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#7367f0',
              colorBackground: isDarkMode ? '#2a2f34' : '#ffffff',
              colorText: isDarkMode ? '#e9ecef' : '#212529',
              colorDanger: '#dc3545',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial',
              // Dark mode specific customizations
              colorComponentBackground: isDarkMode ? '#343a40' : '',
              colorComponentBorder: isDarkMode ? '#495057' : '',
              colorComponentText: isDarkMode ? '#e9ecef' : '',
              colorPlaceholderText: isDarkMode ? '#adb5bd' : ''
            }
          }
        })
        
        this.log("Elements created, creating payment element")
        
        // Create payment element
        const paymentFormElement = elements.create('payment', {
          layout: {
            type: 'tabs',
            defaultCollapsed: false
          }
        })
        
        this.log("Payment element created, clearing container")
        
        // Clear payment container
        if (paymentElement) {
          paymentElement.innerHTML = ''
        }
        
        this.log("Mounting payment element")
        
        // Mount it
        try {
          paymentFormElement.mount('#payment-element')
          this.log("Payment element mounted successfully!")
          
          // Store in global scope and form state for persistent access
          window.stripeElements = elements
          window.stripeInstance = stripe
          window.stripeSecret = data.client_secret
          
          // Also store in form state object
          if (window.PAYMENT_FORM_STATE) {
            window.PAYMENT_FORM_STATE.stripeSetup = true
            window.PAYMENT_FORM_STATE.clientSecret = data.client_secret
            window.PAYMENT_FORM_STATE.elements = elements
            window.PAYMENT_FORM_STATE.stripe = stripe
          }
          
          // Find the payment-next-btn and reattach its handler
          const nextButton = document.getElementById('payment-next-btn')
          if (nextButton) {
            this.log("Found next button, attaching payment handler")
            
            // Remove any existing listeners
            nextButton.replaceWith(nextButton.cloneNode(true))
            
            // Get the new button
            const newNextButton = document.getElementById('payment-next-btn')
            
            // Add the event listener to the new button
            newNextButton.addEventListener('click', async (e) => {
              e.preventDefault()
              this.log("Next button clicked, processing payment setup...")
              
              // Set loading state
              const normalState = newNextButton.querySelector('.normal-state')
              const loadingState = newNextButton.querySelector('.loading-state')
              if (normalState && loadingState) {
                normalState.classList.add('d-none')
                loadingState.classList.remove('d-none')
              }
              newNextButton.disabled = true
              
              try {
                // Confirm the setup
                const result = await stripe.confirmSetup({
                  elements,
                  redirect: 'if_required',
                  confirmParams: {
                    return_url: window.location.href
                  }
                })
                
                if (result.error) {
                  this.log(`Error confirming setup: ${result.error.message}`, true)
                  
                  // Show error in payment message
                  const paymentMessage = document.getElementById('payment-message')
                  if (paymentMessage) {
                    paymentMessage.innerHTML = `
                      <div class="d-flex align-items-center">
                        <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
                        <div>${result.error.message}</div>
                      </div>
                    `
                    paymentMessage.classList.remove('d-none')
                    paymentMessage.classList.add('alert-danger')
                  }
                  
                  // Reset button state
                  if (normalState && loadingState) {
                    normalState.classList.remove('d-none')
                    loadingState.classList.add('d-none')
                  }
                  newNextButton.disabled = false
                  return
                }
                
                // Success! Got setupIntent
                this.log(`Setup confirmed successfully: ${result.setupIntent.id}`)
                
                // Store payment info in the global form state
                if (window.PAYMENT_FORM_STATE) {
                  window.PAYMENT_FORM_STATE.setupIntentId = result.setupIntent.id
                  window.PAYMENT_FORM_STATE.paymentMethodId = result.setupIntent.payment_method
                  window.PAYMENT_FORM_STATE.cardComplete = true
                }
                
                // Also store in window for backward compatibility
                window.setupIntentId = result.setupIntent.id
                window.paymentMethodId = result.setupIntent.payment_method
                
                this.log(`Payment data stored in form state: 
                  - Setup Intent: ${result.setupIntent.id}
                  - Payment Method: ${result.setupIntent.payment_method}
                `)
                
                // Create global function to access payment details safely
                window.getPaymentDetails = function() {
                  return {
                    setupIntentId: window.PAYMENT_FORM_STATE?.setupIntentId || window.setupIntentId,
                    paymentMethodId: window.PAYMENT_FORM_STATE?.paymentMethodId || window.paymentMethodId,
                    clientSecret: window.PAYMENT_FORM_STATE?.clientSecret || window.stripeSecret
                  }
                }
                
                // Get country ID from selector or hidden field
                const countryId = this.hasCountrySelectorTarget ? this.countrySelectorTarget.value : 
                                  document.getElementById('country-id-hidden')?.value || '';
                
                // Get tax ID value based on country type (EU VAT or standard)
                let taxIdValue = '';
                let euVatValue = '';
                
                if (this.countryData && this.countryData.eu_vat === true) {
                  // If country has EU VAT, use the EU VAT field
                  euVatValue = document.getElementById('billing_paymethod_company_eu_vat_number')?.value || 
                              document.getElementById('stripe-tax-id')?.value || '';
                } else {
                  // Otherwise use the standard tax ID field
                  taxIdValue = document.getElementById('stripe-tax-id')?.value || '';
                }
                
                // Send the data to the server
                const formData = {
                  organization: {
                    billing_paymethod_company_name: document.getElementById('stripe-company-name').value,
                    billing_paymethod_email: document.getElementById('stripe-billing-email').value,
                    website_url: document.getElementById('stripe-website')?.value || '',
                    billing_paymethod_address_line1: document.getElementById('stripe-address-line1').value,
                    billing_paymethod_address_line2: document.getElementById('stripe-address-line2')?.value || '',
                    billing_paymethod_address_city: document.getElementById('stripe-city').value,
                    billing_paymethod_address_state: document.getElementById('stripe-state')?.value || '',
                    billing_paymethod_address_postal_code: document.getElementById('stripe-postal-code').value,
                    billing_paymethod_address_country_id: countryId,
                    billing_paymethod_company_vat_number: taxIdValue,
                    billing_paymethod_company_eu_vat_number: euVatValue
                  },
                  setup_intent: {
                    id: result.setupIntent.id,
                    payment_method: result.setupIntent.payment_method,
                    client_secret: data.client_secret
                  }
                }
                
                this.log("Saving data to server...")
                
                // Post to save_data endpoint - URL is built to work with the resource route
                fetch(`${this.baseUrl}/billing/contact/save_data`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
                    'Accept': 'application/json'
                  },
                  body: JSON.stringify(formData)
                })
                .then(response => {
                  this.log(`Save data response: ${response.status}`)
                  return response.json()
                })
                .then(data => {
                  if (data.success) {
                    this.log("Data saved successfully!")
                    
                    // Show success message
                    const paymentMessage = document.getElementById('payment-message')
                    if (paymentMessage) {
                      paymentMessage.innerHTML = `
                        <div class="d-flex align-items-center">
                          <i class="fa-regular fa-check-circle text-success me-2"></i>
                          <div>Payment method saved successfully!</div>
                        </div>
                      `
                      paymentMessage.classList.remove('d-none')
                      paymentMessage.classList.add('alert-success')
                      paymentMessage.classList.remove('alert-danger')
                    }
                    
                    // Go to next step
                    this.goToStep(4)
                    
                  } else {
                    this.log(`Error saving data: ${data.message || 'Unknown error'}`, true)
                    
                    // Show error message
                    const paymentMessage = document.getElementById('payment-message')
                    if (paymentMessage) {
                      paymentMessage.innerHTML = `
                        <div class="d-flex align-items-center">
                          <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
                          <div>${data.message || 'Error saving payment details'}</div>
                        </div>
                      `
                      paymentMessage.classList.remove('d-none')
                      paymentMessage.classList.add('alert-danger')
                    }
                    
                    // Reset button state
                    if (normalState && loadingState) {
                      normalState.classList.remove('d-none')
                      loadingState.classList.add('d-none')
                    }
                    newNextButton.disabled = false
                  }
                })
                .catch(error => {
                  this.log(`Error saving data: ${error.message}`, true)
                  
                  // Show error message
                  const paymentMessage = document.getElementById('payment-message')
                  if (paymentMessage) {
                    paymentMessage.innerHTML = `
                      <div class="d-flex align-items-center">
                        <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
                        <div>Error saving payment details: ${error.message}</div>
                      </div>
                    `
                    paymentMessage.classList.remove('d-none')
                    paymentMessage.classList.add('alert-danger')
                  }
                  
                  // Reset button state
                  if (normalState && loadingState) {
                    normalState.classList.remove('d-none')
                    loadingState.classList.add('d-none')
                  }
                  newNextButton.disabled = false
                })
              } catch (error) {
                this.log(`Unexpected error during payment processing: ${error.message}`, true)
                
                // Show error message
                const paymentMessage = document.getElementById('payment-message')
                if (paymentMessage) {
                  paymentMessage.innerHTML = `
                    <div class="d-flex align-items-center">
                      <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
                      <div>Unexpected error: ${error.message}</div>
                    </div>
                  `
                  paymentMessage.classList.remove('d-none')
                  paymentMessage.classList.add('alert-danger')
                }
                
                // Reset button state
                if (normalState && loadingState) {
                  normalState.classList.remove('d-none')
                  loadingState.classList.add('d-none')
                }
                newNextButton.disabled = false
              }
            })
          } else {
            this.log("Warning: Next button not found", true)
          }
        } catch (e) {
          this.log(`ERROR mounting payment element: ${e.message}`, true)
          
          // Try with card element as backup
          try {
            this.log("Trying card element as backup")
            const cardFormElement = elements.create('card')
            cardFormElement.mount('#card-element')
            this.log("Card element mounted as backup")
          } catch (cardError) {
            this.log(`ERROR mounting card element: ${cardError.message}`, true)
          }
        }
      })
      .catch(error => {
        this.log(`ERROR: ${error.message}`, true)
        
        const paymentElement = this.getPaymentElement()
        if (paymentElement) {
          paymentElement.innerHTML = `
            <div class="alert alert-danger p-3">
              <h5>Error loading payment form</h5>
              <p>${error.message}</p>
              <button class="btn btn-sm btn-primary mt-2" onclick="location.reload()">Reload page</button>
            </div>
          `
        }
      })
      .finally(() => {
        // Reset the request flag to allow future requests
        this.requestInProgressValue = false
        this.log("Request completed, resetting request flag")
      })
      
    } catch (error) {
      this.log(`ERROR creating Stripe instance: ${error.message}`, true)
    }
  }
  
  // Helper method to ensure Stripe is loaded
  ensureStripeLoaded() {
    return new Promise((resolve, reject) => {
      let attempts = 0;
      const maxAttempts = 3;
      
      const tryLoadStripe = () => {
        attempts++;
        console.log(`Attempting to load Stripe (attempt ${attempts}/${maxAttempts})`)
        
        if (typeof Stripe !== 'undefined') {
          console.log("Stripe already loaded in billing wizard")
          try {
            this.stripe = Stripe(this.stripePublicKeyValue)
            resolve()
          } catch (error) {
            console.error("Error initializing Stripe:", error)
            reject(error)
          }
          return
        }
  
        // Remove any existing Stripe script that might have failed
        const existingScript = document.querySelector('script[src="https://js.stripe.com/v3/"]')
        if (existingScript) {
          console.log("Removing existing Stripe script that might have failed")
          existingScript.remove()
        }
  
        console.log("Loading Stripe dynamically in billing wizard")
        const script = document.createElement('script')
        script.src = 'https://js.stripe.com/v3/'
        script.async = true
        
        script.onload = () => {
          console.log("Stripe script loaded successfully in billing wizard")
          // Small delay to ensure Stripe is fully initialized
          setTimeout(() => {
            try {
              this.stripe = Stripe(this.stripePublicKeyValue)
              resolve()
            } catch (error) {
              console.error("Error initializing Stripe after loading:", error)
              if (attempts < maxAttempts) {
                console.log("Retrying after error...")
                setTimeout(tryLoadStripe, 1000)
              } else {
                reject(error)
              }
            }
          }, 100)
        }
        
        script.onerror = () => {
          console.error("Failed to load Stripe script")
          if (attempts < maxAttempts) {
            console.log("Retrying Stripe load after error...")
            setTimeout(tryLoadStripe, 1000)
          } else {
            reject(new Error(`Failed to load Stripe script after ${maxAttempts} attempts`))
          }
        }
        
        document.head.appendChild(script)
      }
      
      tryLoadStripe()
    })
  }

  initializeWizard() {
    this.updateProgressBar()
    
    // Add event listeners to step navigation buttons
    this.element.querySelectorAll('.next-step').forEach(button => {
      button.addEventListener('click', (e) => {
        const step = parseInt(e.currentTarget.getAttribute('data-step'))
        this.goToNextStep(step)
      })
    })

    this.element.querySelectorAll('.prev-step').forEach(button => {
      button.addEventListener('click', (e) => {
        const step = parseInt(e.currentTarget.getAttribute('data-step'))
        this.goToPrevStep(step)
      })
    })

    // Make step indicators clickable
    this.stepIndicatorTargets.forEach(indicator => {
      indicator.addEventListener('click', (e) => {
        const stepNum = parseInt(indicator.getAttribute('data-step'))
        if (stepNum < this.currentStepValue) {
          this.goToStep(stepNum)
        }
      })
    })

    // Handle final submission button
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.addEventListener('click', this.handleSubmit.bind(this))
    }
  }

  // This is the original initializeStripe method that's called internally
  setupStripeOnceReady() {
    console.log("Setting up Stripe after client secret is ready")
    
    // Directly start the initialization process
    if (this.clientSecret) {
      // Once we have the client secret, set up Stripe elements
      console.log("Client secret already fetched, setting up Stripe elements")
      this.setupStripeElements()
    } else {
      console.log("Client secret not available, attempting to fetch")
      this.fetchClientSecret()
        .then(() => {
          console.log("Client secret fetched, setting up Stripe elements")
          this.setupStripeElements()
        })
        .catch(error => {
          console.error("Error during Stripe initialization:", error)
          this.showPaymentError("Failed to initialize payment form. Please try again.")
        })
    }
  }

  async fetchClientSecret() {
    // Prevent duplicate requests
    if (this.requestInProgressValue) {
      console.log('Request for client secret already in progress, skipping duplicate request')
      return false
    }
    
    this.requestInProgressValue = true
    console.log('Setting request flag to prevent duplicates')
    
    this.clientSecret = null
    console.log('Fetching client secret from:', this.dashboardBillingContactPathValue)
    
    // Fix potential URL issues - ensure we're targeting the right endpoint
    let url = this.dashboardBillingContactPathValue
    
    // First debug the URL
    console.log('DEBUG - Raw URL value:', url)
    console.log('DEBUG - URL type:', typeof url)
    
    // If we don't have a URL, create a fallback
    if (!url) {
      console.warn('WARNING: No billing contact path provided, using fallback')
      const orgToken = this.element.dataset.organizationToken || ''
      url = `/dashboard/billing/contact/${orgToken}`
      console.log('DEBUG - Using fallback URL:', url)
      // Also set the value for future use
      this.dashboardBillingContactPathValue = url
    }
    
    // If URL ends with a number (ID), we need to adjust it for the API call
    if (/\/\d+$/.test(url)) {
      console.log('URL contains ID at the end, using base URL instead')
      url = url.replace(/\/\d+$/, '')
    }
    
    // Ensure URL has no query params
    if (url.includes('?')) {
      url = url.split('?')[0]
      console.log('DEBUG - Removed query params from URL')
    }
    
    console.log('Using URL for client secret request:', url)
    
    // Try direct call to get_payment_method_secret endpoint
    const params = {
      do_what: 'get_payment_method_secret',
    }
    
    // Log to console only
    console.log(`Fetching payment token from: ${url}`)
    
    try {
      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
      if (!csrfToken) {
        console.warn('CSRF token not found in page meta tags')
      }
      
      console.log('Sending request for client secret')
      console.log('DEBUG - Request params:', JSON.stringify(params))
      console.log('DEBUG - CSRF Token:', csrfToken ? 'Present' : 'Missing')
      
      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken || '',
          'Accept': 'application/json'
        },
        body: JSON.stringify(params)
      };
      
      console.log('DEBUG - Request options:', JSON.stringify(requestOptions))
      
      // Log API request to console
      console.log('Sending API request...')
      
      const response = await fetch(url, requestOptions)
      
      console.log('Response received, status:', response.status)
      console.log('DEBUG - Response headers:', JSON.stringify([...response.headers.entries()]))
      
      // Log response status to console
      console.log(`Received response with status: ${response.status}`)
      
      if (!response.ok) {
        console.error('Client secret request failed with status:', response.status)
        
        // Try to read the response body for error details
        const errorText = await response.text()
        console.error('Error response body:', errorText)
        
        // Log error to console only
        console.error(`Server error (${response.status}): ${errorText || 'No error details available'}`)
        
        throw new Error(`HTTP error! Status: ${response.status}. ${errorText || ''}`)
      }
      
      // Try to parse as JSON but be prepared for non-JSON responses
      let responseText = await response.text()
      console.log('Raw response text:', responseText ? responseText.substring(0, 100) + '...' : 'empty')
      
      // Log response info to console
      console.log(`Response received (${responseText.length} bytes)`)
      
      let data
      try {
        data = JSON.parse(responseText)
      } catch (e) {
        console.error('Error parsing response as JSON:', e)
        
        // Log JSON parse error to console
        console.error(`JSON parse error: ${e.message}. Raw response: ${responseText.substring(0, 50)}...`)
        
        throw new Error('Server response was not valid JSON: ' + e.message)
      }
      
      console.log('Parsed response data:', data)
      
      if (data && data.client_secret) {
        this.clientSecret = data.client_secret
        console.log('Client secret set successfully:', this.clientSecret.substring(0, 10) + '...')
        
        // Log success to console only
        console.log("Success: Payment token received")
        
        return true
      } else {
        console.error('Client secret missing in response:', data)
        
        // Log error to console
        console.error("Error: Payment token missing in response")
        
        throw new Error('Client secret missing in server response')
      }
    } catch (error) {
      console.error('Error fetching client secret:', error)
      
      // Use a more user-friendly error message
      this.showMessage('Error setting up payment form: ' + error.message, true)
      
      // Also display error in the payment element container
      this.showPaymentError(`Error: ${error.message}. Please reload the page or try again later.`)
      
      // Log failure to console
      console.error(`Payment setup failed: ${error.message}`)
      
      // Reset the request flag to allow future requests
      this.requestInProgressValue = false
      console.log("Request failed, resetting request flag")
      
      throw error
    }
  }

  setupStripeElements() {
    console.log('Setting up Stripe Elements - DEBUG MODE')
    
    // Show loading indicator in payment element
    const paymentElementContainer = document.getElementById('payment-element')
    if (paymentElementContainer) {
      const debugInfo = `
        <div class="debug-info mt-3 border-top pt-3">
          <h6>Debug Info:</h6>
          <ul>
            <li>Stripe Key: ${this.stripePublicKeyValue ? '✓ Present' : '❌ Missing'}</li>
            <li>Stripe Global: ${typeof Stripe !== 'undefined' ? '✓ Loaded' : '❌ Not loaded'}</li>
            <li>Time: ${new Date().toISOString()}</li>
          </ul>
        </div>
      `;
      
      paymentElementContainer.innerHTML = `
        <div class="text-center p-4">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
          <p class="mt-2">Loading payment form...</p>
          ${debugInfo}
        </div>
      `
    } else {
      console.error('Payment element container not found')
    }
    
    console.log('DEBUG - Stripe Public Key Value:', this.stripePublicKeyValue)
    console.log('DEBUG - Global Stripe Available:', typeof Stripe !== 'undefined')
    console.log('DEBUG - Client Secret:', this.clientSecret ? 'Present (first 10 chars: ' + this.clientSecret.substring(0, 10) + '...)' : 'Not present')
    console.log('DEBUG - Dashboard Billing Contact Path:', this.dashboardBillingContactPathValue)
    
    // Validate key value format
    if (this.stripePublicKeyValue) {
      if (!this.stripePublicKeyValue.startsWith('pk_')) {
        console.error('ERROR: Invalid Stripe key format. Should start with pk_')
        this.showPaymentError('Invalid Stripe API key format. Contact support.')
        return
      }
    } else {
      console.error('ERROR: Missing Stripe public key')
      this.showPaymentError('Missing Stripe API key configuration. Contact support.')
      return
    }
    
    // Check if payment element exists
    if (!document.getElementById('payment-element')) {
      console.error('ERROR: payment-element container not found in DOM')
      return
    }
    
    // Ensure Stripe is available or load it
    this.ensureStripeLibraryLoaded()
      .then(() => {
        console.log('DEBUG - Stripe library loaded successfully')
        // Try to set up Stripe immediately if client secret is already available
        if (this.clientSecret) {
          console.log('Client secret already available, initializing Stripe immediately')
          this.initializeStripeWithSecret()
          return
        }
      
        console.log('Client secret not available yet, will fetch it')
      
        // Fetch the client secret
        this.fetchClientSecret()
          .then(() => {
            console.log('DEBUG - fetchClientSecret completed')
            console.log('DEBUG - Client secret after fetch:', this.clientSecret ? 'Present' : 'Not present')
            
            if (this.clientSecret) {
              console.log('Successfully fetched client secret, initializing Stripe')
              this.initializeStripeWithSecret()
            } else {
              console.error('Client secret still not available after explicit fetch')
              // Update UI with this error
              if (paymentElementContainer) {
                paymentElementContainer.innerHTML += `
                  <div class="alert alert-warning mt-3">
                    <strong>Warning:</strong> Could not obtain client secret. Retrying...
                  </div>
                `
              }
              this.startClientSecretWaiter()
            }
          })
          .catch(error => {
            console.error('Error fetching client secret:', error)
            
            // Update UI with more details about the error
            if (paymentElementContainer) {
              paymentElementContainer.innerHTML += `
                <div class="alert alert-danger mt-3">
                  <strong>Error fetching client secret:</strong> ${error.message}
                </div>
              `
            }
            
            this.startClientSecretWaiter()
          })
      })
      .catch(error => {
        console.error('Failed to load Stripe library:', error)
        this.showPaymentError('Failed to load payment system: ' + error.message)
      })
  }

  // Method to ensure the Stripe library is loaded
  ensureStripeLibraryLoaded() {
    return new Promise((resolve, reject) => {
      if (typeof Stripe !== 'undefined') {
        try {
          console.log('Stripe already loaded in wizard')
          this.stripe = Stripe(this.stripePublicKeyValue)
          resolve()
        } catch (error) {
          console.error('Error initializing Stripe:', error)
          reject(error)
        }
        return
      }
      
      // Need to load Stripe dynamically
      console.log('Loading Stripe dynamically in wizard')
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/'
      script.async = true
      
      script.onload = () => {
        try {
          console.log('Stripe script loaded successfully in wizard')
          this.stripe = Stripe(this.stripePublicKeyValue)
          resolve()
        } catch (error) {
          console.error('Error initializing Stripe after loading:', error)
          reject(error)
        }
      }
      
      script.onerror = () => {
        const error = new Error('Failed to load Stripe script in wizard')
        console.error(error)
        reject(error)
      }
      
      document.head.appendChild(script)
    })
  }
  
  startClientSecretWaiter() {
    let waitAttempts = 0
    const maxWaitAttempts = 30 // 6 seconds max (30 * 200ms)
    
    const waitForClientSecret = () => {
      waitAttempts++
      console.log(`Waiting for client secret... (attempt ${waitAttempts}/${maxWaitAttempts})`)
      
      if (this.clientSecret) {
        console.log('Client secret now available, initializing Stripe')
        this.initializeStripeWithSecret()
      } else if (waitAttempts < maxWaitAttempts) {
        setTimeout(waitForClientSecret, 200) // Try again in 200ms
      } else {
        console.error('Timed out waiting for client secret after', waitAttempts, 'attempts')
        
        // Try one last explicit fetch
        console.log('Making final attempt to fetch client secret...')
        this.fetchClientSecret()
          .then(() => {
            if (this.clientSecret) {
              console.log('Last attempt successful, initializing Stripe')
              this.initializeStripeWithSecret()
            } else {
              this.showPaymentError('Unable to set up payment form. Please reload the page and try again.')
            }
          })
          .catch(error => {
            console.error('Final attempt to fetch client secret failed:', error)
            this.showPaymentError('Unable to set up payment form. Please reload the page and try again.')
          })
      }
    }
    
    // Start the waiting process
    waitForClientSecret()
  }
  
  initializeStripeWithSecret() {
    console.log('Initializing Stripe with client secret')
    
    try {
      // Ensure we have a valid Stripe instance
      if (!this.stripe) {
        console.log('Creating Stripe instance')
        try {
          this.stripe = Stripe(this.stripePublicKeyValue)
        } catch (error) {
          console.error('Error creating Stripe instance:', error)
          this.showPaymentError('Failed to initialize payment processor. Please reload the page.')
          return
        }
      }
      
      // Check for the client secret
      if (!this.clientSecret) {
        console.error('Client secret not available')
        this.showPaymentError('Payment setup error: Missing client secret')
        return
      }
      
      const appearance = { 
        theme: 'stripe',
        variables: {
          colorPrimary: '#7367f0',
          colorBackground: '#f8f9fa',
          colorText: '#212529',
          colorDanger: '#dc3545',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          spacingUnit: '4px',
          borderRadius: '12px'
        },
        rules: {
          '.Input': {
            border: '1px solid #e9ecef',
            boxShadow: 'none',
            fontSize: '14px',
            padding: '16px',
            borderRadius: '12px'
          },
          '.Input:focus': {
            border: '1px solid #7367f0',
            boxShadow: '0 0 0 4px rgba(115, 103, 240, 0.1)'
          },
          '.Label': {
            fontSize: '13px',
            fontWeight: '500',
            color: '#6c757d',
            marginBottom: '8px'
          },
          '.Tab': {
            borderRadius: '12px',
            padding: '12px 18px',
            marginRight: '8px'
          },
          '.Tab:hover': {
            backgroundColor: 'rgba(115, 103, 240, 0.05)'
          },
          '.Tab--selected': {
            backgroundColor: 'rgba(115, 103, 240, 0.1)',
            borderColor: '#7367f0',
            color: '#7367f0'
          },
          '.TabIcon': {
            marginRight: '8px'
          },
          '.Block': {
            borderRadius: '12px',
            padding: '16px'
          }
        }
      }
      
      // Find the payment element container
      const paymentElementContainer = document.getElementById('payment-element')
      if (!paymentElementContainer) {
        console.error('Payment element container not found')
        throw new Error('Payment element container not found in the DOM')
      }
      
      try {
        console.log('Creating Stripe Elements instance')
        this.elements = this.stripe.elements({
          clientSecret: this.clientSecret, 
          appearance
        })
        
        // Create payment element
        console.log('Creating payment element')
        this.paymentElement = this.elements.create('payment', {
          fields: {
            billingDetails: 'never' // Hide billing details in Stripe form
          },
          layout: {
            type: 'tabs',
            defaultCollapsed: false
          }
        })
        
        console.log('Found payment element container, mounting payment element')
        paymentElementContainer.innerHTML = '' // Clear loading indicator
        
        // Use a try catch here specifically for the mount operation
        try {
          this.paymentElement.mount('#payment-element')
          console.log('Payment element mounted successfully')
          
          // Show success message
          this.showMessage('Payment form loaded successfully', false)
        } catch (mountError) {
          console.error('Error mounting payment element:', mountError)
          throw mountError
        }
      } catch (elementsError) {
        console.error('Error creating Stripe Elements:', elementsError)
        this.showPaymentError(`Error initializing payment elements: ${elementsError.message}`)
        throw elementsError
      }
    } catch (error) {
      console.error('Error initializing Stripe Elements:', error)
      
      // Clear the loading indicator and show error
      const paymentElementContainer = document.getElementById('payment-element')
      if (paymentElementContainer) {
        paymentElementContainer.innerHTML = `
          <div class="alert alert-danger">
            <strong>Error loading payment form:</strong> ${error.message}
            <p class="mt-2">Please try again or reload the page.</p>
            <button class="btn btn-outline-primary btn-sm mt-2" onclick="window.location.reload()">
              Reload Page
            </button>
          </div>
        `
      }
      
      // Also show in payment message area
      this.showPaymentError(`Error loading payment form: ${error.message}`)
    }
  }

  async handleSubmit(e) {
    e.preventDefault()
    this.setLoading(true)

    try {
      // Check which step we're on
      const isConfirmationStep = document.querySelector('.wizard-step[data-step="4"].active') !== null
      const isPaymentStep = document.querySelector('.wizard-step[data-step="3"].active') !== null

      // If we're on the confirmation step, we've already processed the card
      if (isConfirmationStep) {
        await this.handleConfirmationStep()
        return
      }

      // For payment step, validate the card details
      if (isPaymentStep) {
        await this.handlePaymentStep()
      }
    } catch (error) {
      this.showMessage("An unexpected error occurred.", true)
      console.error('Error during payment processing:', error)
    } finally {
      this.setLoading(false)
    }
  }
  
  // Handler for confirmation button click (final step)
  async handleConfirmation(e) {
    e.preventDefault()
    
    // Set loading state
    this.setConfirmLoading(true)
    
    console.log("Confirm button clicked, completing setup...")
    
    // Get country ID from selector or hidden field
    const countryId = this.hasCountrySelectorTarget ? this.countrySelectorTarget.value : 
                      document.getElementById('country-id-hidden')?.value || '';
    
    // Get tax ID value based on country type (EU VAT or standard)
    let taxIdValue = '';
    let euVatValue = '';
    
    if (this.countryData && this.countryData.eu_vat === true) {
      // If country has EU VAT, use the EU VAT field
      euVatValue = document.getElementById('billing_paymethod_company_eu_vat_number')?.value || 
                  document.getElementById('stripe-tax-id')?.value || '';
    } else {
      // Otherwise use the standard tax ID field
      taxIdValue = document.getElementById('stripe-tax-id')?.value || '';
    }


    // Gather data from previous steps
    const formData = {
      organization: {
        billing_paymethod_company_name: document.getElementById('stripe-company-name').value,
        billing_paymethod_email: document.getElementById('stripe-billing-email').value,
        billing_paymethod_address_line1: document.getElementById('stripe-address-line1').value,
        billing_paymethod_address_line2: document.getElementById('stripe-address-line2')?.value || '',
        billing_paymethod_address_city: document.getElementById('stripe-city').value,
        billing_paymethod_address_state: document.getElementById('stripe-state')?.value || '',
        billing_paymethod_address_postal_code: document.getElementById('stripe-postal-code').value,
        billing_paymethod_address_country_id: countryId,
        billing_paymethod_company_vat_number: taxIdValue,
        billing_paymethod_company_eu_vat_number: euVatValue,
        plan_change: true,
        plan_name: this.selectedPlanName || window.selectedPlanName
      }
    }


    
    // Get payment details using the global accessor function
    console.log("Getting payment details from form state")
    
    const paymentDetails = window.getPaymentDetails ? window.getPaymentDetails() : 
                         {setupIntentId: null, paymentMethodId: null, clientSecret: null}
    
    console.log("Payment details from form state:", paymentDetails)
    
    // Also check the PAYMENT_FORM_STATE directly
    console.log("PAYMENT_FORM_STATE content:", window.PAYMENT_FORM_STATE)
    
    // Check if we have payment info
    if (paymentDetails.setupIntentId && paymentDetails.paymentMethodId) {
      // Add to formData
      formData.setup_intent = {
        id: paymentDetails.setupIntentId,
        payment_method: paymentDetails.paymentMethodId,
        client_secret: paymentDetails.clientSecret
      }
      
      // Log what we're sending
      console.log("Payment details being sent:")
      console.log("- Setup Intent ID:", paymentDetails.setupIntentId)
      console.log("- Payment Method ID:", paymentDetails.paymentMethodId)
      console.log("- Client Secret:", paymentDetails.clientSecret ? paymentDetails.clientSecret.substring(0, 10) + "..." : "missing")
    } else {
      console.error("ERROR: Missing payment information!")
      console.log("Payment form state:", window.PAYMENT_FORM_STATE)
      
      // Create a dummy setup intent for testing
      // NOTE: REMOVE THIS IN PRODUCTION - FOR TESTING ONLY
      console.log("WARNING: Using dummy payment data for testing")
      formData.test_mode = true
      
      // Show warning but proceed anyway for testing
      if (confirm("Payment information is missing. Do you want to proceed with test data? (This is for testing only)")) {
        // Continue with test data
      } else {
        // User chose to cancel
        this.setConfirmLoading(false)
        return
      }
    }
    
    console.log("Sending confirmation to server...")
    
    // Post to confirm_plan endpoint
    console.log("Full request payload:", JSON.stringify({
      do_what: 'complete_setup',
      ...formData
    }, null, 2))
    
    try {
      const response = await fetch(this.dashboardBillingContactPathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          do_what: 'complete_setup',
          organization: formData.organization,
          setup_intent: formData.setup_intent
        })
      })
      
      const data = await response.json()
      console.log("Server response:", data)
      
      if (data.success) {
        console.log("Plan confirmed successfully!")
        
        // Show success message and redirect
        const modalContent = document.querySelector('.modal-content')
        if (modalContent) {
          modalContent.innerHTML = `
            <div class="p-5 text-center">
              <div class="mb-4">
                <i class="fa-regular fa-check-circle text-success" style="font-size: 4rem;"></i>
              </div>
              <h2 class="mb-4">Success!</h2>
              <p class="lead mb-4">Your payment method has been set up and your plan has been updated.</p>
              <p>Redirecting you to your dashboard...</p>
              <div class="spinner-border text-primary mt-3" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          `
        }
        
        // Redirect after a delay
        setTimeout(() => {
          if (data.redirect_to) {
            window.location.href = data.redirect_to
          } else {
            window.location.reload()
          }
        }, 2000)
      } else {
        console.error("Error confirming plan:", data.message || "Unknown error")
        
        // Show error and reset button
        alert("Error: " + (data.message || "An error occurred while confirming your plan. Please try again."))
        this.setConfirmLoading(false)
      }
    } catch (error) {
      console.error("Error confirming plan:", error)
      alert("Error: " + error.message)
      this.setConfirmLoading(false)
    }
  }
  
  // Set loading state for confirmation button
  setConfirmLoading(isLoading) {
    if (!this.hasConfirmButtonTarget) return
    
    this.confirmButtonTarget.disabled = isLoading
    
    if (this.hasConfirmNormalStateTarget && this.hasConfirmLoadingStateTarget) {
      if (isLoading) {
        this.confirmNormalStateTarget.classList.add('d-none')
        this.confirmLoadingStateTarget.classList.remove('d-none')
      } else {
        this.confirmNormalStateTarget.classList.remove('d-none')
        this.confirmLoadingStateTarget.classList.add('d-none')
      }
    }
  }

  async handlePaymentStep() {
    if (!this.stripe || !this.elements) {
      throw new Error('Stripe or Elements not initialized')
    }

    // Gather form values
    const formData = this.getFormData()

    // Validate the payment method without submitting
    const { error, setupIntent } = await this.stripe.confirmSetup({
      elements: this.elements,
      redirect: 'if_required',
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: formData.organization.billing_paymethod_company_name,
            email: formData.organization.billing_paymethod_email,
            phone: formData.organization.billing_paymethod_mobile_phone,
            address: {
              line1: formData.organization.billing_paymethod_address_line1,
              line2: formData.organization.billing_paymethod_address_line2,
              city: formData.organization.billing_paymethod_address_city,
              state: formData.organization.billing_paymethod_address_state,
              postal_code: formData.organization.billing_paymethod_address_postal_code,
              country: this.defaultCountryValue.toUpperCase(),
            }
          },
          metadata: {
            company_name: formData.organization.billing_paymethod_company_name,
            tax_id: formData.organization.billing_paymethod_company_vat_number
          }
        },
      },
    })

    if (error) {
      this.showMessage(error.message, true)
      // Display error in step 3 error container
      const errorContainer = document.getElementById('step3-error-message')
      if (errorContainer) {
        errorContainer.innerHTML = `
          <div class="d-flex align-items-center">
            <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
            <div>${error.message}</div>
          </div>
        `
        errorContainer.classList.remove('d-none')
      }
      return
    }

    // Payment validation succeeded
    this.showMessage("Payment information validated successfully!")
    
    if (setupIntent) {
      // Store setup intent and payment details in hidden fields
      this.storePaymentDetails(setupIntent)
      
      console.log(`Stored payment method ID: ${setupIntent.payment_method}`)
      console.log(`Now attempting to post data to save_data endpoint`)
      
      // Create the post data
      const postData = {
        setup_intent: {
          id: setupIntent.id,
          client_secret: setupIntent.client_secret,
          payment_method: setupIntent.payment_method
        },
        form_data: formData
      }
      
      console.log('Posting payment data:', JSON.stringify(postData))
      
      // DIRECT POST TO CONTACT CONTROLLER SAVE_DATA ENDPOINT
      console.log('POSTING TO CONTACT CONTROLLER SAVE_DATA NOW WITH PAYMENT METHOD: ' + setupIntent.payment_method)
      
      const contactSaveDataUrl = this.dashboardBillingContactPathValue + '/save_data'
      console.log('POSTING TO URL: ' + contactSaveDataUrl)
      
      fetch(contactSaveDataUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          setup_intent: {
            id: setupIntent.id,
            client_secret: setupIntent.client_secret,
            payment_method: setupIntent.payment_method
          },
          form_data: formData
        })
      })
      .then(response => {
        console.log('SAVE_DATA RESPONSE STATUS: ' + response.status)
        return response.text()
      })
      .then(text => {
        console.log('SAVE_DATA RESPONSE TEXT: ' + text)
        // Proceed to confirmation step after getting the response
        this.goToNextStep(3)
      })
      .catch(error => {
        console.error('SAVE_DATA ERROR: ' + error)
        // Still proceed to confirmation step even on error
        this.goToNextStep(3)
      })
    }
  }

  async handleConfirmationStep() {
    // Get stored payment data
    const setupIntentId = document.getElementById('setup-intent-id')?.value
    const setupIntentClientSecret = document.getElementById('setup-intent-client-secret')?.value
    const paymentMethodId = document.getElementById('payment-method-id')?.value


    //Hide the wizard header
    $("#wizard-progress").hide();



    if (!setupIntentId || !paymentMethodId) {
      this.showMessage("Error: Payment information is missing. Please go back to the payment step.", true)
      return
    }
    
    // Collect form data
    const formData = this.getFormData()
    
    // Add Stripe details
    formData.setup_intent = {
      id: setupIntentId,
      client_secret: setupIntentClientSecret,
      payment_method: paymentMethodId
    }
    formData.do_what = 'complete_setup'
    
    // Submit to server
    try {
      const response = await fetch(this.dashboardBillingContactPathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify(formData)
      })
      
      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Server error')
      }
      
      const data = await response.json()
      this.showMessage(data.message || "Setup complete! Redirecting...")
      
      // Redirect after short delay
      setTimeout(() => {
        if (data.redirect_to) {
          window.location.href = data.redirect_to
        } else {
          window.location.reload()
        }
      }, 1500)
    } catch (error) {
      console.error("Error completing setup:", error)
      this.showMessage("Error completing setup: " + error.message, true)
      this.setLoading(false)
    }
  }

  // Helper methods
  getFormData() {
    const countryId = this.hasCountrySelectorTarget ? this.countrySelectorTarget.value : 
                      document.getElementById('country-id-hidden')?.value || '';
    
    // Determine the correct tax ID field based on country data
    let taxIdValue = ''
    let euVatValue = ''
    
    if (this.countryData && this.countryData.eu_vat === true) {
      // If country has EU VAT, use the EU VAT field
      euVatValue = document.getElementById('billing_paymethod_company_eu_vat_number')?.value || 
                  document.getElementById('stripe-tax-id')?.value || ''
    } else {
      // Otherwise use the standard tax ID field
      taxIdValue = document.getElementById('stripe-tax-id')?.value || ''
    }


    return {
      organization: {
        billing_paymethod_address_country_id: countryId,
        billing_paymethod_company_name: document.getElementById('stripe-company-name').value,
        billing_paymethod_email: document.getElementById('stripe-billing-email').value,
        billing_paymethod_company_vat_number: taxIdValue,
        billing_paymethod_company_eu_vat_number: euVatValue,
        website_url: document.getElementById('stripe-website')?.value || '',
        billing_paymethod_address_line1: document.getElementById('stripe-address-line1').value,
        billing_paymethod_address_line2: document.getElementById('stripe-address-line2')?.value || '',
        billing_paymethod_address_city: document.getElementById('stripe-city').value,
        billing_paymethod_address_state: document.getElementById('stripe-state')?.value || '',
        billing_paymethod_address_postal_code: document.getElementById('stripe-postal-code').value,
        // Plan change details
        plan_change: true,
        plan_name: this.selectedPlanName || window.selectedPlanName
      }
    }
  }

  storePaymentDetails(setupIntent) {
    // Store setup intent ID
    let setupIntentIdField = document.getElementById('setup-intent-id')
    if (!setupIntentIdField) {
      setupIntentIdField = document.createElement('input')
      setupIntentIdField.type = 'hidden'
      setupIntentIdField.id = 'setup-intent-id'
      document.body.appendChild(setupIntentIdField)
    }
    setupIntentIdField.value = setupIntent.id
    
    // Store client secret
    let setupIntentSecretField = document.getElementById('setup-intent-client-secret')
    if (!setupIntentSecretField) {
      setupIntentSecretField = document.createElement('input')
      setupIntentSecretField.type = 'hidden'
      setupIntentSecretField.id = 'setup-intent-client-secret'
      document.body.appendChild(setupIntentSecretField)
    }
    setupIntentSecretField.value = setupIntent.client_secret
    
    // Store payment method ID
    if (setupIntent.payment_method) {
      let paymentMethodField = document.getElementById('payment-method-id')
      if (!paymentMethodField) {
        paymentMethodField = document.createElement('input')
        paymentMethodField.type = 'hidden'
        paymentMethodField.id = 'payment-method-id'
        document.body.appendChild(paymentMethodField)
      }
      paymentMethodField.value = setupIntent.payment_method
    }
  }

  showPaymentError(message) {
    // Show error in payment message container first
    const paymentMessage = document.getElementById('payment-message')
    if (paymentMessage) {
      paymentMessage.innerHTML = `
        <div class="d-flex align-items-center">
          <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
          <div>${message}</div>
        </div>
      `
      paymentMessage.classList.remove('d-none')
      paymentMessage.classList.add('alert-danger')
      paymentMessage.classList.remove('alert-success')
    }
    
    // Also show more detailed error in the payment element area
    const paymentElementContainer = document.getElementById('payment-element')
    if (paymentElementContainer) {
      paymentElementContainer.innerHTML = `
        <div class="alert alert-danger">
          <strong>Error:</strong> ${message}
          <p class="mt-2">Please try closing this dialog and opening it again, or reload the page.</p>
        </div>
        <button class="btn btn-outline-primary mt-3" onclick="window.location.reload()">
          Reload page
        </button>
      `
    }
    
    // Also log to console
    console.error('Payment error:', message)
  }

  showMessage(messageText, isError = false) {
    console.log("Message:", messageText)
    const paymentMessage = document.getElementById('payment-message')
    if (paymentMessage) {
      paymentMessage.innerHTML = `
        <div class="d-flex align-items-center">
          <i class="${isError ? 'fa-regular fa-exclamation-circle text-danger' : 'fa-regular fa-check-circle text-success'} me-2"></i>
          <div>${messageText}</div>
        </div>
      `
      paymentMessage.classList.remove('d-none')
      
      if (!isError) {
        setTimeout(() => {
          paymentMessage.classList.add('d-none')
          paymentMessage.innerHTML = ""
        }, 4000)
      }
    } else {
      // Fallback to alert
      alert(messageText)
    }
  }

  setLoading(isLoading) {
    if (!this.hasSubmitButtonTarget) return
    
    this.submitButtonTarget.disabled = isLoading
    
    if (this.hasNormalStateTarget && this.hasLoadingStateTarget) {
      if (isLoading) {
        this.normalStateTarget.classList.add('d-none')
        this.loadingStateTarget.classList.remove('d-none')
      } else {
        this.normalStateTarget.classList.remove('d-none')
        this.loadingStateTarget.classList.add('d-none')
      }
    }
  }

  updateProgressBar() {
    const progressPercentage = ((this.currentStepValue - 1) / (this.totalStepsValue - 1)) * 100
    if (this.hasProgressBarTarget) {
      this.progressBarTarget.style.width = `${progressPercentage}%`
    }
    
    // Update step indicators
    this.stepIndicatorTargets.forEach(step => {
      const stepNum = parseInt(step.getAttribute('data-step'))
      step.classList.remove('active', 'completed')
      
      if (stepNum < this.currentStepValue) {
        step.classList.add('completed')
      } else if (stepNum === this.currentStepValue) {
        step.classList.add('active')
      }
    })
  }

  goToStep(step) {
    // Hide all steps
    this.stepTargets.forEach(stepEl => {
      stepEl.classList.remove('active')
    })
    
    // Show target step
    const targetStep = this.stepTargets.find(el => parseInt(el.getAttribute('data-step')) === step)
    if (targetStep) {
      targetStep.classList.add('active')
    }
    
    this.currentStepValue = step
    this.updateProgressBar()
  }

  goToNextStep(currentStepNum) {
    if (this.validateStep(currentStepNum) && currentStepNum < this.totalStepsValue) {
      this.goToStep(currentStepNum + 1)
    }
  }

  goToPrevStep(currentStepNum) {
    if (currentStepNum > 1) {
      this.goToStep(currentStepNum - 1)
    }
  }

  validateStep(step) {
    let isValid = true
    const errorContainer = document.getElementById(`step${step}-error-message`)
    
    // Clear previous error
    if (errorContainer) {
      errorContainer.classList.add('d-none')
      errorContainer.innerHTML = ''
    }
    
    // Validate required fields based on step
    if (step === 1) {
      // Check country selector
      const countryId = this.hasCountrySelectorTarget ? this.countrySelectorTarget.value : 
                        document.getElementById('country-id-hidden')?.value;
                        
      // Get tax ID field - handle both possible IDs
      const taxIdField = document.getElementById('billing_paymethod_company_eu_vat_number') || 
                          document.getElementById('stripe-tax-id');
      
      // Check all required fields
      if (!countryId || 
          !document.getElementById('stripe-company-name').value || 
          !document.getElementById('stripe-billing-email').value ||
          !taxIdField?.value ||
          !document.getElementById('stripe-website').value) {
        this.showStepError(step, "Please fill out all required fields")
        isValid = false
      }
      
      // Validate email format
      const emailInput = document.getElementById('stripe-billing-email');
      if (emailInput && emailInput.value && !this.isValidEmail(emailInput.value)) {
        this.showStepError(step, "Please enter a valid email address")
        isValid = false
      }
      
      // Validate website URL format
      const websiteInput = document.getElementById('stripe-website');
      if (websiteInput && websiteInput.value && !this.isValidUrl(websiteInput.value)) {
        this.showStepError(step, "Please enter a valid website URL")
        isValid = false
      }
      
      // Check if the country is under sanctions
      if (this.countryData && this.countryData.sanctions === true) {
        this.showStepError(step, "This country is under sanctions. We cannot proceed with your registration.")
        isValid = false
      }
    } else if (step === 2) {
      if (!document.getElementById('stripe-address-line1').value || 
          !document.getElementById('stripe-city').value || 
          !document.getElementById('stripe-postal-code').value) {
        this.showStepError(step, "Please fill out all required fields")
        isValid = false
      }
    }
    
    return isValid
  }
  
  isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  isValidUrl(url) {
    // Simplified pattern that accepts domain.com or www.domain.com without requiring http://
    // This matches:
    // - domain.com
    // - www.domain.com
    // - sub.domain.com
    // - http://domain.com
    // - https://domain.com
    // - domain with paths like domain.com/page
    const pattern = /^(https?:\/\/)?((www\.)?[a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}(\/.*)?$/i;
    
    // If the URL doesn't start with a protocol, we can't use the URL constructor directly
    // Instead, rely on our regex for basic validation
    return pattern.test(url);
  }

  showStepError(step, message) {
    const errorContainer = document.getElementById(`step${step}-error-message`)
    if (errorContainer) {
      errorContainer.innerHTML = `
        <div class="d-flex align-items-center">
          <i class="fa-regular fa-exclamation-circle text-danger me-2"></i>
          <div>${message}</div>
        </div>
      `
      errorContainer.classList.remove('d-none')
    } else {
      alert(message)
    }
  }
}