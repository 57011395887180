import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        tableConfig: {
            type: Object,
            default: {
                '#datatable': {
                    autoWidth: true,
                    columns: [
                        null,  // Name
                        null,  // Email
                        null,  // Role
                        null,  // Auth Method
                        null,  // Signed in
                        { orderable: false }  // Actions
                    ],
                    order: [[0, 'asc']],
                    pageLength: 10,
                    language: {
                        url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
                        emptyTable: "No team members found"
                    }
                },
                '#datasharing': {
                    autoWidth: true,
                    columns: [
                        null,  // Account Number
                        null,  // Name
                        null,  // Organization Jurisdiction
                        null,  // Storage Jurisdiction
                        null,  // Direction
                        { orderable: false }  // Actions
                    ],
                    order: [[0, 'asc']],
                    pageLength: 10,
                    language: {
                        url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
                        emptyTable: "No data sharing agreements found"
                    }
                }
            }
        }
    }

    // Track DataTable instances for proper cleanup
    dataTableInstances = []

    initialize() {
        // Add listeners for all relevant Turbo events
        document.addEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
        document.addEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    }

    connect() {
        requestAnimationFrame(() => {
            this.initializeTables()
            this.initializePopovers()
        })
    }

    cleanupBeforeNavigation = () => {
        this.cleanupDataTables()
    }

    disconnect() {
        // Remove event listeners
        document.removeEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
        document.removeEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
        
        // Clean up all DataTable instances
        this.cleanupDataTables()
    }

    cleanupDataTables() {
        // Clean up any instances we've created
        this.dataTableInstances.forEach(dt => {
            try {
                if (dt && typeof dt.destroy === 'function') {
                    dt.destroy(true) // true = remove DOM changes
                }
            } catch (err) {
                console.warn('Error cleaning up DataTable:', err)
            }
        })
        
        // Reset the instances array
        this.dataTableInstances = []
        
        // Also attempt to clean any tables in the DOM
        try {
            const configs = this.tableConfigValue
            if (configs) {
                Object.keys(configs).forEach(tableId => {
                    const table = document.querySelector(`table${tableId}`)
                    if (table && $.fn.DataTable.isDataTable(table)) {
                        try {
                            $(table).DataTable().destroy(true)
                        } catch (e) {
                            // Ignore errors
                        }
                    }
                })
            }
        } catch (err) {
            // Ignore errors
        }
    }

    initializeTables() {
        try {
            // Clean up first
            this.cleanupDataTables()
            
            const configs = this.tableConfigValue
            if (!configs) return

            Object.entries(configs).forEach(([tableId, config]) => {
                const table = document.querySelector(`table${tableId}`)
                if (!table) return
                
                // Save original HTML if needed
                if (!table.dataset.originalHtml) {
                    table.dataset.originalHtml = table.innerHTML
                }
                
                // Restore original HTML if it exists
                if (table.dataset.originalHtml) {
                    table.innerHTML = table.dataset.originalHtml
                }
                
                // Create config with options that help with Turbo
                const finalConfig = {
                    ...config,
                    retrieve: true,
                    destroy: true,
                    responsive: true,
                    dom: 'lBfrtip',
                }
                
                // Use jQuery-style initialization
                try {
                    const dtInstance = $(table).DataTable(finalConfig)
                    if (dtInstance) {
                        this.dataTableInstances.push(dtInstance)
                    }
                } catch (error) {
                    console.error(`Error initializing table ${tableId}:`, error)
                }
            })
        } catch (error) {
            console.error('Error initializing tables:', error)
        }
    }

    initializePopovers() {
        try {
            const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            popoverTriggerList.forEach(popoverTriggerEl => {
                new bootstrap.Popover(popoverTriggerEl)
            })
        } catch (error) {
            console.warn('Error initializing popovers:', error)
        }
    }
}