import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["paymentElement", "messageElement", "button", "normalState", "loadingState"]
  static values = {
    organizationToken: String,
    stripePublicKey: String,
    contactPath: String
  }

  connect() {
    console.log("Update Card controller connected")
    console.log("Stripe public key:", this.stripePublicKeyValue)
    console.log("Organization token:", this.organizationTokenValue)

    // Ensure Stripe is loaded
    this.ensureStripeLoaded()
      .then(() => {
        console.log("Stripe is now available")
        this.stripe = Stripe(this.stripePublicKeyValue)
      })
      .catch(error => {
        console.error("Failed to load Stripe:", error)
      })

    this.initializeModal()
  }
  
  // Helper method to ensure Stripe is loaded
  ensureStripeLoaded() {
    return new Promise((resolve, reject) => {
      if (typeof Stripe !== 'undefined') {
        console.log("Stripe already loaded")
        resolve()
        return
      }

      console.log("Loading Stripe dynamically")
      const script = document.createElement('script')
      script.src = 'https://js.stripe.com/v3/'
      script.onload = () => {
        console.log("Stripe script loaded successfully")
        resolve()
      }
      script.onerror = () => {
        const error = new Error("Failed to load Stripe script")
        console.error(error)
        reject(error)
      }
      document.head.appendChild(script)
    })
  }

  initializeModal() {
    console.log("Setting up modal")
    
    // Get button directly from the DOM
    const submitButton = document.getElementById('update-card-button')
    if (submitButton) {
      console.log("Found submit button, attaching event listener")
      submitButton.addEventListener('click', this.handleCardUpdate.bind(this))
    } else {
      console.warn("Submit button not found by ID")
    }
  }
  
  handleModalShown(event) {
    console.log("Update Card modal is showing")
    
    this.clientSecret = null
    this.fetchClientSecret()
      .then(() => {
        console.log("Initializing Stripe elements after fetching client secret")
        this.initializeStripeElements()
      })
      .catch(error => {
        console.error("Error during initialization:", error)
      })
  }

  async fetchClientSecret() {
    console.log("Fetching client secret with token:", this.organizationTokenValue)
    
    try {
      const token = this.organizationTokenValue
      if (!token) {
        console.error("Organization token is missing")
        this.showMessage("Error: Organization token is missing", true)
        return
      }

      // Use the contact path from data attribute instead of hardcoded URL
      const response = await fetch(this.contactPathValue, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({
          do_what: 'update_card'
        })
      })

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }

      const data = await response.json()
      console.log("Client secret received for card update:", data.client_secret ? "Yes (redacted)" : "No")
      
      if (!data.client_secret) {
        throw new Error("No client secret returned from server")
      }
      
      this.clientSecret = data.client_secret
      
      // If we've already shown the modal, initialize elements
      if (this.element.classList.contains('show')) {
        this.initializeStripeElements()
      }
    } catch (error) {
      console.error("Error fetching card update client secret:", error)
      this.showMessage("Error: " + error.message, true)
    }
  }

  initializeStripeElements() {
    console.log("initializeStripeElements called")
    
    // First check if the payment element target exists
    if (!this.hasPaymentElementTarget) {
      console.error("Payment element target not available - trying to find it manually")
      
      // Try to find the element manually
      const manualPaymentElement = document.getElementById('card-payment-element')
      if (manualPaymentElement) {
        console.log("Found payment element manually:", manualPaymentElement)
        this.paymentElementTarget = manualPaymentElement
      } else {
        console.error("Payment element not found even with manual lookup")
        return
      }
    }
    
    // Show loading indicator
    this.paymentElementTarget.innerHTML = `
      <div class="text-center p-4">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <p class="mt-2">Loading payment form...</p>
      </div>
    `
    
    // Make sure Stripe is available
    this.ensureStripeLoaded()
      .then(() => {
        if (!this.stripe) {
          console.log("Creating Stripe instance")
          this.stripe = Stripe(this.stripePublicKeyValue)
        }
        
        // Wait for client secret
        this.waitForClientSecret()
      })
      .catch(error => {
        console.error("Failed to load Stripe:", error)
        this.showMessage("Error: Payment processing not available", true)
        
        this.paymentElementTarget.innerHTML = `
          <div class="alert alert-danger">
            <strong>Error:</strong> Failed to load payment processing.
            <p>Please refresh the page and try again.</p>
          </div>
        `
      })
  }
  
  waitForClientSecret(attempt = 0) {
    const maxAttempts = 50 // 5 seconds max (50 * 100ms)
    
    console.log(`Waiting for client secret... (attempt ${attempt}/${maxAttempts})`)
    
    if (this.clientSecret) {
      console.log("Client secret available, creating Stripe elements")
      this.createAndMountElement()
    } else if (attempt < maxAttempts) {
      setTimeout(() => this.waitForClientSecret(attempt + 1), 100)
    } else {
      console.error("Timed out waiting for client secret")
      this.showMessage("Error: Payment form initialization timed out", true)
      
      this.paymentElementTarget.innerHTML = `
        <div class="alert alert-danger">
          <strong>Error:</strong> Payment form initialization timed out.
          <p class="mt-2">Please try again or reload the page.</p>
        </div>
      `
    }
  }
  
  createAndMountElement() {
    try {
      // Clear any existing content
      this.paymentElementTarget.innerHTML = ''
      
      // Check if dark mode is active
      const isDarkMode = document.documentElement.getAttribute('data-bs-theme') === 'dark';
      
      // Set up appearance
      const appearance = {
        theme: 'stripe',
        variables: {
          colorPrimary: '#7367f0',
          colorBackground: isDarkMode ? '#2a2f34' : '#f8f9fa',
          colorText: isDarkMode ? '#e9ecef' : '#212529',
          colorDanger: '#dc3545',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
          borderRadius: '8px',
          // Dark mode specific customizations
          colorComponentBackground: isDarkMode ? '#343a40' : '',
          colorComponentBorder: isDarkMode ? '#495057' : '',
          colorComponentText: isDarkMode ? '#e9ecef' : '',
          colorPlaceholderText: isDarkMode ? '#adb5bd' : ''
        }
      }
      
      // Create Elements instance
      console.log("Creating elements with client secret")
      this.elements = this.stripe.elements({
        clientSecret: this.clientSecret, 
        locale: document.documentElement.lang || 'auto', // Use the page language or auto-detect
        appearance
      })
      
      // Create and mount Payment Element
      console.log("Creating payment element")
      this.paymentElement = this.elements.create('payment', {
        // Collect billing details in the form instead of opting out
      })
      
      console.log("Mounting payment element to:", this.paymentElementTarget)
      this.paymentElement.mount('#card-payment-element')
      console.log("Card payment element mounted successfully")
    } catch (error) {
      console.error("Error initializing card elements:", error)
      this.showMessage("Error: " + error.message, true)
      
      this.paymentElementTarget.innerHTML = `
        <div class="alert alert-danger">
          <strong>Error loading payment form:</strong> ${error.message}
          <p class="mt-2">Please try again or reload the page.</p>
        </div>
      `
    }
  }

  async handleCardUpdate(event) {
    event.preventDefault()
    
    if (!this.stripe || !this.elements) {
      this.showMessage("Error: Payment processing not available", true)
      return
    }
    
    // Show loading state
    this.setLoading(true)
    
    try {
      // Confirm the setup
      const { error, setupIntent } = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required',
        confirmParams: {}
      })
      
      if (error) {
        console.error("Error confirming setup:", error)
        this.showMessage(error.message, true)
        this.setLoading(false)
      } else {
        // Submit the setup to the server
        const response = await fetch(this.contactPathValue, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({
            do_what: 'complete_card_update',
            setup_intent: {
              id: setupIntent.id,
              payment_method: setupIntent.payment_method,
              client_secret: this.clientSecret
            }
          })
        })
        
        const data = await response.json()
        if (data.success) {
          this.showMessage("Payment card updated successfully", false)
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        } else {
          this.showMessage(data.error || "Error updating card", true)
          this.setLoading(false)
        }
      }
    } catch (error) {
      console.error("Unexpected error during card update:", error)
      this.showMessage("An unexpected error occurred", true)
      this.setLoading(false)
    }
  }

  showMessage(message, isError) {
    console.log(isError ? "Card error:" : "Card message:", message)
    
    // Try to find message element if not already targeted
    if (!this.hasMessageElementTarget) {
      console.log("Message element target not available - trying to find it manually")
      const manualMessageElement = document.getElementById('card-payment-message')
      if (manualMessageElement) {
        console.log("Found message element manually:", manualMessageElement)
        this.messageElementTarget = manualMessageElement
      }
    }
    
    if (this.messageElementTarget) {
      const iconClass = isError 
        ? 'fa-regular fa-exclamation-circle text-danger' 
        : 'fa-regular fa-check-circle text-success'
      
      this.messageElementTarget.innerHTML = `
        <div class="d-flex align-items-center">
          <i class="${iconClass} me-2"></i>
          <div>${message}</div>
        </div>
      `
      
      this.messageElementTarget.classList.remove('d-none')
      this.messageElementTarget.classList.add(isError ? 'alert-danger' : 'alert-success')
      this.messageElementTarget.classList.remove(isError ? 'alert-success' : 'alert-danger')
      
      if (!isError) {
        setTimeout(() => {
          this.messageElementTarget.classList.add('d-none')
        }, 4000)
      }
    } else {
      // If we still can't find the message element, create a temporary one
      const tempMessage = document.createElement('div')
      tempMessage.className = `alert mt-3 rounded-pill ${isError ? 'alert-danger' : 'alert-success'}`
      tempMessage.innerHTML = `
        <div class="d-flex align-items-center">
          <i class="${isError ? 'fa-regular fa-exclamation-circle text-danger' : 'fa-regular fa-check-circle text-success'} me-2"></i>
          <div>${message}</div>
        </div>
      `
      
      // Find a good place to insert it
      const paymentElement = document.getElementById('card-payment-element')
      if (paymentElement) {
        paymentElement.after(tempMessage)
        
        if (!isError) {
          setTimeout(() => {
            tempMessage.remove()
          }, 4000)
        }
      } else {
        // Last resort
        alert(message)
      }
    }
  }

  setLoading(isLoading) {
    // Try to find button element if not already targeted
    if (!this.hasButtonTarget) {
      const manualButtonElement = document.querySelector('button[data-dashboard--update-card-target="button"]')
      if (manualButtonElement) {
        console.log("Found button element manually:", manualButtonElement)
        this.buttonTarget = manualButtonElement
      }
    }
    
    if (!this.buttonTarget) {
      console.error("Button target not found, can't set loading state")
      return
    }
    
    this.buttonTarget.disabled = isLoading
    
    // Try to find state elements if not already targeted
    if (!this.hasNormalStateTarget) {
      const manualNormalState = this.buttonTarget.querySelector('.normal-state')
      if (manualNormalState) {
        this.normalStateTarget = manualNormalState
      }
    }
    
    if (!this.hasLoadingStateTarget) {
      const manualLoadingState = this.buttonTarget.querySelector('.loading-state')
      if (manualLoadingState) {
        this.loadingStateTarget = manualLoadingState
      }
    }
    
    // Update loading state
    if (this.normalStateTarget && this.loadingStateTarget) {
      if (isLoading) {
        this.normalStateTarget.classList.add('d-none')
        this.loadingStateTarget.classList.remove('d-none')
      } else {
        this.normalStateTarget.classList.remove('d-none')
        this.loadingStateTarget.classList.add('d-none')
      }
    } else {
      // Fallback if we can't find the state elements
      if (isLoading) {
        this.buttonTarget.innerHTML = '<span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Processing...'
      } else {
        this.buttonTarget.innerHTML = '<i class="fa-regular fa-check me-1"></i>Update Card'
      }
    }
  }
}