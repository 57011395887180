import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

export default class extends Controller {
    static values = {
        prefetched: Boolean,
        href: String
    }
    
    connect() {
        // Add mouseenter listener for prefetch if data-turbo-prefetch is true
        if (this.element.dataset.turboPrefetch === "true") {
            this.element.addEventListener("mouseenter", this.prefetch.bind(this));
        }
    }
    
    prefetch() {
        // Only prefetch once
        if (this.prefetchedValue) return;
        
        const href = this.hrefValue || this.element.dataset.href;
        if (!href || href.includes('#') || href.includes('javascript:')) return;
        
        console.log('🔍 Clickable controller prefetching:', href);
        
        // Mark as prefetched to avoid duplicate prefetches
        this.prefetchedValue = true;
        
        try {
            // Simple prefetch using fetch API (compatible with all Turbo versions)
            fetch(href, {
                method: 'GET',
                credentials: 'same-origin',
                headers: {
                    'Accept': 'text/html, application/xhtml+xml',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-Turbo-Prefetch': 'true'
                }
            })
            .then(response => {
                if (response.ok) {
                    console.log('✅ Clickable controller successfully prefetched:', href);
                }
            })
            .catch(error => {
                console.error('❌ Clickable controller prefetch failed:', error);
                // Reset prefetched status so we might try again later
                this.prefetchedValue = false;
            });
        } catch (error) {
            console.error('❌ Error in prefetch:', error);
            this.prefetchedValue = false;
        }
    }
    
    click() {
        const href = this.hrefValue || this.element.dataset.href;
        if (href) {
            Turbo.visit(href);
        }
    }
}