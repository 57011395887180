import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["refreshIndicator"]

  connect() {
    console.log("Boards controller connected")
    this.loadDummyData()
    this.startRefreshTimer()
    this.setupApplicantListeners()
    this.setupRefreshIndicator()
  }
  
  setupRefreshIndicator() {
    this.refreshIndicator = document.querySelector('.auto-refresh-indicator')
    
    // Show the indicator as active
    if (this.refreshIndicator) {
      this.refreshIndicator.setAttribute('data-auto-refresh-active', 'true')
    }
  }

  disconnect() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }

  setupApplicantListeners() {
    // Use direct click handler on applicant cards
    document.querySelectorAll('.applicant-card').forEach(card => {
      card.addEventListener('click', () => {
        const applicantId = card.dataset.id || 'unknown'
        this.showFeaturedApplicant(applicantId)
        
        // Add selected class to the clicked card and remove from others
        document.querySelectorAll('.applicant-card').forEach(c => {
          c.classList.remove('selected')
        })
        card.classList.add('selected')
      })
    })
    
    // Set up a mutation observer to handle newly added cards
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          const newCards = Array.from(mutation.addedNodes)
            .filter(node => node.nodeType === 1 && node.classList.contains('applicant-card'))
          
          newCards.forEach(card => {
            card.addEventListener('click', () => {
              const applicantId = card.dataset.id || 'unknown'
              this.showFeaturedApplicant(applicantId)
              
              // Add selected class to the clicked card and remove from others
              document.querySelectorAll('.applicant-card').forEach(c => {
                c.classList.remove('selected')
              })
              card.classList.add('selected')
            })
          })
        }
      })
    })
    
    // Start observing the applicants container
    const applicantsData = document.getElementById('applicants-data')
    if (applicantsData) {
      observer.observe(applicantsData, { childList: true, subtree: true })
    }
  }

  startRefreshTimer() {
    // Refresh data every 10 seconds
    this.refreshTimer = setInterval(() => {
      this.loadDummyData()
      console.log('Dashboard refreshed at', new Date().toLocaleTimeString())
    }, 10000)
  }

  loadDummyData() {
    this.fetchApplicants()
    this.updateMetrics()
    // Initial featured applicant will be loaded when clicking on a card
    
    // Flash the refresh indicator
    if (this.refreshIndicator) {
      this.refreshIndicator.classList.add('refreshing')
      setTimeout(() => {
        this.refreshIndicator.classList.remove('refreshing')
      }, 1000)
    }
  }

  fetchApplicants() {
    fetch('/boards/refresh_applicants', {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .catch(error => {
      console.error('Error fetching applicants:', error)
    })
  }

  updateMetrics() {
    fetch('/boards/update_metrics', {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .catch(error => {
      console.error('Error updating metrics:', error)
    })
  }

  showFeaturedApplicant(applicantId) {
    fetch(`/boards/show_featured/${applicantId}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-Requested-With': 'XMLHttpRequest' 
      }
    })
    .catch(error => {
      console.error('Error showing featured applicant:', error)
    })
  }
}