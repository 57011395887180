import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

export default class extends Controller {
  static targets = ["phone", "countrySelect"]
  
  connect() {
    // Only proceed if we're on the contact page (check for required elements)
    if (!this.hasPhoneTarget || !this.element.querySelector('#organization_billing_paymethod_mobile_phone')) {
      return;
    }
    
    try {
      const controllerParams = JSON.parse(this.data.get("params"));
      console.log("Contact controller connected with params:", controllerParams);
      
      this.initPhoneInput(controllerParams);
      this.initCountrySelect(controllerParams);
    } catch (error) {
      console.error("Error initializing contact controller:", error);
    }
  }
  
  disconnect() {
    // Clean up any listeners or state when controller disconnects
    if (this.iti) {
      this.iti.destroy();
    }
    
    // Remove country change listener if it exists
    if (this.hasCountrySelectTarget) {
      $(this.countrySelectTarget).off('change');
    }
  }
  
  initPhoneInput(params) {
    // Only initialize if the phone input exists
    const phoneInput = this.element.querySelector("#organization_billing_paymethod_mobile_phone");
    if (!phoneInput) return;
    
    this.iti = intlTelInput(phoneInput, {
      initialCountry: params["defaultCountry"] || "us",
      strictMode: true,
      nationalMode: true,
      hiddenInput: () => ({ phone: "full_phone", country: "country_code" }),
      loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.0/build/js/utils.js",
    });
  }
  
  initCountrySelect(params) {
    // Only initialize if the country select exists
    const countrySelect = this.element.querySelector('#organization_billing_paymethod_address_country_id');
    if (!countrySelect) return;
    
    // Set initial value
    if (params['defaultCountryId']) {
      $(countrySelect).val(params['defaultCountryId']);
    }
    
    // Attach change handler
    $(countrySelect).on('change', (event) => {
      this.handleCountryChange(event, params);
    });
    
    // Trigger initial change
    $(countrySelect).trigger('change');
  }
  
  handleCountryChange(event, params) {
    const selectedCountryId = $(event.target).val();
    
    if (!selectedCountryId || !params['dashboardBillingContactIndexPath']) return;
    
    // Make AJAX request to fetch country data
    $.ajax({
      url: params['dashboardBillingContactIndexPath'] + '&country_id=' + selectedCountryId,
      method: 'GET',
      success: (response) => {
        // Update registration/tax number spans
        $('#regnumber').text(response.company_id || '');
        $('#taxnumber').text(response.tax_id_company || '');
        $('#eu_taxnumber').text(response.tax_id_company || '');
        
        // Handle sanctions alert
        if (response.sanctions) {
          $(".sanction-alert").show();
        } else {
          $(".sanction-alert").hide();
        }
        
        // Handle EU VAT fields
        if (response.eu_vat) {
          $("#eu-vat").show();
          $("#other-vat").hide();
          
          let countryCode = response.country_code;
          if (countryCode == "GR") {
            // Special handling for Greece
            countryCode = "EL";
          }
          let placeholderText = countryCode + "00000000000000";
          $("#organization_billing_paymethod_company_eu_vat_number").attr('placeholder', placeholderText);
        } else {
          $("#eu-vat").hide();
          $("#other-vat").show();
        }
      },
      error: (xhr, status, error) => {
        console.error("Error fetching country info:", error);
        $('#countryInfo').text('Failed to fetch country info.');
      }
    });
  }
}
