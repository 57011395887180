// FingerprintJS implementation
import { load, hashComponents } from '@fingerprintjs/fingerprintjs'

// Initialize an agent at application startup
const fpPromise = load()

// Get visitor identification data and set it as a hidden field
export const getVisitorIdAndSetField = async () => {
  // Get the visitor identifier
  const fp = await fpPromise
  const result = await fp.get()
  
  // Set the visitor ID to hidden form fields if they exist
  const fingerprintField = document.getElementById('user_fingerprint_hash')
  if (fingerprintField) {
    fingerprintField.value = result.visitorId

    
    // Also set timezone in the timezone fields
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    
    // Set timezone in both fields
    const timezoneField = document.getElementById('user_timezone')
    if (timezoneField) {
      timezoneField.value = userTimezone

    }
    
    // Add a timezone parameter directly to login_activity object
    const form = fingerprintField.closest('form')
    if (form) {
      // Create a hidden field for login_activity[timezone] if it doesn't exist
      let timezoneHiddenField = document.getElementById('login_activity_timezone')
      if (!timezoneHiddenField) {
        timezoneHiddenField = document.createElement('input')
        timezoneHiddenField.type = 'hidden'
        timezoneHiddenField.id = 'login_activity_timezone'
        timezoneHiddenField.name = 'login_activity[timezone]'
        form.appendChild(timezoneHiddenField)
      }
      timezoneHiddenField.value = userTimezone

    }

    // Also log what the form will submit to help with debugging
    if (form) {

    }
  } else {

    // Try to find any forms on the page to help with debugging
    const forms = document.querySelectorAll('form')

    forms.forEach((form, index) => {

    })
  }
  
  return result.visitorId
}

// Function to check for auth pages and initialize fingerprinting
const initFingerprintIfNeeded = () => {
  // Check if we're on the registration or login page
  const isAuthPage = document.querySelector('form[action*="sign_in"]') || 
                     document.querySelector('form[action*="sign_up"]')
  
  if (isAuthPage) {

    
    // Copy timezone from user field to login_activity field if needed
    const userTimezoneField = document.getElementById('user_timezone')
    if (userTimezoneField && userTimezoneField.value) {
      const userTimezone = userTimezoneField.value
      
      // Create login_activity[timezone] field if it doesn't exist
      const form = document.querySelector('form')
      if (form) {
        let loginActivityTimezoneField = document.getElementById('login_activity_timezone')
        if (!loginActivityTimezoneField) {
          loginActivityTimezoneField = document.createElement('input')
          loginActivityTimezoneField.type = 'hidden'
          loginActivityTimezoneField.id = 'login_activity_timezone'
          loginActivityTimezoneField.name = 'login_activity[timezone]'
          form.appendChild(loginActivityTimezoneField)
        }
        loginActivityTimezoneField.value = userTimezone

      }
    }
    
    getVisitorIdAndSetField().then(id => {

    }).catch(error => {

    })
  }
}

// Set timezone in HTTP header for all requests
document.addEventListener('turbo:before-fetch-request', (event) => {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  event.detail.fetchOptions.headers['Timezone'] = userTimezone;
});

// Initialize fingerprinting on DOM content loaded
document.addEventListener('DOMContentLoaded', initFingerprintIfNeeded)

// Also initialize on Turbo navigation
document.addEventListener('turbo:load', initFingerprintIfNeeded)

export default { getVisitorIdAndSetField }