import { Controller } from "@hotwired/stimulus"
import { debounce } from "lodash"
import Choices from 'choices.js'
import 'choices.js/public/assets/styles/choices.min.css'

export default class extends Controller {
    static values = {
        savePath: String,
        debounceMs: { type: Number, default: 500 }
    }

    connect() {
        // Create debounced save function with the correct debounce time
        this.debouncedSave = debounce(() => {
            this.save()
        }, this.debouncesMsValue || 500)

        this.initializeSwitches()
        this.initializeForm()
        this.initializeChoices()

        // Set initial form state based on general switch
        const generalSwitch = this.element.querySelector('#kyc_capture_email')
        if (generalSwitch) {
            this.toggleFormState(generalSwitch.checked)
        }
    }

    disconnect() {
        if (this.debouncedSave) {
            this.debouncedSave.cancel()
        }

        // Clean up all Choices instances
        if (this.choicesInstances) {
            this.choicesInstances.forEach(choice => choice.destroy())
        }
    }

    initializeChoices() {
        const choicesSelects = this.element.querySelectorAll('select[data-controller="choices"]')
        // Store all Choices instances for proper cleanup
        this.choicesInstances = []

        choicesSelects.forEach(select => {
            const choicesInstance = new Choices(select, {
                removeItemButton: select.dataset.choicesRemoveItemButtonValue === 'true',
                placeholder: false,
                classNames: {
                    containerOuter: 'choices form-control form-control-light'
                }
            })
            this.choicesInstances.push(choicesInstance)
        })
    }

    initializeSwitches() {
        // Get the token from the form's ID
        const formId = this.element.id  // e.g. "general_abc123"
        const token = formId.split('_')[1]  // e.g. "abc123"

        const switches = [
            {
                id: 'kyc_capture_email',
                handler: (checked) => this.toggleFormState(checked)
            },
            {
                baseId: 'aml_adverse_media_screening',
                handler: (checked) => this.toggleCardVisibility(`aml_adverse_media_screening_${token}_card`, checked)
            }
        ]

        switches.forEach((switchConfig) => {
            // For the general switch, use the original ID
            const elementId = switchConfig.id || `${switchConfig.baseId}_${token}`
            const switchElement = this.element.querySelector(`#${elementId}`)
            if (!switchElement) return

            // Set initial visibility state
            if (elementId !== 'kyc_capture_email') {
                const cardId = `${switchConfig.baseId}_${token}_card`
                this.toggleCardVisibility(cardId, switchElement.checked)
            }

            // Add change listener
            switchElement.addEventListener("change", (event) => {
                switchConfig.handler(event.target.checked)
                this.save()
            })
        })
    }

    initializeForm() {
        // Use this.element to get the form element that this controller is attached to
        const form = this.element
        if (!form) return

        // Listen for changes on all form elements
        form.querySelectorAll('input, select, textarea').forEach(element => {
            if (element.type === 'submit' || element.id === 'kyc_capture_email') return

            if (element.type === "text" || element.tagName === "TEXTAREA") {
                // Create a unique debounced save function for each text input
                const debouncedSaveForInput = debounce(() => {
                    this.save()
                }, this.debouncesMsValue || 500)

                element.addEventListener("input", () => debouncedSaveForInput())
                element.addEventListener("blur", () => {
                    debouncedSaveForInput.cancel()
                    this.save()
                })
            } else {
                // For all other inputs (select, checkbox, radio), save immediately
                element.addEventListener("change", () => this.save())
            }
        })

        // Handle form submission
        form.addEventListener('submit', (event) => {
            event.preventDefault()
            this.save()
        })
    }

    toggleFormState(enabled) {
        // Toggle visibility of status alerts
        this.toggleVisibility('email_verification-enabled', enabled)
        this.toggleVisibility('email_verification-disabled', !enabled)

        // Toggle form section
        const formSection = this.element.querySelector('#kyc-email_verification-form')
        if (!formSection) return

        formSection.classList.toggle('opacity-50', !enabled)

        // Disable/enable all form elements except the general switch
        formSection.querySelectorAll('input, select, textarea, button').forEach(element => {
            if (element.id !== 'kyc_capture_email') {
                element.disabled = !enabled
            }
        })

        // Toggle pointer events on clickable elements
        formSection.querySelectorAll('a, button').forEach(element => {
            element.style.pointerEvents = enabled ? 'auto' : 'none'
        })
    }

    toggleVisibility(elementId, show) {
        const element = this.element.querySelector(`#${elementId}`)
        if (!element) return

        element.classList.toggle('d-none', !show)
        element.classList.toggle('show', show)
    }

    toggleCardVisibility(cardId, show) {
        const card = this.element.querySelector(`#${cardId}`)
        if (!card) return

        if (show) {
            card.classList.remove('d-none')
            card.classList.add('show')
        } else {
            card.classList.add('d-none')
            card.classList.remove('show')
        }
    }

    async save() {
        const form = this.element
        if (!form || !form.elements) {
            console.error("Form not found or invalid.")
            return
        }

        // Get all form elements and initialize FormData
        const formElements = Array.from(form.elements)
        const formData = new FormData()

        // Iterate over form elements
        for (let element of formElements) {
            // Skip elements without a name or those that are disabled
            if (!element.name || element.disabled) continue

            if (element.type === 'checkbox') {
                // Handle checkboxes: include whether checked or not
                formData.append(`email_verification[${element.name}]`, element.checked ? '1' : '0')
            } else if (element.tagName === 'SELECT' && element.multiple) {
                // Special handling for document_types multiselect

                if (element.name === 'level[document_types][]') {
                    const selectedValues = Array.from(element.selectedOptions).map(option => option.value)
                    if (selectedValues.length > 0) {
                        selectedValues.forEach(value => {
                            formData.append('email_verification[level][document_types][]', value)
                        })
                    } else {
                        // If no values selected, send an empty array to ensure the parameter is included
                        formData.append('email_verification[level][document_types][]', '')
                    }
                }
            } else if (element.type !== 'submit') {
                // Handle all other input types, excluding submit buttons
                formData.append(`email_verification[${element.name}]`, element.value)
            }
        }

        const submitButtons = form.querySelectorAll('button[type="submit"]')
        submitButtons.forEach(button => button.disabled = true)

        try {
            const response = await fetch(form.action, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'text/vnd.turbo-stream.html',
                    'X-CSRF-Token': this.getCSRFToken()
                }
            })

            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const html = await response.text()
            Turbo.renderStreamMessage(html)
        } catch (error) {
            console.error('Save failed:', error)
        } finally {
            submitButtons.forEach(button => button.disabled = false)
        }
    }

    getCSRFToken() {
        const element = document.querySelector('meta[name="csrf-token"]')
        return element ? element.getAttribute('content') : null
    }
}