import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tableConfig: {
      type: Object,
      default: {
        '#id_table': {
          autoWidth: true,
          columns: [
            {
              data: 'document_type',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'country',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'document_number',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'first_names',
              render: (data, type, row) => data || "ANY"
            },
            {
              data: 'last_names',
              render: (data, type, row) => data || "ANY"
            },
            { data: 'list_type' },      // List Type
            { data: 'expires' },        // Expires
            { data: 'actions', orderable: false }  // Actions
          ],
          order: [[0, 'asc']],
          pageLength: 10,
          processing: true,
          language: {
            url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
            emptyTable: "No dataset entries"
          }
        },
        '#datasharing': {
          autoWidth: true,
          columns: [
            { data: 'account_number' },        // Account Number
            { data: 'name' },                  // Name
            { data: 'org_jurisdiction' },      // Organization Jurisdiction
            { data: 'storage_jurisdiction' },  // Storage Jurisdiction
            { data: 'direction' },            // Direction
            { data: 'actions', orderable: false }  // Actions
          ],
          order: [[0, 'asc']],
          pageLength: 10,
          processing: true,
          language: {
            url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
            emptyTable: "No dataset entries"
          }
        }
      }
    }
  }

  // Track DataTable instances for proper cleanup
  dataTableInstances = []

  initialize() {
    // Add listeners for all relevant Turbo events
    document.addEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
    document.addEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
  }

  connect() {
    // Wait for the next frame to ensure DOM is ready
    requestAnimationFrame(() => {
      this.initializeTables()
      this.initializePopovers()
    })
  }

  cleanupBeforeNavigation = () => {
    this.cleanupDataTables()
  }

  disconnect() {
    // Cleanup event listeners and tables
    document.removeEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
    document.removeEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    this.cleanupDataTables()
  }

  cleanupDataTables() {
    // Clean up DataTable instances we've tracked
    this.dataTableInstances.forEach(dt => {
      try {
        if (dt && typeof dt.destroy === 'function') {
          dt.destroy(true) // true = remove DOM changes
        }
      } catch (err) {
        console.warn('Error cleaning up DataTable:', err)
      }
    })
    
    // Reset the instances array
    this.dataTableInstances = []
    
    // Also try to clean any tables in the DOM
    try {
      const configs = this.tableConfigValue
      if (configs) {
        Object.keys(configs).forEach(tableId => {
          const table = document.querySelector(`table${tableId}`)
          if (table && $.fn.DataTable.isDataTable(table)) {
            try {
              $(table).DataTable().destroy(true)
            } catch (e) {
              // Ignore errors
            }
          }
        })
      }
    } catch (err) {
      // Ignore errors here
    }
  }

  initializeTables() {
    try {
      // Clean up first
      this.cleanupDataTables()
      
      const configs = this.tableConfigValue
      if (!configs) return

      Object.entries(configs).forEach(([tableId, config]) => {
        const table = document.querySelector(`table${tableId}`)
        if (!table) return
        
        // Add core options that help with Turbo
        const finalConfig = {
          ...config,
          retrieve: true, // Allows reinitialization
          destroy: true,  // Ensures clean destruction
          responsive: true,
          dom: 'lBfrtip',
        }
        
        try {
          // Use jQuery-style initialization
          const dtInstance = $(table).DataTable(finalConfig)
          
          // Store the instance for cleanup
          if (dtInstance) {
            this.dataTableInstances.push(dtInstance)
          }
        } catch (error) {
          console.error(`Error initializing table ${tableId}:`, error)
        }
      })
    } catch (error) {
      console.error('Error in initializeTables:', error)
    }
  }

  initializePopovers() {
    try {
      const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      popoverTriggerList.forEach(popoverTriggerEl => {
        new bootstrap.Popover(popoverTriggerEl)
      })
    } catch (error) {
      console.warn('Error initializing popovers:', error)
    }
  }
}