import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
import "@hotwired/stimulus"
// Import the custom prefetch implementation
import "~/prefetch"
// Import theme detection
import "~/theme-detect"
// Import fingerprint utilities
import "~/utils/fingerprint"
import "~/oauth_fingerprinting"
// Import translations
import "~/translations"

// Debug Turbo configuration
console.log('Turbo version:', Turbo.version);
console.log('Turbo enabled:', Turbo.session.enabled);

// Configure Turbo for optimal performance
if (Turbo.session) {
  console.log('Configuring Turbo for optimal performance');
  
  // Enable standard drive mode (Turbo's built-in navigation)
  Turbo.session.drive = true;
  
  // Set a smaller progress bar delay for more responsive UI
  Turbo.setProgressBarDelay(100);
  
  // Try to enable prefetching, but this may not be available in all Turbo versions
  try {
    if (typeof Turbo.session.enablePrefetch !== 'undefined') {
      Turbo.session.enablePrefetch = true;
      console.log('✅ Native Turbo prefetching enabled');
    }
  } catch (e) {
    console.log('⚠️ Native Turbo prefetching not available, using custom implementation');
  }
}

// Monitor Turbo prefetch events and cache management
document.addEventListener('turbo:before-fetch-request', (event) => {
  const isPrefetch = event.detail.fetchOptions.headers['X-Turbo-Prefetch'];
  console.log(`Turbo ${isPrefetch ? 'prefetch' : 'fetch'} event fired for: ${event.detail.url}`);
});

// Debug Turbo's snapshot cache - used for prefetching
document.addEventListener('turbo:before-cache', () => {
  console.log('🗃️ Turbo is caching current page');
});

// Log when a cached snapshot is rendered
document.addEventListener('turbo:before-render', (event) => {
  if (event.detail.isPreview) {
    console.log('🎭 Turbo is rendering a preview');
  } else {
    console.log(`🖼️ Turbo rendering from ${event.detail.fetchResponse?.isCached ? 'cache' : 'fresh fetch'}`);
  }
});

// Debug page loads
document.addEventListener('turbo:load', () => {
  console.log('Turbo page load completed');
});

// Log interaction with prefetchable links
document.addEventListener('DOMContentLoaded', () => {
  // Set timezone values for user timezone fields
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneFields = document.querySelectorAll('[id="user_timezone"], [id="login_activity_timezone"]');
  timezoneFields.forEach(field => {
    field.value = userTimezone;
    console.log('Set timezone field:', field.id, 'to', userTimezone);
  });

  // Monitor hover events on prefetchable links
  document.addEventListener('mouseover', (event) => {
    if (event.target.tagName === 'A' && event.target.hasAttribute('data-turbo-prefetch')) {
      console.log('Hovering over prefetchable link:', event.target.href);
    }
  }, true);
});

import "~/jquery"
import "~/controllers"
import "~/dashboard"
import "~/menu"
import "~/bynntoast"

import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap;

import "@ckeditor/ckeditor5-build-classic"
import "@curiosityx/bootstrap-session-timeout"
import "@simonwep/pickr"

import "alertifyjs"
import "apexcharts"
import "chance"
import "chart.js"

import feather from "feather-icons"
import "flatpickr"
import "fullcalendar"
import "glightbox"
import "imask"

import "jquery-countdown"
import "jquery-knob"
import "jquery-sparkline"
import "jsvectormap"
import "jszip"

import "leaflet"
import "masonry-layout"
import "metismenu"
import "nouislider"

//import "pace-js"
import "pdfmake"
import "pristinejs"
import "simplebar"
import "sweetalert2"
import "swiper"
import "twitter-bootstrap-wizard"
import toastr from "toastr"
window.toastr = toastr;

import "vendor/admin-resources/bootstrap-filestyle/bootstrap-filestyle.min.js"
import "vendor/table-edits/build/table-edits.min.js"
// Import vanilla-cookieconsent
import 'vanilla-cookieconsent/dist/cookieconsent.css';
// Import as namespace all exports from vanilla-cookieconsent
import * as CookieConsentModule from 'vanilla-cookieconsent';
// Assign it to window for global access
window.CookieConsent = CookieConsentModule;
import "wnumb"

document.addEventListener('turbo:load', function() {
  document.body.setAttribute('data-sidebar-size', 'sm');
  feather.replace();
});