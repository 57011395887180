import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
    static values = {
        tableConfig: {
            type: Object,
            default: {
                '#datatable': {
                    autoWidth: false,
                    "createdRow": function (row, data, dataIndex) {
                        $(row).css('cursor', 'pointer');

                        // Add click handler to all cells except the last two columns
                        $(row).find('td:not(:last-child):not(:nth-last-child(0))').on('click', function() {
                            const url = $(row).data('url');
                            if (url) {
                                // Use regular visit to ensure proper controller cleanup
                                Turbo.visit(url, { action: 'advance' });
                            }
                        });

                        // Add hover effect to the entire row
                        $(row).hover(
                            function () {
                                $(this).css({
                                    'background-color': '#f5f5f5'
                                });
                            },
                            function () {
                                $(this).css('background-color', '');
                            }
                        );
                    },
                    pageLength: 10,
                    processing: true,
                    language: {
                        url: `/locales/datatables/${document.documentElement.lang || 'en'}.json`,
                        emptyTable: "No assigned tasks"
                    }
                }
            }
        }
    }

    // Track instances for cleanup
    dataTableInstances = []

    initialize() {
        // Add listener for before navigation/caching
        document.addEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
        document.addEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    }

    connect() {
        // Basic initialization with a single animation frame
        requestAnimationFrame(() => {
            this.initializeTables()
        })
    }

    disconnect() {
        // Be sure to cleanup when controller disconnects
        this.cleanupDataTables()
        
        // Remove event listeners
        document.removeEventListener('turbo:before-visit', this.cleanupBeforeNavigation.bind(this))
        document.removeEventListener('turbo:before-cache', this.cleanupBeforeNavigation.bind(this))
    }

    cleanupBeforeNavigation = () => {
        this.cleanupDataTables()
    }

    cleanupDataTables() {
        // Clean up any instances we've created
        this.dataTableInstances.forEach(dt => {
            try {
                if (dt && typeof dt.destroy === 'function') {
                    dt.destroy(true) // true = remove DOM changes
                }
            } catch (err) {
                console.warn('Error cleaning up DataTable:', err)
            }
        })
        
        // Reset the instances array
        this.dataTableInstances = []
        
        // Also attempt to clean any tables in the DOM
        try {
            if (this.element) {
                const tables = this.element.querySelectorAll('table')
                tables.forEach(table => {
                    try {
                        if ($.fn.DataTable.isDataTable(table)) {
                            const dt = $(table).DataTable()
                            dt.destroy(true)
                        }
                    } catch (err) { 
                        // Ignore errors here
                    }
                })
            }
        } catch (err) {
            // Ignore errors here too
        }
    }

    initializeTables() {
        try {
            // Clean up any existing instances first
            this.cleanupDataTables()
            
            const configs = this.tableConfigValue
            if (!configs || !this.element) return
            
            Object.entries(configs).forEach(([tableId, config]) => {
                const table = this.element.querySelector(`table${tableId}`)
                if (!table) return
                
                // Initialize with a core set of fixed options that help with Turbo
                const finalConfig = {
                    ...config,
                    destroy: true,
                    responsive: true,
                    dom: 'lBfrtip',
                    retrieve: true // Important: allows retrieving existing instance
                }
                
                // Create instance and store reference for cleanup
                try {
                    const dtInstance = $(table).DataTable(finalConfig)
                    if (dtInstance) {
                        this.dataTableInstances.push(dtInstance)
                    }
                } catch (error) {
                    console.error('Error creating DataTable:', error)
                }
            })
        } catch (error) {
            console.error('Error initializing tables:', error)
        }
    }
}