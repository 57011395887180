// app/javascript/translations.js
//
// This file exposes translations from Rails i18n to JavaScript
// The actual translation data will be injected by Rails when the page is rendered

window.I18n = window.I18n || {};
window.I18n.translations = window.I18n.translations || {};

// Simple translation function that accepts a key and optional parameters
window.I18n.t = function(key, params = {}) {
  // Get translation from loaded translations
  let translation = window.I18n.translations[key];
  
  // If no translation found, return default value if provided, otherwise the key
  if (translation === undefined) {
    return params && params.default !== undefined ? params.default : key;
  }
  
  // Replace parameters in the translation string
  if (typeof translation === 'string' && params) {
    Object.keys(params).forEach(param => {
      // Skip the default parameter as it's not for replacement
      if (param === 'default') return;
      
      const regex = new RegExp(`%{${param}}`, 'g');
      translation = translation.replace(regex, params[param]);
    });
  }
  
  return translation;
};