import { Controller } from "@hotwired/stimulus"
import Choices from 'choices.js'

export default class extends Controller {
  connect() {
    this.initChoices()
  }
  
  initChoices() {
    // Common fast options with reduced animations
    const baseOptions = {
      itemSelectText: '',
      removeItemButton: true,
      placeholder: true,
      renderChoiceLimit: 0,
      position: 'auto',

      loadingText: '',
      // Performance options
      renderSelectedChoices: 'auto',
      // Removing/reducing animations
      duplicateItemsAllowed: false,
      shouldSort: false,
      callbackOnCreateTemplates: function(template) {
        return {
          item: (classNames, data) => {
            return template(`
              <div class="${classNames.item} ${data.highlighted ? classNames.highlightedState : ''} ${data.disabled ? classNames.itemDisabled : ''}" data-item data-id="${data.id}" data-value="${data.value}" ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''}>
                ${data.label}
                <button type="button" class="${classNames.button}" data-button aria-label="Remove item" style="transition: none; animation: none;">Remove item</button>
              </div>
            `);
          },
          choice: (classNames, data) => {
            return template(`
              <div class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : ''} ${data.selected ? classNames.itemSelectable : ''}" data-select-text="${this.config.itemSelectText}" data-choice ${data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable'} data-id="${data.id}" data-value="${data.value}" ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'} style="transition: none; animation: none;">
                ${data.label}
              </div>
            `);
          }
        };
      }
    };
    
    // Initialize nationality dropdown
    const nationalityDropdown = document.getElementById('nationality')
    if (nationalityDropdown && !nationalityDropdown.classList.contains('choices-initialized')) {
      new Choices(nationalityDropdown, {
        ...baseOptions,
        searchEnabled: true, 
        searchPlaceholderValue: 'Search countries...',
        placeholderValue: 'Select a country',
        searchResultLimit: 8,
        searchChoices: true,
        placeholder: true,
        removeItemButton: true,
        placeholderValue: 'All Countries',
        searchPlaceholderValue: 'Select Countries',
        searchFields: ['label']
      })
      nationalityDropdown.classList.add('choices-initialized')
    }
    
    // Initialize status dropdown
    const statusDropdown = document.getElementById('status')
    if (statusDropdown && !statusDropdown.classList.contains('choices-initialized')) {
      new Choices(statusDropdown, {
        ...baseOptions,
        searchEnabled: false,
        placeholder: true,
        removeItemButton: true,
        placeholderValue: 'All statuses',
        searchPlaceholderValue: 'Select statuses',
      })
      statusDropdown.classList.add('choices-initialized')
    }
    
    // Initialize any elements with data-trigger attribute
    const triggerElements = document.querySelectorAll('[data-trigger]:not(.choices-initialized)')
    triggerElements.forEach(element => {
      if (!element.classList.contains('choices-initialized')) {
        new Choices(element, {
          ...baseOptions,
          searchEnabled: true,
          searchPlaceholderValue: 'Search options...',
          placeholderValue: 'Select an option'
        })
        element.classList.add('choices-initialized')
      }
    })
  }
}