// app/javascript/controllers/dashboard/webhook_subscriber_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["overlay", "content", "search", "eventItem", "openButton"]
    static values = {
        url: String,
        subscriptionsUrl: String,
        currentWebhook: String
    }

    connect() {
        this.selectedEvents = new Set()
        document.addEventListener('keydown', this.handleEscape.bind(this))
    }

    disconnect() {
        document.removeEventListener('keydown', this.handleEscape.bind(this))
    }

    handleEscape(event) {
        if (event.key === "Escape") {
            this.close()
        }
    }

    close() {
        console.log('Close method called')
        // The modal is directly on the page, not within the controller element
        const modal = document.querySelector('.bynn-webhook-subscriber')
        if (modal && modal.classList) {
            modal.classList.add('hidden')
            console.log('Added hidden class to modal element')
        } else {
            console.error('Modal element not found or does not have classList property')
        }
    }

    async open(event) {
        const webhookToken = event.currentTarget.dataset.id
        this.currentWebhookValue = webhookToken
        console.log('Opening modal for webhook:', webhookToken)

        // Clear all checkboxes first
        this.eventItemTargets.forEach(item => {
            const checkbox = item.querySelector('input[type="checkbox"]')
            if (checkbox) {
                checkbox.checked = false
            }
        })

        // Show the modal immediately to give user feedback
        const modal = document.querySelector('.bynn-webhook-subscriber')
        if (modal && modal.classList) {
            modal.classList.remove('hidden')
            console.log('Removed hidden class from modal element')
        } else {
            console.error('Modal element not found or does not have classList property')
        }

        try {
            // Use the subscription URL and add the webhook_address parameter
            const url = new URL(this.subscriptionsUrlValue, window.location.origin)
            url.searchParams.append('webhook_address', webhookToken)
            
            const response = await fetch(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.getCSRFToken()
                }
            })

            if (!response.ok) {
                throw new Error('Failed to fetch subscribed events')
            }

            const { subscribed_events } = await response.json()

            // Update checkboxes based on subscribed events
            this.eventItemTargets.forEach(item => {
                const eventName = item.dataset.eventId
                const checkbox = item.querySelector('input[type="checkbox"]')

                if (checkbox) {
                    checkbox.checked = subscribed_events.includes(eventName)
                }
            })

        } catch (error) {
            console.error('Error fetching subscribed events:', error)
        }
    }

    toggleEvent(event) {
        console.log("Toggle event triggered")
        const checkbox = event.target
        const eventItem = checkbox.closest('[data-event-id]')
        const eventName = eventItem.dataset.eventId
        const webhookToken = this.currentWebhookValue

        console.log("Event Name:", eventName)
        console.log("Webhook Token:", webhookToken)
        console.log("Checked:", checkbox.checked)

        this.updateSubscription(webhookToken, eventName, checkbox.checked)
    }

    async updateSubscription(webhookToken, eventName, isSubscribing) {
        try {
            // The URL is already the full handle_subscription path
            const url = this.urlValue
            
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.getCSRFToken()
                },
                body: JSON.stringify({
                    event_name: eventName,
                    subscribe: isSubscribing,
                    webhook_address: webhookToken
                })
            })

            if (!response.ok) {
                throw new Error('Subscription update failed')
            }

            console.log(`Successfully ${isSubscribing ? 'subscribed to' : 'unsubscribed from'}: ${eventName}`)
        } catch (error) {
            console.error('Error updating subscription:', error)
            // Revert checkbox state on error
            this.updateCheckboxState(eventName, !isSubscribing)
        }
    }

    getCSRFToken() {
        return document.querySelector('meta[name="csrf-token"]')?.content
    }

    updateCheckboxState(eventName, checked) {
        const eventItem = this.eventItemTargets.find(item => item.dataset.eventId === eventName)
        if (eventItem) {
            const checkbox = eventItem.querySelector('input[type="checkbox"]')
            if (checkbox) {
                checkbox.checked = checked
            }
        }
    }

    search() {
        const query = this.searchTarget.value.toLowerCase()

        this.eventItemTargets.forEach(item => {
            const eventName = item.querySelector('.event-name').textContent.toLowerCase()
            const category = item.closest('.event-category')
            const matches = eventName.includes(query)

            // Toggle visibility of the event item
            item.classList.toggle('hidden', !matches)

            // If category exists, check if it has any visible events
            if (category) {
                const hasVisibleEvents = Array.from(category.querySelectorAll('.event-item'))
                    .some(event => !event.classList.contains('hidden'))
                category.classList.toggle('hidden', !hasVisibleEvents)
            }
        })
    }
}