import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "addButton", 
    "deleteLink", 
    "confirmDeleteButton",
    "passkeyDetailsModal"
  ]

  static values = {
    profilePath: String,
    profileIndexPath: String
  }

  connect() {
    // Initialize tooltips and popovers when controller connects
    this.initializeTooltipsAndPopovers()
    
    // Add event listeners to dynamically created modals
    this.boundHandleModalShow = this.handleModalShow.bind(this)
    document.addEventListener('show.bs.modal', this.boundHandleModalShow)
    
    // Initialize Bootstrap modals
    this.setupModals()
    
    console.log("Passkey controller connected", this.element)
    console.log("Confirm button:", this.confirmDeleteButtonTarget)
    
    // DIRECT event listener as a fallback
    const confirmButton = document.getElementById('confirmDeletePasskeyBtn')
    if (confirmButton) {
      console.log("Adding direct event listener to confirm button")
      confirmButton.addEventListener('click', this.directClickHandler.bind(this))
    }
  }

  disconnect() {
    // Clean up event listeners when controller disconnects
    document.removeEventListener('show.bs.modal', this.boundHandleModalShow)
  }
  
  setupModals() {
    // Make sure modals are properly initialized
    const confirmModal = document.getElementById('confirmPasskeyDeleteModal')
    if (confirmModal) {
      console.log('Initializing confirm delete modal')
      // Make sure it's a bootstrap modal instance
      if (!bootstrap.Modal.getInstance(confirmModal)) {
        new bootstrap.Modal(confirmModal)
      }
    }
  }

  initializeTooltipsAndPopovers() {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(tooltipTriggerEl => {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    popoverTriggerList.map(popoverTriggerEl => {
      return new bootstrap.Popover(popoverTriggerEl)
    })
  }

  async addPasskey(event) {
    try {
      const url = this.profilePathValue
      await this.registerPasskey(url)
      location.reload()
    } catch (error) {
      if(error.message === "The object is in an invalid state") {
        alert('You have already registered this device!')
      } else {
        alert('Failed to register passkey: ' + error.message)
      }
    }
  }

  async registerPasskey(url) {
    try {
      // Fetch the creation options from Rails backend
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
        }
      })

      const options = await response.json()

      // Convert the options for the WebAuthn API
      const publicKeyCredentialCreationOptions = {
        challenge: this.base64URLToBuffer(options.challenge),
        rp: options.rp,
        user: {
          ...options.user,
          id: this.base64URLToBuffer(options.user.id),
        },
        pubKeyCredParams: options.pubKeyCredParams,
        timeout: options.timeout,
        excludeCredentials: options.excludeCredentials.map(credential => ({
          ...credential,
          id: this.base64URLToBuffer(credential.id),
        })),
        authenticatorSelection: {
          residentKey: 'preferred',
          userVerification: 'preferred'
        },
        extensions: options.extensions
      }

      // Create the credential
      const credential = await navigator.credentials.create({
        publicKey: publicKeyCredentialCreationOptions
      })

      // Convert the credential for sending to the server
      const credentialResponse = {
        id: credential.id,
        rawId: Array.from(new Uint8Array(credential.rawId)),
        type: credential.type,
        response: {
          attestationObject: Array.from(new Uint8Array(credential.response.attestationObject)),
          clientDataJSON: Array.from(new Uint8Array(credential.response.clientDataJSON))
        }
      }

      // Send the credential back to your Rails backend
      const verificationResponse = await fetch('verify_passkey', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content
        },
        body: JSON.stringify(credentialResponse)
      })

      if (!verificationResponse.ok) {
        throw new Error('Failed to verify passkey')
      }

      // Handle successful registration
      console.log('Passkey registered successfully!')
      return await verificationResponse.json()
    } catch (error) {
      console.error('Error registering passkey:', error)
      throw error
    }
  }

  handleModalShow(event) {
    // Handle different modal events
    if (event.target.id === 'confirmPasskeyDeleteModal') {
      this.currentDeleteBtn = event.relatedTarget
      console.log('Delete modal opened for:', this.currentDeleteBtn)
      
      // Store the credential ID and delete URL directly on the confirm button
      if (this.currentDeleteBtn) {
        const confirmButton = event.target.querySelector('#confirmDeletePasskeyBtn')
        if (confirmButton) {
          confirmButton.dataset.credentialId = this.currentDeleteBtn.dataset.credentialId
          confirmButton.dataset.deleteUrl = this.currentDeleteBtn.dataset.deleteUrl
          console.log('Stored credential and URL on confirm button', 
                      confirmButton.dataset.credentialId, 
                      confirmButton.dataset.deleteUrl)
        }
      }
    } else if (event.target.id === 'passkeyDetailsModal') {
      this.setupPasskeyDetailsModal(event)
    }
  }

  setupPasskeyDetailsModal(event) {
    // Button that triggered the modal
    const button = event.relatedTarget
    
    // Extract info from data attributes
    const passkeyId = button.getAttribute('data-passkey-id')
    const passkeyName = button.getAttribute('data-passkey-name')
    const passkeyType = button.getAttribute('data-passkey-type')
    const passkeyCreated = button.getAttribute('data-passkey-created')
    const passkeyUsed = button.getAttribute('data-passkey-used')
    const passkeySignCount = button.getAttribute('data-passkey-sign-count')

    // Update the modal's content
    const modal = event.target
    modal.querySelector('#passkeyName').textContent = passkeyName
    modal.querySelector('#passkeyType').textContent = passkeyType
    modal.querySelector('#passkeyType').className = `badge badge-soft-${passkeyType === 'Platform' ? 'info' : 'warning'} mb-3`
    modal.querySelector('#passkeyCreated').textContent = passkeyCreated
    modal.querySelector('#passkeyUsed').textContent = passkeyUsed
    modal.querySelector('#passkeySignCount').textContent = passkeySignCount || '0'

    // Set the authenticator type
    modal.querySelector('#passkeyAuthType').textContent = passkeyType === 'Platform' ?
      'Platform (built into device)' :
      'Cross-platform (portable security key)'

    // Set default values for technical info we might not have
    modal.querySelector('#passkeyAlgorithm').textContent = 'ES256 (ECDSA P-256)'
    modal.querySelector('#passkeyFormat').textContent = 'none'

    // Get any AAGUID information from data attributes (if added server-side)
    const aaguidElement = modal.querySelector('#passkeyAaguid')
    const aaguid = button.getAttribute('data-passkey-aaguid')
    if (aaguid && aaguid !== '00000000000000000000000000000000') {
      aaguidElement.innerHTML = `
        <code>${aaguid}</code>
        <br>
        <small class="text-muted">Uniquely identifies the authenticator model</small>
      `
    } else {
      aaguidElement.innerHTML = '<span class="text-muted">Not available</span>'
    }

    // Set information about discoverable credential (resident key)
    const isResidentKey = button.getAttribute('data-passkey-resident') === 'true'
    const discoverableElement = modal.querySelector('#passkeyDiscoverable')
    if (discoverableElement) {
      discoverableElement.innerHTML = isResidentKey ?
        '<span class="badge badge-soft-success">Yes</span> <small class="text-muted">(Stored on device)</small>' :
        '<span class="badge badge-soft-warning">No</span> <small class="text-muted">(Server-side lookup required)</small>'
    }

    // Set user verification information
    const userVerificationElement = modal.querySelector('#passkeyUserVerification')
    if (userVerificationElement) {
      const isUserVerified = button.getAttribute('data-passkey-uv') === 'true'
      userVerificationElement.innerHTML = isUserVerified ?
        '<span class="badge badge-soft-success">Required</span> <small class="text-muted">(Biometric/PIN)</small>' :
        '<span class="badge badge-soft-warning">Discouraged</span> <small class="text-muted">(Presence only)</small>'
    }

    // Update the delete button URL and add the proper classes/data attributes
    // We need to construct the URL with the passkey's token, not ID
    const linkInRow = button.closest('tr')?.querySelector('.delete-passkey-link')
    let deleteUrl

    if (linkInRow && linkInRow.dataset.deleteUrl) {
      // Use the existing URL which should be correct
      deleteUrl = linkInRow.dataset.deleteUrl
      console.log('Got delete URL from table row:', deleteUrl)
    } else {
      // Fallback: use the one from the button
      deleteUrl = button.dataset.deleteUrl
      console.log('Using delete URL from button:', deleteUrl)
    }

    // Configure the delete button
    const deleteBtn = modal.querySelector('#deletePasskeyBtn')
    if (deleteBtn) {
      deleteBtn.href = deleteUrl || '#'
      deleteBtn.dataset.turboMethod = 'delete'
      deleteBtn.dataset.turboConfirm = 'Are you sure you want to remove this passkey? It will be deleted from our database and your device.'

      // Add classes and data for our deletion handler
      deleteBtn.classList.add('delete-passkey-link')

      // IMPORTANT: Set the credential ID data attribute for deletion
      const credentialId = button.getAttribute('data-credential-id')
      if (credentialId) {
        console.log('Setting credential ID on modal delete button:', credentialId)
        deleteBtn.dataset.credentialId = credentialId
      } else {
        console.warn('Could not find credential ID for modal delete button')
      }
    }
  }

  directClickHandler(event) {
    console.log('DIRECT click handler activated', event)
    this.confirmDeletePasskey(event)
  }
  
  async confirmDeletePasskey(event) {
    console.log('Confirm delete button clicked', event.currentTarget)
    
    // First, get the credential ID and delete URL directly from the delete link that was clicked
    const passkeyData = document.querySelector('.delete-passkey-link[data-bs-toggle="modal"]')
    console.log('Found passkey data element:', passkeyData)
    
    // Get the data attributes
    const credential = passkeyData?.dataset?.credentialId
    const deleteUrl = passkeyData?.dataset?.deleteUrl
    
    console.log('Data from passkey data element:', { credential, deleteUrl })
    
    // If we can't find them, show an error and exit
    if (!credential || !deleteUrl) {
      console.error('Could not determine credential ID or delete URL')
      alert('Error: Could not find the passkey to delete. Please try again.')
      return
    }
    
    console.log('Deleting passkey with credential:', credential)
    console.log('Delete URL:', deleteUrl)
    
    // Hide the confirmation modal
    const confirmPasskeyDeleteModal = document.getElementById('confirmPasskeyDeleteModal')
    const bsModal = bootstrap.Modal.getInstance(confirmPasskeyDeleteModal)
    bsModal.hide()
    
    // Show a progress modal
    const progressModal = document.createElement('div')
    progressModal.className = 'modal fade show'
    progressModal.style.display = 'block'
    progressModal.innerHTML = `
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Removing Passkey</h5>
          </div>
          <div class="modal-body">
            <div class="text-center">
              <p class="mb-3">Removing passkey from server database...</p>
              <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Removing...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
    
    // Add backdrop
    const backdrop = document.createElement('div')
    backdrop.className = 'modal-backdrop fade show'
    
    // Add to body
    document.body.appendChild(progressModal)
    document.body.appendChild(backdrop)
    document.body.classList.add('modal-open')
    
    try {
      // Delete from server
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      
      // Update progress modal for device deletion
      progressModal.querySelector('.modal-body').innerHTML = `
        <div class="text-center">
          <p class="mb-3">Now removing passkey from your device...</p>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Removing...</span>
          </div>
        </div>
      `
      
      // IMPORTANT: ALWAYS remove from device after server deletion
      if (credential) {
        console.log('🔑 NOW REMOVING PASSKEY FROM DEVICE WITH CREDENTIAL ID:', credential)
        
        try {
          // Execute the same function that was working before
          const deviceRemovalResult = await this.removePasskeyFromDevice(credential)
          console.log('🔑 Device removal result:', deviceRemovalResult ? 'SUCCESS ✅' : 'FAILED ❌')
          
          // Try one more time if it failed
          if (!deviceRemovalResult) {
            console.log('🔄 First attempt failed, trying one more time...')
            await new Promise(resolve => setTimeout(resolve, 1000))
            
            const secondAttemptResult = await this.removePasskeyFromDevice(credential)
            console.log('🔑 Second device removal attempt result:', secondAttemptResult ? 'SUCCESS ✅' : 'FAILED ❌')
          }
        } catch (deviceError) {
          console.error('⚠️ Error removing from device:', deviceError)
        }
      } else {
        console.error('❌ NO CREDENTIAL ID AVAILABLE - CANNOT REMOVE FROM DEVICE')
      }
      
      // Show success and reload
      progressModal.querySelector('.modal-body').innerHTML = `
        <div class="text-center">
          <div class="text-success mb-3">
            <i class="fa-solid fa-circle-check fa-3x"></i>
          </div>
          <p>The passkey was successfully removed.</p>
          <p>The page will reload momentarily...</p>
        </div>
      `
      
      // Reload after a short delay
      setTimeout(() => {
        window.location.href = this.profileIndexPathValue
      }, 1500)
      
    } catch (error) {
      console.error('Error during passkey deletion:', error)
      
      // Show error
      progressModal.querySelector('.modal-body').innerHTML = `
        <div class="text-center">
          <div class="text-danger mb-3">
            <i class="fa-solid fa-circle-xmark fa-3x"></i>
          </div>
          <p>There was an error removing the passkey.</p>
          <p>Please try again or contact support if the problem persists.</p>
          <div class="mt-3">
            <button class="btn btn-primary" id="errorRetryBtn">Retry</button>
            <button class="btn btn-light ms-2" id="errorCloseBtn">Close</button>
          </div>
        </div>
      `
      
      // Add handlers for error buttons
      const errorRetryBtn = progressModal.querySelector('#errorRetryBtn')
      const errorCloseBtn = progressModal.querySelector('#errorCloseBtn')
      
      if (errorRetryBtn) {
        errorRetryBtn.addEventListener('click', () => {
          document.body.removeChild(progressModal)
          document.body.removeChild(backdrop)
          document.body.classList.remove('modal-open')
          this.confirmDeletePasskey()
        })
      }
      
      if (errorCloseBtn) {
        errorCloseBtn.addEventListener('click', () => {
          document.body.removeChild(progressModal)
          document.body.removeChild(backdrop)
          document.body.classList.remove('modal-open')
          window.location.reload()
        })
      }
    }
  }

  // Enhanced function to convert Base64URL string to ArrayBuffer
  base64URLToBuffer(base64URL) {
    try {
      // Handle edge cases
      if (!base64URL) {
        console.error('base64URLToBuffer: Empty or null input')
        return new ArrayBuffer(0)
      }
      
      console.log('base64URLToBuffer input:', base64URL)
      
      // Convert base64url to regular base64
      const base64 = base64URL.replace(/-/g, '+').replace(/_/g, '/')
      
      // Add padding if needed
      const padLen = (4 - (base64.length % 4)) % 4
      const padded = base64 + '='.repeat(padLen)
      
      console.log('base64URLToBuffer padded base64:', padded)
      
      // Decode base64 to binary string
      const binary = atob(padded)
      
      // Convert binary string to ArrayBuffer
      const buffer = new Uint8Array(binary.length)
      for (let i = 0; i < binary.length; i++) {
        buffer[i] = binary.charCodeAt(i)
      }
      
      console.log('base64URLToBuffer converted to buffer with length:', buffer.length)
      console.log('buffer first 8 bytes:', [...buffer.slice(0, 8)])
      
      return buffer.buffer
    } catch (error) {
      console.error('Error in base64URLToBuffer:', error)
      throw error
    }
  }

  // Function to remove passkey from device - UPDATED AND FIXED VERSION
  async removePasskeyFromDevice(credentialId) {
    try {
      if (!credentialId) {
        console.warn('No credential ID provided for deletion')
        return false
      }
      
      // Log the credential ID we're trying to remove
      console.log(`Attempting to remove credential: ${credentialId}`)
      
      // Verify format of the credential ID - it needs to be base64url formatted
      // but we also need to handle if it's already in the right format
      let formattedCredentialId = credentialId
      if (credentialId.includes('+') || credentialId.includes('/') || credentialId.includes('=')) {
        // Convert from standard base64 to base64url
        formattedCredentialId = credentialId
          .replace(/\+/g, '-')
          .replace(/\//g, '_')
          .replace(/=/g, '')
      }
      console.log(`Formatted credential ID: ${formattedCredentialId}`)
      
      // Method 1: Use the WebAuthn Signal API (Chrome 132+)
      if (window.PublicKeyCredential && typeof PublicKeyCredential.signalUnknownCredential === 'function') {
        console.log('Using WebAuthn Signal API to delete passkey')
        try {
          // Use the formatted credential ID
          await PublicKeyCredential.signalUnknownCredential({
            rpId: window.location.hostname,
            credentialId: formattedCredentialId
          })
          console.log('Signaled credential as unknown via WebAuthn Signal API')
          return true
        } catch (apiError) {
          console.warn('Error using WebAuthn Signal API:', apiError)
          // Fall through to the next method
        }
      }
      
      // Method 2: Use the workaround method (excludeCredentials)
      // This is the most widely compatible approach
      if (window.PublicKeyCredential) {
        console.log('Using excludeCredentials workaround to delete passkey')
        
        try {
          // Convert the credential ID from base64url to ArrayBuffer
          const credentialIdBuffer = this.base64URLToBuffer(formattedCredentialId)
          console.log('Converted credential to ArrayBuffer:', credentialIdBuffer)
          
          // Create a more reliable challenge
          const challenge = new Uint8Array(32)
          window.crypto.getRandomValues(challenge)
          
          // Create random user ID for the registration operation
          const userId = new Uint8Array(16)
          window.crypto.getRandomValues(userId)
          
          // Create the credential options
          const createOptions = {
            publicKey: {
              challenge: challenge,
              rp: {
                name: 'Bynn Verification',
                id: window.location.hostname
              },
              user: {
                id: userId,
                name: 'cleanup@example.com',
                displayName: 'Cleanup Operation'
              },
              pubKeyCredParams: [
                { type: 'public-key', alg: -7 }, // ES256
                { type: 'public-key', alg: -257 } // RS256
              ],
              // Put the credential to delete in excludeCredentials
              excludeCredentials: [{
                type: 'public-key',
                id: credentialIdBuffer,
                // Include all possible transports
                transports: ['internal', 'usb', 'nfc', 'ble']
              }],
              timeout: 60000,
              authenticatorSelection: {
                // Don't specify attachment to make this more general
                userVerification: 'discouraged'
              }
            }
          }
          
          console.log('Attempting credential creation with exclusion list:', createOptions)
          
          // Try to create a credential, which should trigger cleanup on the authenticator
          await navigator.credentials.create(createOptions)
          console.log('Successfully used excludeCredentials method')
          return true
        } catch (createError) {
          console.warn('Error with excludeCredentials method:', createError)
          
          // Let's try once more but with different options
          try {
            console.log('Attempting with alternative options')
            
            // Convert the credential ID again to be sure
            const credentialIdBuffer = this.base64URLToBuffer(formattedCredentialId)
            
            // Create the credential options with minimal configuration
            const createOptions = {
              publicKey: {
                challenge: window.crypto.getRandomValues(new Uint8Array(32)),
                rp: {
                  id: window.location.hostname
                },
                user: {
                  id: window.crypto.getRandomValues(new Uint8Array(16)),
                  name: 'cleanup@example.com',
                  displayName: 'Cleanup'
                },
                pubKeyCredParams: [
                  { type: 'public-key', alg: -7 }
                ],
                excludeCredentials: [{
                  type: 'public-key',
                  id: credentialIdBuffer
                }],
                timeout: 30000
              }
            }
            
            await navigator.credentials.create(createOptions)
            console.log('Successfully used basic excludeCredentials method')
            return true
          } catch (retryError) {
            console.warn('Alternative method also failed:', retryError)
            
            // If we got here, all automated methods failed
            // Ask the user to delete the passkey manually as a last resort
            const confirmed = confirm(
              "We couldn't automatically remove the passkey from your device. " +
              "Would you like instructions on how to remove it manually from your device settings?"
            )
            
            if (confirmed) {
              alert(
                "To manually remove passkeys:\n\n" +
                "- On Chrome: Go to Settings > Passwords > Passkeys\n" +
                "- On Safari: Go to Settings > Passwords > Passkeys\n" +
                "- On Windows: Go to Settings > Accounts > Sign-in options > Security Key\n" +
                "- On iOS: Go to Settings > Passwords > Passkeys\n\n" +
                "Find this website's entry and remove it."
              )
            }
          }
        }
      }
       
      // No method worked, but we've tried our best and offered manual deletion
      console.warn('All passkey removal methods failed, offered manual deletion')
      return false
    } catch (error) {
      console.error('Error removing passkey from device:', error)
      return false
    }
  }
}